import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import { useState } from 'react';
import Pills from '../../components/Pills';
import FieldStore from '../../components/FieldStore';


function ExchangesFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {
    const [ stores, setStores ] = useState(filter?.stores ?? []);
  
    function confirm() {
        onConfirm({
            stores,
        });
    }

    function cancel() {
        onCancel();
        setStores(filter.stores ?? []);
    }
      
    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            // I18N
            title={'Filtrar trocas'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldStore
                        multipleSelection={true}
                        onAddItem={(record) => {
                            setStores((store) => {
                                return [
                                    ...store,
                                    record
                                ];
                            });
                        }}
                        ignoreItems={stores.map(record => record.id)}
                    >
                        <Pills
                            pills={stores}
                            onRemoveItem={(record) => {
                                setStores((store) => {
                                    return store.filter(item => item.id !== record.id);
                                });
                            }}
                        />
                    </FieldStore>
                </div>
            </div>
        </SimpleConfirmModal>
    );
}

export default ExchangesFilterModal;