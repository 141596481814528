import api from '../util/api';

export async function getBillsToReceiveList(page, finalDueDate, finalReleaseDate, initialDueDate, initialReleaseDate, receiptType, saleId, status, cancelToken) {
    return (await api.get('/bills-to-receive', {
        params: {
            page,
            finalDueDate, 
            finalReleaseDate, 
            initialDueDate, 
            initialReleaseDate,
            receiptType, 
            saleId, 
            status
        },
        cancelToken: cancelToken
    })).data;
}

export async function getBillToReceive(uuid) {
    return (await api.get(`/bills-to-receive/${uuid}`)).data;
}

export async function addBillsToReceive(formData) {
    return await api.post('/bills-to-receive', formData);
}

export async function editBillsToReceive(uuid, formData) {
    return await api.put(`/bills-to-receive/${uuid}`, formData);
}

