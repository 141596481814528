import './FieldPermission.scss';
import nextId from 'react-id-generator';

function FieldPermissionGroup({
    groupName,
    permissions = [],
    onSelected,
    select = [],
    readOnly,
    // multipleSelection = true, //TODO
}) {
    function renderPermission() {
        const permissionList = [];
        for (const permission of permissions) {
            const { name, enum: enumerate, textFn } = permission;
            function renderPermissionType() {
                let permissionEnumArray = [];
                for (const permissionEnum in enumerate) {
                    permissionEnumArray.push(permissionEnum);
                }

                const typeList = [];
                typeList.push(
                    <div key={'all'} className={'field-inputs'}>
                        <label>
                            <input
                                type={'checkbox'}
                                name={nextId('permissions')}
                                // checked={select?.includes(enumPermission)}
                                onChange={(e) => {
                                    if (onSelected) {
                                        if (e.target.checked) {
                                            onSelected({
                                                add: permissionEnumArray
                                            });
                                        } else {
                                            onSelected({
                                                remove: permissionEnumArray
                                            });
                                        }
                                    }
                                }}
                                disabled={readOnly}
                                checked={permissionEnumArray.every((item) => select.includes(item))}
                            />
                            { /*I18N*/ }
                            { <strong>{ 'Todas as permissões' }</strong> }
                        </label>
                    </div>
                );

                for (const permissionEnum of permissionEnumArray) {
                    typeList.push(
                        <div key={permissionEnum} className={'field-inputs'}>
                            <label>
                                <input
                                    type={'checkbox'}
                                    name={nextId('permissions')}
                                    checked={select?.includes(permissionEnum)}
                                    onChange={(e) => {
                                        if (onSelected) {
                                            if (e.target.checked) {
                                                onSelected({
                                                    add: [ permissionEnum ]
                                                });
                                            } else {
                                                onSelected({
                                                    remove: [ permissionEnum ]
                                                });
                                            }
                                        }
                                    }}
                                    disabled={readOnly}
                                />
                                { textFn ? textFn(permissionEnum) : permissionEnum }
                            </label>
                        </div>
                    );
                }
                return typeList;
            }

            permissionList.push(
                <div key={name} className={'permission'}>
                    <div>
                        { name }
                    </div>
                    { renderPermissionType() }
                </div>
            );
        }
        return permissionList;
    }

    return (
        <div className={'field-permission-group field-group'}>
            <div className={'title'}>
                { groupName }
            </div>
            <div className={'permission-container'}>
                { renderPermission() }
            </div>
        </div>
    );
}

export default FieldPermissionGroup;