import { useEffect, useState, useRef, useContext } from 'react';
import { getProductsNeedPrintTags } from '../../services/TagsService';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import ScreenHeader from '../../components/logged/ScreenHeader';
import FieldCheckbox from '../../components/FieldCheckbox';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Button, { ButtonColor, ButtonFontColor, ButtonStyle } from '../../components/Button';
import { ProductTagTypeText, ProductStatusEnum } from 'erva-doce-common';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import FieldTextSearch from '../../components/FieldTextSearch';
import ReprintModal from './ReprintModal';
import './Tags.scss';

export function getTagsRoute() {
    return '/produtos/etiquetas';
}

export default function Tags() {
    const inputSearchRef = useRef();
    const buttonPrintRef = useRef();
    const buttonUpdatePriceRef = useRef();
    const {
        addHotkey,
        removeHotkey,
    } = useContext(EnvironmentContext);

    const [loading, setLoading] = useState(false);
    const [productTags, setProductTags] = useState([]);
    const [selectedProductTags, setSelectedProductTags] = useState([]);
    const [showReprint, setShowReprint] = useState(false);
    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: null,
        count: null
    });
    const [filter, setFilter] = useState({
        order: 'DESC',
        search: null,
        page: 0
    });

    useEffect(() => {
        fetchProductAndLogs();
    }, [filter]);

    useEffect(() => {
        const f2Hotkey = addHotkey('F2', () => {
            inputSearchRef.current?.focus();
        });
        const f6Hotkey = addHotkey('F6', () => {
            buttonPrintRef.current?.click();
        });
        const f7Hotkey = addHotkey('F7', () => {
            buttonUpdatePriceRef.current?.click();
        });

        return () => {
            removeHotkey(f2Hotkey);
            removeHotkey(f6Hotkey);
            removeHotkey(f7Hotkey);
        };
    }, []);

    const fetchProductAndLogs = async () => {
        try {
            setLoading(true);

            const {
                count,
                page,
                pageSize,
                records
            } = await getProductsNeedPrintTags(filter) || {};

            setProductTags(records.map(p => {
                const product = {
                    id: p.id,
                    barCode: p.barCodes[0].barCode,
                    name: `${p.name1} ${p.name2} ${p.name3}`,
                    newPrice: p.newPrice.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    }),
                    tag: ProductTagTypeText(p.tag),
                    printed: p.status === ProductStatusEnum.AWAITING_AUTH_PRICE_UPDATE
                };

                const {
                    prioritizeFamilyPrinting,
                    name,
                    tag
                } = p?.familyModel || {};

                if(prioritizeFamilyPrinting){
                    product.barCode = 'Família';
                    product.name = name || '';
                    product.tag = ProductTagTypeText(tag);
                }

                return product;
            }));

            setPagination({
                page,
                pageSize,
                count
            });
        } catch (e) {
        } finally {
            setLoading(false);
        }
    };

    const handleSelectedProductTag = (idProduct) => {
        setSelectedProductTags(
            selectedProductTags.includes(idProduct) ?
                selectedProductTags.filter(id => id !== idProduct) :
                [...selectedProductTags, idProduct]
        );
    };

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                // I18N
                title={'Listagem'}
                breadcrumbs={[
                    // I18N
                    { name: 'Produtos', route: getDashboardRoute() },
                    // I18N
                    { name: 'Etiquetas' },
                ]}
            />
            <div className={'controls'}>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-8'}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            // I18N
                            label={'<em>Buscar por nomes de <strong>produtos/famílias</strong> [F2]</em>'}
                            onChange={({ target }) => setFilter({ ...filter, search: target.value })}
                        />
                    </div>
                    <div className={'gd-col gd-col-4'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                            onClick={() => setShowReprint(!showReprint)}
                        >
                            {'Reimpressão de etiquetas'}
                        </Button>
                    </div>
                </div>
                <ReprintModal
                    showModal={showReprint}
                    onCloseModal={setShowReprint}
                />
            </div>
            <div className={'table-scroll'}>
                <Table
                    loading={loading}
                    currentSort={filter.order}
                    columns={[
                        {
                            name: '',
                        },
                        {
                            name: 'Cod. barras',
                        },
                        {
                            name: 'Nome produto/família',
                        },
                        {
                            name: 'Novo preço',
                        },
                        {
                            name: 'Etiqueta',
                        },
                        {
                            name: 'Impressa',
                        },
                    ]}
                >
                    {productTags.map(product => {
                        return (
                            <tr key={product.id} className={'product-tag-row'}>
                                <td>
                                    <FieldCheckbox
                                        inputs={[{
                                            value: selectedProductTags.includes(product.id),
                                            onChange: () => handleSelectedProductTag(product.id),
                                            checked: selectedProductTags.includes(product.id)
                                        }]}
                                    />
                                </td>
                                <td>{product.barCode}</td>
                                <td>{product.name}</td>
                                <td>{product.newPrice}</td>
                                <td>{product.tag}</td>
                                <td>{product.printed ? 'Sim' : 'Não'}</td>
                            </tr>
                        );
                    })}
                </Table>
                <Pagination
                    page={pagination.page}
                    pageSize={pagination.pageSize}
                    count={pagination.count}
                    recordCount={productTags.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-8'} />
                        <div className={'gd-col gd-col-2'}>
                            <Button
                                ref={buttonPrintRef}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                loading={false}
                                onClick={() => console.log('Print')}
                            >
                                {'Imprimir [F6]'}
                            </Button>
                        </div>
                        <div className={'gd-col gd-col-2'}>
                            <Button
                                ref={buttonUpdatePriceRef}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                loading={false}
                                onClick={() => console.log('Update Price')}
                            >
                                {'Atualizar preços [F7]'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
