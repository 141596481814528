import FieldCurrency, { extractCurrencyNumber, formatCurrency } from './FieldCurrency';
import FieldInteger from './FieldInteger';
import FieldMachine from './FieldMachine';
import FieldPaymentType from './FieldPaymentType';
import FieldText from './FieldText';


function PaymentList({ formData, setFormData, updateFormData, balanceToPay, emptyPayments, edit, setEdit, ...props }) {


    return (
        <div className={'payment-list'}>
            <div className={'gd field-group payment-header'}>
                <div className={'gd-col-2 mr-25'}>
                    <div className={'container-title'}>
                        {/*I18N*/}
                        <span>{'Total'}</span>
                    </div>
                </div>
                <div className={'gd-col-2 mr-25'}>
                    <div className={'container-title'}>
                        {/*I18N*/}
                        <span>{'Tipo'}</span>
                    </div>
                </div>
                <div className={'gd-col-3'}>
                    <div className={'container-title'}>
                        {/*I18N*/}
                        <span>{'Máquina'}</span>
                    </div>
                </div>
                <div className={'gd-col-1 mr-25'}>
                    <div className={'container-title'}>
                        {/*I18N*/}
                        <span>{'Parcelas'}</span>
                    </div>
                </div>
                <div className={'gd-col-3'}>
                    <div className={'container-title'}>
                        {/*I18N*/}
                        <span>{'Valor parcelado'}</span>
                    </div>
                </div>
            </div>
            {formData.payments?.map((payment, i) => {
                let { total, paymentType, machine, quantityInstallments, amountInstallments, } = payment;

                if (!edit) {
                    total = balanceToPay?.toLocaleString(undefined, { style: 'currency', currency: 'BRL' });
                    amountInstallments = total;
                    emptyPayments.total = total;
                    emptyPayments.amountInstallments =  quantityInstallments === 1 ? 'R$ 0,01' : amountInstallments;
                }


                return (
                    <div key={i} className={'gd field-group'}>
                        <div className={'gd-col-2 mr-25'}>
                            <FieldCurrency

                                // I18N
                                value={total}
                                className={'field-total'}
                                onChange={({ target }) => {
                                    const total = extractCurrencyNumber(formatCurrency(target.value));
                                    const quantity = payment.quantityInstallments;
                                    const amountInstallments = (total / (quantity ? quantity : 1))
                                        .toLocaleString(undefined, { style: 'currency', currency: 'BRL', });
                                    payment.amountInstallments = amountInstallments;
                                    payment.total = formatCurrency(target.value);
                                    setEdit(true);
                                    updateFormData({ payments: [...formData.payments], });
                                }}
                            />
                        </div>
                        <div className={'gd-col-2 mr-25'}>
                            <FieldPaymentType
                                // I18N
                                select={paymentType}
                                readOnly={false}
                                onSelected={(pt) => {
                                    payment.paymentType = pt;
                                    updateFormData({ payments: [...formData.payments], });
                                }}
                            />
                        </div>
                        <div className={'gd-col-3'}>
                            <FieldMachine
                                // I18N
                                select={machine}
                                onSelected={(m) => {
                                    payment.machine = m;
                                    updateFormData({ payments: [...formData.payments] });
                                }}
                            />
                        </div>
                        <div className={'gd-col-1 mr-25'}>
                            <FieldInteger
                                // I18N
                                maxLength={2}
                                readOnly={false}
                                value={quantityInstallments}
                                className={'field-quantity-installments'}
                                onChange={({ target }) => {
                                    const totals = extractCurrencyNumber(total);
                                    const quantityInstallments = Number(target?.value);
                                    const amountInstallments = (totals / (quantityInstallments ? quantityInstallments : 1))
                                        .toLocaleString(undefined, { style: 'currency', currency: 'BRL', });
                                    payment.amountInstallments = amountInstallments;
                                    payment.quantityInstallments = quantityInstallments;
                                    updateFormData({ payments: [...formData.payments] });
                                }}
                            />
                        </div>
                        <div className={'gd-col-3'}>
                            <FieldText
                                // I18N
                                maxLength={120}
                                readOnly={true}
                                validationError={false}
                                value={
                                    quantityInstallments === 1 ? amountInstallments : payment.amountInstallments
                                }
                                className={'field-amount-installments'}
                                onChange={
                                    ({ target }) => {
                                        payment.amountInstallments = target?.value;
                                        updateFormData({ payments: [...formData.payments] });
                                    }}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default PaymentList;
