import { RoleEnum } from 'erva-doce-common';
import { useContext, useEffect, useRef, } from 'react';
import { useLocation } from 'react-router';
import { getCfopRoute } from '../../admin/cfop/Cfop';
import { getCollaboratorsRoute } from '../../admin/collaborators/Collaborators';
import { getExchangesRoute } from '../../admin/exchanges/Exchanges';
import { getExpensesTypesRoute } from '../../admin/expenses-types/ExpensesTypes';
import { getIcmsRoute } from '../../admin/icms/Icms';
import { getJobsTitlesRoute } from '../../admin/jobs-titles/JobsTitles';
import { getMachinesRoute } from '../../admin/machines/Machines';
import { getPaymentsTypesRoute } from '../../admin/payments-types/PaymentsTypes';
import { getProductsBrandsRoute } from '../../admin/products-brands/ProductsBrands';
import { getProductsCategoriesRoute } from '../../admin/products-categories/ProductsCategories';
import { getProductFamiliesRoute } from '../../admin/products-families/ProductsFamilies';
import { getProductsRoute } from '../../admin/products/Products';
import { getPurchaseSuggestionRoute } from '../../admin/purchase-suggestion/PurchaseSuggestion';
import { getQuotationsTypesRoute } from '../../admin/quotations-types/QuotationsTypes';
import { getRepresentativesRoute } from '../../admin/representatives/Representatives';
import { getSalesRoute } from '../../admin/sales/Sales';
import { getSegmentationsRoute } from '../../admin/segmentations/Segmentations';
import { getStoresTypesRoute } from '../../admin/stores-types/StoresTypes';
import { getStoresRoute } from '../../admin/stores/Stores';
import { getSuppliersRoute } from '../../admin/suppliers/Suppliers';
import { getTaxesClassificationsRoute } from '../../admin/taxes-classifications/TaxesClassifications';
import { getTaxesSituationsRoute } from '../../admin/taxes-situations/TaxesSituations';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getMyProfileRoute } from '../../my-profile/MyProfile';
import { getTagsRoute } from '../../admin/tags/Tags';
import * as LoginService from '../../services/LoginService';
import Button from '../Button';
import Picture, {
    PictureSize,
    PictureStyle
} from '../Picture';
import {
    IconButtonBack,
    IconButtonBack2x,
    MainMenuAdmin,
    MainMenuAdmin2x,
    MainMenuAdminSelected,
    MainMenuAdminSelected2x,
    MainMenuCashierService,
    MainMenuCashierService2x,
    MainMenuCashierServiceSelected,
    MainMenuCashierServiceSelected2x,
    MainMenuEditUser,
    MainMenuEditUser2x,
    MainMenuLogoff,
    MainMenuLogoff2x,
    MainMenuProduct,
    MainMenuProduct2x,
    MainMenuProductSelected,
    MainMenuProductSelected2x,
    MainMenuWallet,
    MainMenuWallet2x,
    MainMenuWalletSelected,
    MainMenuWalletSelected2x,
} from '../images';
import './MainMenu.scss';
import { CollapseGroupItem, GroupItem, MainMenuGroup } from './MainMenuGroup';
import { getSalesFormRoute } from '../../admin/sales/SalesForm';
import { getOrdersRoute } from '../../admin/order/Orders';
import { getCancelReasonRoute } from '../../admin/cancel-reason/CancelReason';
import { getOrderListRoute } from '../../admin/order-list/OrderList';
import { getOrderDocumentsRoute } from '../../admin/order-documents/OrderDocuments';
import { getStockRoute } from '../../admin/stock/Stock';
import ProfileDropDown from '../ProfileDropDown';
import { getStockCheckRoute } from '../../admin/stock/stock-check/StockCheck';
import MainMenuButton from './MainMenuButton';
import { getAdminStockCheckRoute } from '../../admin/stock/admin-stock-check/AdminStockCheck';
import { getDivergenceReasonRoute } from '../../admin/divergence-reason/DivergenceReason';
import { getCustomersRoute } from '../../admin/customers/Customers';
import { getNcmRoute } from '../../admin/ncm/Ncm';
import { getDeliveryAreaRoute } from '../../admin/loja-virtual/areas-de-entrega';
import { getBannersRoute } from '../../admin/loja-virtual/banners';
import { getParametrosRoute } from '../../admin/loja-virtual/parametros';
import { getMenuPrincipalRoute } from '../../admin/loja-virtual/menu-principal';
import { getRodapeRoute } from '../../admin/loja-virtual/rodape';

export default function MainMenu() {
    const mobileWidth = 576;
    const {
        user,
        setLoading,
        setIsLogged,
        setUser,
        backendConnectionError,
        menuCollapsed,
        setMenuCollapsed,
    } = useContext(EnvironmentContext);

    const mainMenuRef = useRef();
    const timerCollapse = useRef(null);

    async function logoff() {
        setLoading(true, false, 'Saindo...');
        try {
            await LoginService.logoff();
            setIsLogged(false);
            setUser(null);
        } catch (e) {
            backendConnectionError('Fail to logoff', e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        function mouseOver() {
            if (window.innerWidth < mobileWidth) return;

            clearTimeout(timerCollapse.current);
            timerCollapse.current = setTimeout(() => {
                setMenuCollapsed(false);
                setTimeout(() => {
                    const selected = mainMenuRef.current?.querySelector('.selected');
                    if (selected) {
                        selected.scrollIntoView();
                    }
                }, 200);
            }, 300);
        }

        function mouseOut() {
            if (window.innerWidth < mobileWidth) return;

            clearTimeout(timerCollapse.current);
            timerCollapse.current = setTimeout(() => setMenuCollapsed(true), 100);
        }

        mainMenuRef.current?.addEventListener('mouseenter', mouseOver);
        mainMenuRef.current?.addEventListener('mouseleave', mouseOut);

        setTimeout(() => {
            setMenuCollapsed(true);
        }, 100);

        return () => {
            mainMenuRef.current?.removeEventListener('mouseenter', mouseOver);
            mainMenuRef.current?.removeEventListener('mouseleave', mouseOut);
        };
    }, []);

    const location = useLocation();

    return (
        <div
            ref={mainMenuRef}
            className={`main-menu ${menuCollapsed ? 'collapsed' : ''}`}
        >
            <div className={'d-flex mb-10 justify-content-end hide-desktop'}>
                <a className={'close-button'} aria-label={'Fechar menu'} onClick={() => { setMenuCollapsed(true);}} title={'Fechar menu'}>
                    <img src={IconButtonBack} srcSet={`${IconButtonBack} 1x, ${IconButtonBack2x} 2x`} alt={'Fechar'}/>
                    {'Fechar'}
                </a>
            </div>
            <div className={'user-container'}>
                <Picture
                    picture={user?.picture}
                    name={user?.name}
                    style={PictureStyle.USER_SQUARE}
                    size={PictureSize.MAIN_MENU}
                />
                <div className={'user-info'}>
                    <div className={'user-name'} title={user?.name ?? '...'}>
                        {user?.name ?? '...'}
                    </div>
                    <div className={'job-title'}>{user?.jobTitle ?? ''}</div>
                </div>
                <div className={'user-controls'}>
                    <Button
                        className={'button transparent'}
                        route={getMyProfileRoute()}
                    >
                        <img
                            src={MainMenuEditUser}
                            srcSet={`${MainMenuEditUser} 1x, ${MainMenuEditUser2x} 2x`}
                            alt={''}
                        />
                    </Button>
                </div>
            </div>
            <div className={'d-flex justify-content-center mb-20 hide-desktop'}>
                <ProfileDropDown />
            </div>
            <div className={'menu-container'}>
                <div className={'scroll-container'}>
                    <div>
                        <MainMenuGroup
                            icon={MainMenuCashierService}
                            icon2x={MainMenuCashierService2x}
                            iconSelected={MainMenuCashierServiceSelected}
                            iconSelected2x={MainMenuCashierServiceSelected2x}
                            // I18N
                            title={'Atendimento Caixa'}
                            // I18N
                            defaultShow={location.pathname.startsWith(
                                '/vendas/'
                            )}
                            menuCollapsed={menuCollapsed}
                        >
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_SALE_ADD
                            ) && (
                                // I18N
                                <GroupItem route={getSalesFormRoute()}>
                                    {'Vender'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_SALE_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getSalesRoute()}>
                                    {'Listagem de vendas'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_EXCHANGE_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getExchangesRoute()}>
                                    {'Listagem de trocas'}
                                </GroupItem>
                            )}
                            {/* {user.roles?.includes(RoleEnum.PERMISSION_SALE_VISUALIZE) && (
                            // I18N
                            <GroupItem route={() => { }}>{'Caixa'}</GroupItem>
                        )} */}
                        </MainMenuGroup>
                        <MainMenuGroup
                            icon={MainMenuProduct}
                            icon2x={MainMenuProduct2x}
                            iconSelected={MainMenuProductSelected}
                            iconSelected2x={MainMenuProductSelected2x}
                            // I18N
                            title={'Produtos'}
                            // I18N
                            defaultShow={location.pathname.startsWith(
                                '/produtos/'
                            )}
                            menuCollapsed={menuCollapsed}
                        >
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_STOCK_EDIT
                            ) && (
                                <CollapseGroupItem title={'Estoque'} route={'/produtos/estoque'} menuCollapsed={menuCollapsed}>
                                    {user.roles?.includes(
                                        RoleEnum.PERMISSION_STOCK_EDIT
                                    ) && (
                                        <GroupItem route={getStockRoute()}>
                                            {'Gerenciar'}
                                        </GroupItem>
                                    )}
                                    {user.roles?.includes(
                                        RoleEnum.PERMISSION_STOCK_EDIT
                                    ) && (
                                        <GroupItem route={getStockCheckRoute()}>
                                            {'Minhas conferências'}
                                        </GroupItem>
                                    )}
                                    {user.roles?.includes(
                                        RoleEnum.PERMISSION_STOCK_EDIT
                                    ) && (
                                        <GroupItem route={getAdminStockCheckRoute()}>
                                            {'Administrar conferências'}
                                        </GroupItem>
                                    )}
                                    {user.roles?.includes(
                                        RoleEnum.PERMISSION_STOCK_EDIT
                                    ) && (
                                        <GroupItem route={getDivergenceReasonRoute()}>
                                            {'Motivos de divergência'}
                                        </GroupItem>
                                    )}
                                </CollapseGroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_PRODUCT_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getProductsRoute()}>
                                    {'Listagem de produtos'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_PRODUCT_FAMILY_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getProductFamiliesRoute()}>
                                    {'Famílias de produtos'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_PRODUCT_CATEGORY_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getProductsCategoriesRoute()}>
                                    {'Categorias de produtos'}
                                </GroupItem>
                            )}
                            {/* {user.roles?.includes(RoleEnum.PERMISSION_PRODUCT_CATEGORY_VISUALIZE) && (
                            // I18N
                            <GroupItem route={() => { }}>{'Grupo de produtos'}</GroupItem>
                        )} */}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_PRODUCT_BRAND_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getProductsBrandsRoute()}>
                                    {'Marcas de produtos'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_SEGMENTATION_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getSegmentationsRoute()}>
                                    {'Segmentações'}
                                </GroupItem>
                            )}

                            {user.roles?.includes(
                                RoleEnum.PERMISSION_QUOTATION_TYPE_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getQuotationsTypesRoute()}>
                                    {'Tipos de cotações'}
                                </GroupItem>
                            )}

                            {user.roles?.includes(
                                RoleEnum.PERMISSION_QUOTATION_TYPE_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getTagsRoute()}>
                                    {'Impressão de etiquetas'}
                                </GroupItem>
                            )}
                        </MainMenuGroup>
                        <MainMenuGroup
                            icon={MainMenuWallet}
                            icon2x={MainMenuWallet2x}
                            iconSelected={MainMenuWalletSelected}
                            iconSelected2x={MainMenuWalletSelected2x}
                            // I18N
                            title={'Controle Financeiro'}
                            // I18N
                            defaultShow={location.pathname.startsWith(
                                '/financeiro/'
                            )}
                            menuCollapsed={menuCollapsed}
                        >
                            {/* {user.roles?.includes(RoleEnum.PERMISSION_SALE_VISUALIZE) && (
                            // I18N
                            <GroupItem route={() => { }}>{'Contas a Pagar'}</GroupItem>
                        )}
                        {user.roles?.includes(RoleEnum.PERMISSION_BILLS_TO_RECEIVE_VISUALIZE) && (
                            // I18N
                            <GroupItem route={getBillsToReceiveRoute()}>{'Contas a Receber'}</GroupItem>
                        )} */}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_EXPENSE_TYPE_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getExpensesTypesRoute()}>
                                    {'Tipos de despesas'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_PAYMENT_TYPE_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getPaymentsTypesRoute()}>
                                    {'Tipos de pagamentos'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_MACHINE_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getMachinesRoute()}>
                                    {'Máquinas de pagamentos'}
                                </GroupItem>
                            )}
                        </MainMenuGroup>
                        <MainMenuGroup
                            icon={MainMenuAdmin}
                            icon2x={MainMenuAdmin2x}
                            iconSelected={MainMenuAdminSelected}
                            iconSelected2x={MainMenuAdminSelected2x}
                            // I18N
                            title={'Administrativo'}
                            // I18N
                            defaultShow={location.pathname.startsWith(
                                '/administrativo/'
                            )}
                            menuCollapsed={menuCollapsed}
                        >
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_COLLABORATOR_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getCollaboratorsRoute()}>
                                    {'Colaboradores'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_JOB_TITLE_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getJobsTitlesRoute()}>
                                    {'Cargos'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_SUPPLIER_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getSuppliersRoute()}>
                                    {'Fornecedores'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_REPRESENTATIVE_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getRepresentativesRoute()}>
                                    {'Representantes'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_STORE_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getStoresRoute()}>
                                    {'Lojas'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_STORE_TYPE_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getStoresTypesRoute()}>
                                    {'Tipos de lojas'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_STORE_TYPE_VISUALIZE
                            ) && (
                                <GroupItem route={getCustomersRoute()}>
                                    {'Clientes'}
                                </GroupItem>
                            )}
                        </MainMenuGroup>
                        <MainMenuGroup
                            icon={MainMenuAdmin}
                            icon2x={MainMenuAdmin2x}
                            iconSelected={MainMenuAdminSelected}
                            iconSelected2x={MainMenuAdminSelected2x}
                            // I18N
                            title={'Fiscal'}
                            defaultShow={location.pathname.startsWith(
                                '/fiscal/'
                            )}
                            menuCollapsed={menuCollapsed}
                        >
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_ICMS_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getIcmsRoute()}>
                                    {'ICMS'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_TAX_CLASSIFICATION_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem
                                    route={getTaxesClassificationsRoute()}
                                >
                                    {'Classificações fiscais'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_TAX_SITUATION_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getTaxesSituationsRoute()}>
                                    {'Situações tributárias'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_CFOP_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getCfopRoute()}>
                                    {'CFOP'}
                                </GroupItem>
                            )}
                            <GroupItem route={getNcmRoute()}>
                                {'NCM'}
                            </GroupItem>

                        </MainMenuGroup>
                        <MainMenuGroup
                            icon={MainMenuAdmin}
                            icon2x={MainMenuAdmin2x}
                            iconSelected={MainMenuAdminSelected}
                            iconSelected2x={MainMenuAdminSelected2x}
                            // I18N
                            title={'Compras'}
                            // I18N
                            defaultShow={location.pathname.startsWith(
                                '/compras/'
                            )}
                            menuCollapsed={menuCollapsed}
                        >
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_PURCHASE_SUGGESTION_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getPurchaseSuggestionRoute()}>
                                    {'Sugestões de pedidos'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_ORDER_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getOrdersRoute()}>
                                    {'Acompanhamento de pedidos'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_ORDER_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getOrderListRoute()}>
                                    {'Listagem de pedidos'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_ORDER_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getOrderDocumentsRoute()}>
                                    {'XML importados'}
                                </GroupItem>
                            )}
                            {user.roles?.includes(
                                RoleEnum.PERMISSION_CANCEL_REASON_VISUALIZE
                            ) && (
                                // I18N
                                <GroupItem route={getCancelReasonRoute()}>
                                    {'Motivos de cancelamento'}
                                </GroupItem>
                            )}
                        </MainMenuGroup>
                        <MainMenuGroup
                            icon={MainMenuAdmin}
                            icon2x={MainMenuAdmin2x}
                            iconSelected={MainMenuAdminSelected}
                            iconSelected2x={MainMenuAdminSelected2x}
                            title={'Loja virtual'}
                            defaultShow={location.pathname.startsWith(
                                '/loja-virtual/'
                            )}
                            menuCollapsed={menuCollapsed}
                        >
                            <CollapseGroupItem title={'Marketing'} route={'/loja-virtual/marketing'} menuCollapsed={menuCollapsed}>
                                <GroupItem route={getBannersRoute()}>
                                    {'Banners'}
                                </GroupItem>
                                <GroupItem route={getCancelReasonRoute()}>
                                    {'Cupons'}
                                </GroupItem>
                                <GroupItem route={getCancelReasonRoute()}>
                                    {'Newsletters'}
                                </GroupItem>
                            </CollapseGroupItem>
                            <CollapseGroupItem title={'Entregas'} route={'/loja-virtual/entregas'} menuCollapsed={menuCollapsed}>
                                <GroupItem route={getDeliveryAreaRoute()}>
                                    {'Áreas de entrega'}
                                </GroupItem>
                                <GroupItem route={getDeliveryAreaRoute() + '/sdas'}>
                                    {'Integrações'}
                                </GroupItem>
                            </CollapseGroupItem>
                            <CollapseGroupItem title={'Pagamento'} route={'/loja-virtual/pagamento'} menuCollapsed={menuCollapsed}>
                                <GroupItem route={getCancelReasonRoute()}>
                                    {'Métodos de pagamento'}
                                </GroupItem>
                            </CollapseGroupItem>
                            <CollapseGroupItem title={'Geral'} route={'/loja-virtual/geral'} menuCollapsed={menuCollapsed}>
                                <GroupItem route={getParametrosRoute()}>
                                    {'Parâmetros'}
                                </GroupItem>
                                <GroupItem route={getMenuPrincipalRoute()}>
                                    {'Menu principal'}
                                </GroupItem>
                                <GroupItem route={getRodapeRoute()}>
                                    {'Rodapé'}
                                </GroupItem>
                            </CollapseGroupItem>
                        </MainMenuGroup>
                        <MainMenuButton
                            className={'logoff'}
                            icon={MainMenuLogoff}
                            icon2x={MainMenuLogoff2x}
                            title={'Sair'}
                            onClick={logoff}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
