import { forwardRef } from 'react';
import { parseNumberLocale } from '../util/numberParser';
import Field from './Field';

const prefix = 'R$';

function FieldCurrency({
    onChange,
    value,
    ...props
}) {

    return (
        <div className={'input-container'}>
            <Field
                {...props}
            >
                <input
                    value={value}
                    onChange={onChange}
                />
            </Field>
        </div>
    );
}

export default forwardRef(FieldCurrency);

export function extractCurrencyNumber(value) {
    const specialCharacter = value.match(/^[a-zA-Z]+\$,|_$/);
    if (!value || specialCharacter) return null;

    return parseNumberLocale(value.replace(prefix, '').trim());
}

export const formatCurrency = (value, first=false) => {
    const number = value.replace(/([\D])/g, '');
    const amount = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });

    if (first) {
        return `${amount.format(number)}`;
    } else {
        return `${amount.format(number / 100)}`;
    }
};
