import * as Sentry from '@sentry/react';
import axios from 'axios';
import { ExchangeStatusEnum, RoleEnum, ValidationErrorExchangesText } from 'erva-doce-common';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Amount from '../../components/Amount';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import ButtonRemove from '../../components/ButtonRemove';
import FieldInteger from '../../components/FieldInteger';
import FieldText, { FieldTextLabelStyle } from '../../components/FieldText';
import FieldTextSearch from '../../components/FieldTextSearch';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import SearchProductModal from '../../components/SearchProductModal';
import Table from '../../components/Table';
// import Total from '../../components/Total';
import { IconSearch, IconSearch2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as ExchangesService from '../../services/ExchangesService';
import * as ProductsService from '../../services/ProductsService';
import { formatValue } from '../../util/formatValue';
import { getExchangesRoute } from './Exchanges';
import './ExchangesForm.scss';

export default function ExchangesForm() {
    const {
        backendConnectionError,
        setInfoModal,
        setWindowTitle,
        addHotkey,
        removeHotkey,
        user,
        setConfirmModal
    } = useContext(EnvironmentContext);
    const emptyFormData = {
        products: [],
        clientName: ''
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [, setValidateOnChange] = useState(false);
    const { uuid } = useParams();
    const [exchangeName, setExchangeName] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [, setDeleteLoading] = useState(false);
    const [filter, setFilter] = useState({
        search: null,
    });
    const inputAmount = useRef();
    const inputSearchRef = useRef();
    const [amount, setAmount] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [productsSelected, setProductsSelected] = useState([]);
    const navigate = useNavigate();


    const canSave = uuid
        ? user.roles?.includes(RoleEnum.PERMISSION_JOB_TITLE_EDIT)
        : user.roles?.includes(RoleEnum.PERMISSION_JOB_TITLE_ADD);
    const canRemove = user.roles?.includes(RoleEnum.PERMISSION_JOB_TITLE_REMOVE);

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchExchange() {
        try {
            setLoading(true);
            const exchange = await ExchangesService.getExchange(uuid);

            setProductsSelected(exchange.products);

            setFormData({
                ...exchange
            });
            setExchangeName(exchange.clientName);
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    // I18N
                    message: 'Troca não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch exchange', e, null, title);
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            // noinspection JSIgnoredPromiseFromCall
            fetchExchange();
        }
    }, [uuid]);

    useEffect(() => {
        // I18N
        setWindowTitle('Troca de produtos');
        const f2Hotkey = addHotkey('F2', () => {
            setShowModal(true);
        });
        const f4Hotkey = addHotkey('F4', () => {
            deleteExchange();
        });

        return () => {
            removeHotkey(f2Hotkey);
            removeHotkey(f4Hotkey);

            setWindowTitle();
        };
    }, []);

    useEffect(() => {
        const f6Hotkey = addHotkey('F6', () => {
            save(false);
        });

        return () => {
            removeHotkey(f6Hotkey);
        };
    }, [formData.clientName]);



    function back() {
        navigate(getExchangesRoute());
    }

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!formData.clientName?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, clientName: 'Digite o nome do cliente' }));
        }

        return hasError;
    }

    async function save(returnRoute) {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);
            const body = {
                ...formData,
                lineQuantity: productsSelected.length,
                itemsQuantity: productsSelected.reduce((sum, pro) => { return sum + Number(pro.amount); }, 0),
                total: productsSelected.reduce((sum, pro) => { return sum + Number(pro.total); }, 0),
                status: returnRoute ? ExchangeStatusEnum.IN_EDIT : ExchangeStatusEnum.AWAITING_RESCUE,
                products: productsSelected
            };

            let message;
            if (!uuid) { // new exchange
                await ExchangesService.addExchange(body);
                // I18N
                message = 'Troca salva com sucesso!';
            } else {
                await ExchangesService.editExchange(uuid, body);
                // I18N
                message = 'Troca editada com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;
            setHasChange(false);

            if (returnRoute) {
                setTimeout(() => {
                    navigate(getExchangesRoute());
                }, 200);
            } else {
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            }
        } catch (e) {
            backendConnectionError('Fail to create/edit exchange', e, null, title, ValidationErrorExchangesText);
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteExchange() {
        // I18N
        let title = 'Cancelar troca';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await ExchangesService.deleteExchange(uuid);
                // I18N
                const message = 'Troca cancelada com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete exchange', e, null, title, ValidationErrorExchangesText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja cancelar a troca?',
            onConfirm: proceed,
            show: true,
        });
    }

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar troca';
            if (windowTitle && exchangeName) title += ` - ${exchangeName}`;
            return title;
        } else {
            return 'Nova troca';
        }
    }

    const addProduct = useCallback(
        (product) => {
            const productFound = productsSelected.find(
                cartProduct => cartProduct.uuid === product.uuid,
            );

            if (!productFound) {
                setProductsSelected(prevProducts => [
                    ...prevProducts,
                    {
                        ...product,
                        amount: Number(amount),
                        total: product.newPrice * Number(amount)
                    },
                ]);
            } else {
                setProductsSelected(prevProducts =>
                    prevProducts.map(product => {
                        if (productFound.uuid === product.uuid) {
                            return {
                                ...product,
                                amount: Number(product.amount) + Number(amount),
                                total: product.newPrice * (Number(product.amount) + Number(amount))
                            };
                        }

                        return product;
                    }),
                );
            }
        },
        [amount, productsSelected],
    );

    const fetchProdctByBarCode = useCallback(async () => {
        try {
            if (filter.search) {
                const product = await ProductsService.getProductByBarCode(filter.search);
                if (product.uuid) {
                    addProduct(product);

                    setFilter({
                        search: null
                    });

                    inputSearchRef.current.value = '';

                    setAmount(1);
                }
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
            }
        }
    }, [filter.search]);

    useEffect(() => {
        fetchProdctByBarCode();
    }, [fetchProdctByBarCode, filter.search]);

    const totalExchange = useMemo(() => {
        return formatValue((productsSelected.reduce((sum, product) => { return sum + Number(product.total); }, 0)) ?? 0);
    }, [productsSelected]);

    const totalItens = useMemo(() => {
        return productsSelected.reduce((sum, product) => { return sum + Number(product.amount); }, 0) ?? 0;
    }, [productsSelected]);

    const title = getTitle();
    return (
        <>
            <RouteChangePrompt
                enabled={hasChange}
                message={
                    'Deseja salvar a troca com o status <strong>Em edição</strong>?'
                }
                onConfirm={() => {
                    save(true);
                }}
            />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Administrativo', route: getDashboardRoute() },
                        { name: 'Trocas', route: getExchangesRoute() },
                        {
                            name: uuid
                                ? loading
                                    ? '...'
                                    : exchangeName
                                : title,
                        },
                    ]}
                    backRoute={getExchangesRoute()}
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col-2'}>
                            <div className={'quantity-field'}>
                                <p> {'Quantidade:'} </p>
                                <FieldInteger
                                    ref={inputAmount}
                                    value={amount}
                                    onChange={({ target }) =>
                                        setAmount(target.value)
                                    }
                                    thousandsSeparator={false}
                                    maxLength={15}
                                    height={'50px'}
                                    fieldGroup={false}
                                    radius={'12px 0px 0px 12px'}
                                    className={'amount-field'}
                                />
                            </div>
                        </div>
                        <div className={'gd-col-4'}>
                            <div className={'gd-col-12'}>
                                <FieldTextSearch
                                    ref={inputSearchRef}
                                    // I18N
                                    label={
                                        '<em>Buscar por <strong>código de barras</strong> [F2]</em>'
                                    }
                                    onChange={({ target }) =>
                                        setFilter({
                                            ...filter,
                                            search: target.value,
                                        })
                                    }
                                    radius={'0px 0px 0px 0px'}
                                    pointer={true}
                                    icon={''}
                                    icon2x={''}
                                />

                                <Button
                                    style={{
                                        width: '50px',
                                        padding: '0px',
                                        borderRadius: '0px 12px 12px 0px',
                                    }}
                                    buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                    color={ButtonColor.BUTTON_COLOR_WHITE}
                                    onClick={() => setShowModal(true)}
                                    icon={IconSearch}
                                    icon2x={IconSearch2x}
                                />
                            </div>
                        </div>
                        <div className={'gd-col gd-col-2'}></div>
                        <div className={'gd'}>
                            <div className={'gd-col gd-col-6'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                    color={ButtonColor.BUTTON_COLOR_GRAY}
                                    style={{ display: `${uuid ? '' : 'none'}` }}
                                    onClick={deleteExchange}
                                >
                                    {'Cancelar troca [F4]'}
                                </Button>
                            </div>
                            <div className={'gd-col gd-col-6'}>
                                <Button
                                    buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                    onClick={() => {
                                        save(false);
                                    }}
                                >
                                    {'Finalizar troca [F6]'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <fieldset>
                    <Table
                        loading={loading}
                        columns={[
                            {
                                // I18N
                                name: 'Linhas',
                            },
                            {
                                // I18N
                                name: 'Descrição do produto',
                            },
                            {
                                // I18N
                                name: 'Quantidade',
                            },
                            {
                                // I18N
                                name: 'Preço Unitário',
                            },
                            {
                                // I18N
                                name: 'Total',
                            },
                            {},
                        ]}
                    >
                        {productsSelected.map((product, index) => (
                            <tr key={product.uuid}>
                                <td>{index + 1}</td>
                                <td>{product.name1}</td>
                                <td>{product.amount}</td>
                                <td>
                                    {product.newPrice?.toLocaleString(
                                        undefined,
                                        { style: 'currency', currency: 'BRL' }
                                    )}
                                </td>
                                <td>
                                    {product.total?.toLocaleString(undefined, {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </td>
                                <td>
                                    <ButtonRemove
                                        onClick={() =>
                                            setProductsSelected((prevState) => {
                                                return prevState.filter(
                                                    (selectedProduct) =>
                                                        selectedProduct.uuid !==
                                                        product.uuid
                                                );
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                    </Table>
                </fieldset>
                <fieldset>
                    <div className={'gd'}>
                        <div
                            className={'gd-col-6 align-left'}
                            style={{ flexDirection: 'column' }}
                        >
                            <div className={'gd-col-12'}>
                                <FieldText
                                    // I18N
                                    label={
                                        'Digite o nome do cliente para registrar a troca (*campo obrigatório)'
                                    }
                                    value={formData?.clientName}
                                    validationError={formError?.clientName}
                                    onChange={({ target }) =>
                                        updateFormData({
                                            clientName: target.value,
                                        })
                                    }
                                    fieldGroup={false}
                                    labelStyle={
                                        FieldTextLabelStyle.LABEL_INLINE
                                    }
                                    radius={'12px 0px 0px 12px'}
                                />
                            </div>

                            <div style={{ marginTop: '8px' }}>
                                <div className={'gd'}>
                                    <div className={'gd-col-6'}>
                                        <Amount
                                            // I18N
                                            title={'Quantidade de linhas'}
                                            amount={productsSelected.length}
                                            radius={'12px 0px 0px 12px'}
                                            className={'amount-exchange'}
                                        />
                                    </div>
                                    <div className={'gd-col-6'}>
                                        <Amount
                                            // I18N
                                            title={'Quantidade de itens'}
                                            amount={totalItens}
                                            radius={'0px 0px 0px 0px'}
                                            className={'amount-exchange'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'gd-col-6 align-right'}>
                            <div className={'total-exchange'}>
                                <div className={'title'}>
                                    {'Total troca'}
                                    <span>{totalExchange}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <SearchProductModal
                show={showModal}
                onCancel={() => setShowModal(false)}
                onSelect={(prod) => {
                    if (prod) {
                        addProduct(prod);
                        setAmount(1);
                        setShowModal(false);
                    }
                }}
            />
        </>
    );
}

export function getExchangesFormRoute(uuid) {
    // I18Ns
    if (uuid) {
        return `/vendas/trocas/${uuid}`;
    } else {
        return '/vendas/trocas/novo';
    }
}
