import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import { useState } from 'react';
import Pills from '../../components/Pills';
import FieldSegmentations from '../../components/FieldSegmentation';

function SuppliersFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {
    const [ segmentations, setSegmentations ] = useState(filter?.segmentations ?? []);

    function confirm() {
        onConfirm({
            segmentations,
        });
    }

    function cancel() {
        onCancel();
        setSegmentations(filter.segmentations);
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            // I18N
            title={'Filtrar fornecedores'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldSegmentations
                        multipleSelection={true}
                        onAddItem={(record) => {
                            setSegmentations((segmentations) => {
                                return [
                                    ...segmentations,
                                    record
                                ];
                            });
                        }}
                        ignoreItems={segmentations.map(record => record.id)}
                    >
                        <Pills
                            pills={segmentations}
                            onRemoveItem={(record) => {
                                setSegmentations((segmentations) => {
                                    return segmentations.filter(item => item.id !== record.id);
                                });
                            }}
                        />
                    </FieldSegmentations>
                </div>
            </div>

        </SimpleConfirmModal>
    );
}

export default SuppliersFilterModal;