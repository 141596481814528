import Table from '../../../components/Table';
import Button from '../../../components/Button';
import { IconButtonRemove, IconButtonRemove2x, IconButtonRemoveSvg, IconEdit, IconEdit2x, IconEditSvg } from '../../../components/images';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import * as BillingsService from '../../../services/BillingsServices';
import Pagination from '../../../components/Pagination';
import { formatValue } from '../../../util/formatValue';
import { OrderBillingTypeText } from 'erva-doce-common';

const FILTERS = {
    order: null,
    page: 0,
    orderId: null,
};

export default function BillingList({ order, afterDelete }) {
    const navigate = useNavigate();
    const { setInfoModal, setConfirmModal, setLoading, backendConnectionError } = useContext(EnvironmentContext);

    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState(FILTERS);

    const getItems = async () => {
        try {
            setIsLoading(true);
            const data = await BillingsService.getBillings(filter);

            setData(data);
        } catch (err) {
            console.log(err);
            backendConnectionError('Fail to fetch Billings', err, null);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteBilling = async (id) => {
        try {
            const proceed = async () => {
                try {
                    setIsLoading(true);

                    await BillingsService.deleteBilling(id);

                    setInfoModal({
                        title: 'Faturamento',
                        message: 'Faturamento cancelado com sucesso',
                        onClose: () => {
                            getItems();
                            afterDelete();
                        },
                        show: true,
                    });

                } catch(err) {
                    console.log(err);
                    backendConnectionError('Fail to delete Billing', err, null);
                } finally {
                    setIsLoading(false);
                }
            };

            setConfirmModal({
                title: 'Faturamento',
                message: 'Você tem certeza de que deseja cancelar o faturamento do pedido?',
                onConfirm: proceed,
                show: true,
            });
        } catch(err) {
            console.log(err);
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!filter.orderId) return;
        getItems();
    }, [filter]);

    useEffect(() => {
        setFilter({ ...filter, orderId: order.id });
    }, [order]);

    return (
        <>
            <Table
                loading={isLoading}
                columns={[
                    {
                        name: 'Identificador',
                    },
                    {
                        name: 'Tipo',
                        align: 'center',
                    },
                    {
                        name: 'Valor',
                        align: 'center',
                    },
                    {
                        name: 'Pendência',
                        align: 'center',
                    },
                    {
                        name: 'Data',
                        align: 'center',
                    },
                    {
                        name: '',
                        align: 'end',
                        width: '50%',
                    },
                ]}
            >
                {data?.records?.length ? data?.records?.map((billing, index) => (
                    <tr key={index}>
                        <td>{billing.uuid}</td>
                        <td className={'text-center'}>{OrderBillingTypeText(billing.type)}</td>
                        <td className={'text-center'}>{formatValue(billing.total || 0)}</td>
                        <td className={'text-center'}>{formatValue(billing.pending || 0)}</td>
                        <td className={'text-center'}>{billing.createdAt}</td>
                        <td>
                            <div className={'d-flex justify-content-end'}>
                                <Button
                                    className={'transparent mr-10'}
                                    onClick={() => navigate(`/compras/pedidos/${order.id}/faturamento/${billing.uuid}`)}
                                >
                                    <img
                                        src={IconEditSvg}
                                        srcSet={`${IconEdit} 1x, ${IconEdit2x} 2x`}
                                        alt={'Editar'}
                                        title={'Editar'}
                                        style={{
                                            width: '16px',
                                        }}
                                    />
                                </Button>
                                <Button
                                    className={'transparent'}
                                    onClick={() => deleteBilling(billing.uuid)}
                                >
                                    <img
                                        src={IconButtonRemoveSvg}
                                        srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                        alt={'Remover'}
                                        title={'Remover'}
                                        style={{
                                            width: '12px',
                                        }}
                                    />
                                </Button>
                            </div>
                        </td>
                    </tr>
                )) : <tr><td colSpan={17} className={'text-center'}>{'Nenhum item encontrado'}</td></tr>}
                {
                    !!data?.records?.length && (
                        <tr className={'total-row'}>
                            <td></td>
                            <td></td>
                            <td className={'text-center'}>{formatValue(data?.totals?.total || 0)}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    )
                }
            </Table>
            <Pagination
                page={data?.page}
                pageSize={data?.pageSize}
                count={data?.count}
                recordCount={data?.records?.length || 0}
                onPageChange={(page) => setFilter({ ...filter, page })}
            />
        </>
    );
}
