import React, {  useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { extractCurrencyNumber } from '../../components/FieldCurrency';

import ReceiptFormModal from './ReceiptFormModal';
import styles from './ReceiptList.module.scss';
import { format } from 'date-fns';

function ReceiptsList() {
    const { control, getValues } = useFormContext();
    const [ openReceiptFormModal, setOpenReceiptFormModal ] = useState(false);
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'receipts'
    });

    const receipts = getValues('receipts');

    const handleOpenReceiptFormModal = () => {
        setOpenReceiptFormModal(prevState => !prevState);
        const total = getValues('installmentValue');
        
        append({
            deliveryDate: '',
            value: extractCurrencyNumber(total),
            receiptType: {
                id: undefined,
                value: undefined
            },
            previousReceipt: false,
        });
    };

    return (
        <div className={styles.bills_container}>
            { Array.isArray(receipts) && receipts.length >= 1 && (
                <>
                    <div className={styles.bills_container_title}>
                        <span>{ 'Recebimentos' }</span>
                    </div>

                    <div className={styles.bills_list_header}>
                        <span>{ 'Data do recebimento' }</span>
                        <span>{ 'Valor recebido' }</span>
                        <span>{ 'Tipo de recebimento' }</span>
                    </div>

                    <ul className={styles.bills_list}>
                        { receipts && receipts.map((receipt, index) => (
                            <li key={index}>
                                <span>{ receipt.deliveryDate.length >= 10 ? format(new Date(receipt.deliveryDate), 'dd/MM/yyyy') : '' }</span>
                                <span>{ receipt.value }</span>
                                <span>{ receipt.receiptType.value }</span>
                            </li>
                        )) } 
                    </ul>
                </>
            ) }

            { fields.map((field, index) => (
                <>
                    <ReceiptFormModal
                        open={openReceiptFormModal}
                        numberOfReceipts={index}
                        onCancel={() => {
                            setOpenReceiptFormModal(false);
                            remove(index);
                        }}
                        onConfirm={() =>{
                            setOpenReceiptFormModal(false);
                        }}
                    />
                </>
            )) }
                           
            <button  type={'button'} onClick={handleOpenReceiptFormModal}>{ '+ Receber' }</button>
        </div>
    );
}

export default ReceiptsList;