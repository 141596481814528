import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import { useState } from 'react';
import Pills from '../../components/Pills';

function RepresentativesFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {
    const [ suppliers, setSuppliers ] = useState(filter?.suppliers ?? []);

    function confirm() {
        onConfirm({
            suppliers: suppliers,
        });
    }

    function cancel() {
        onCancel();
        setSuppliers(filter.suppliers);
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            // I18N
            title={'Filtrar representantes'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    { /*TODO*/ }
                    { /*<FieldSegmentations*/ }
                    { /*    multipleSelection={true}*/ }
                    { /*    onAddItem={(record) => {*/ }
                    { /*        setSuppliers((suppliers) => {*/ }
                    { /*            return [*/ }
                    { /*                ...suppliers,*/ }
                    { /*                record*/ }
                    { /*            ];*/ }
                    { /*        });*/ }
                    { /*    }}*/ }
                    { /*    ignoreItems={suppliers.map(record => record.id)}*/ }
                    { /*>*/ }
                    { /*    <Pills*/ }
                    { /*        pills={suppliers}*/ }
                    { /*        onRemoveItem={(record) => {*/ }
                    { /*            setSuppliers((suppliers) => {*/ }
                    { /*                return suppliers.filter(item => item.id !== record.id);*/ }
                    { /*            });*/ }
                    { /*        }}*/ }
                    { /*    />*/ }
                    { /*</FieldSegmentations>*/ }
                </div>
            </div>

        </SimpleConfirmModal>
    );
}

export default RepresentativesFilterModal;