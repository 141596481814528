import { useContext, useEffect, useState } from 'react';
import ScreenHeader from '../../../components/logged/ScreenHeader';
import { getDashboardRoute } from '../../../dashboard/Dashboard';
import { useNavigate, useParams } from 'react-router';
import { getStockCheckRoute } from './StockCheck';
import Button, { ButtonColor, ButtonStyle } from '../../../components/Button';
import { IconAdd, IconAdd2x, IconFilter, IconFilter2x } from '../../../components/images';
import * as Tabs from '@radix-ui/react-tabs';
import Pagination from '../../../components/Pagination';
import { OrderByProductStockCheckEnum, ProductStockCheckFilterTabEnum, ProductStockCheckFilterTabText, StockCheckFilterTabEnum } from 'erva-doce-common';
import StockCheckDetailTable from './StockCheckDetailTable';
import StockCheckDetailFilterModal from './StockCheckDetailFilterModal';
import { getStockCheckFormRoute } from './StockCheckForm';
import * as StockCheckService from '../../../services/StockCheckService';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';

const FILTERS = {
    orderProduct: OrderByProductStockCheckEnum.ID_ASC,
    page: 0,
    status: ProductStockCheckFilterTabEnum.ALL,
};

export default function StockCheckDetail() {
    const title = 'Conferência detalhada';
    const { id } = useParams();
    const navigate = useNavigate();
    const { backendConnectionError, setConfirmModal, setInfoModal } = useContext(EnvironmentContext);

    const [filter, setFilter] = useState(FILTERS);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [stockCheck, setStockCheck] = useState(null);

    const handleFilterTabChange = (e) => {
        setFilter({
            ...filter,
            status: e,
        });
    };

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const getItems = async () => {
        try {
            setIsLoading(true);

            const response = await StockCheckService.getItems(id, filter);

            setData(response);
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch info', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const getInfo = async () => {
        try {
            setIsLoading(true);

            const response = await StockCheckService.getById(id);

            setStockCheck(response);
        } catch(err) {
            console.log(err);
            backendConnectionError('Fail to fetch info', err, null, title);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteStockCheck = async () => {
        const proceed = async () => {
            try {
                setIsLoading(true);

                await StockCheckService.deleteById(id);

                return setInfoModal({
                    title,
                    message: 'Conferência excluída com sucesso',
                    show: true,
                    onClose: () => navigate(getStockCheckRoute()),
                });
            } catch(err) {
                console.log(err);
                backendConnectionError('Fail to delete stockCheck', err, null, title);
            } finally {
                setIsLoading(false);
            }
        };

        setConfirmModal({
            title,
            message: 'Você tem certeza de que deseja excluir a conferência?',
            onConfirm: proceed,
            show: true,
        });
    };

    const finishStockCheck = async () => {
        const proceed = async () => {
            try {
                setIsLoading(true);

                await StockCheckService.update(id, { status: StockCheckFilterTabEnum.FINISHED });

                return setInfoModal({
                    title,
                    message: 'Conferência finalizada com sucesso',
                    show: true,
                });
            } catch(err) {
                console.log(err);
                backendConnectionError('Fail to finish stockCheck', err, null, title);
            } finally {
                setIsLoading(false);
            }
        };

        setConfirmModal({
            title,
            message: 'Você tem certeza de que deseja finalizar a conferência?',
            onConfirm: proceed,
            show: true,
        });
    };

    useEffect(() => {
        getItems();
    }, [filter]);

    useEffect(() => {
        getInfo();
    }, []);

    return (
        <>
            <div className={'stock crud-list'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Produtos', route: getDashboardRoute() },
                        { name: 'Estoque' },
                        { name: 'Minhas conferências', route: getStockCheckRoute() },
                        { name: title, route: getStockCheckDetailRoute(id) },
                    ]}
                    backRoute={getStockCheckRoute()}
                />
                <div className={'row justify-content-end mb-32'}>
                    <div className={'col-6 col-md-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_GREEN}
                            icon={IconAdd}
                            icon2x={IconAdd2x}
                            onClick={() => navigate(getStockCheckFormRoute(id))}
                            className={'w-100'}
                        >
                            {'Conferir novo item'}
                        </Button>
                    </div>
                    <div className={'col-6 col-md-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                            icon={IconFilter}
                            icon2x={IconFilter2x}
                            onClick={() => {
                                setShowFilterModal(true);
                            }}
                            className={'w-100'}
                        >
                            {'Filtrar'}
                        </Button>
                    </div>
                </div>
                <div className={'Order_suggestion_list'}>
                    <Tabs.Root
                        defaultValue={filter.status}
                        className={'tabs_container'}
                        onValueChange={handleFilterTabChange}
                    >
                        <Tabs.List
                            className={'tabs_list'}
                            aria-label={'Manage your account'}
                        >
                            {Object.values(ProductStockCheckFilterTabEnum).map(
                                (status, index) => {
                                    return (
                                        <Tabs.Trigger
                                            key={index}
                                            className={'tabs_trigger'}
                                            value={status}
                                        >
                                            {`${ProductStockCheckFilterTabText(status)} (${status === ProductStockCheckFilterTabEnum.ALL ? data?.total : data?.totals?.[status]?.count || 0})`}
                                        </Tabs.Trigger>
                                    );
                                })}
                        </Tabs.List>
                    </Tabs.Root>
                    <StockCheckDetailTable
                        stockCheckId={id}
                        data={data?.records}
                        filter={filter}
                        onSortChange={updateFilter}
                        isLoading={isLoading}
                    />
                    <Pagination
                        page={data?.page}
                        pageSize={data?.pageSize}
                        count={data?.count}
                        recordCount={data?.records?.length || 0}
                        onPageChange={(page) => setFilter({ ...filter, page })}
                    />
                </div>
                <div className={'gd align-right update-purchase'}>
                    {
                        stockCheck?.canExclude && (
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                className={'ml-10'}
                                color={ButtonColor.BUTTON_COLOR_GRAY}
                                onClick={deleteStockCheck}
                            >
                                {'Excluir conferência'}
                            </Button>
                        )
                    }
                    {
                        stockCheck?.canFinish && (
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_NORMAL}
                                className={'ml-10'}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                onClick={finishStockCheck}
                            >
                                {'Finalizar conferência'}
                            </Button>
                        )
                    }
                </div>
            </div>
            <StockCheckDetailFilterModal
                show={showFilterModal}
                filter={filter}
                onCancel={() => setShowFilterModal(false)}
                onConfirm={(filterData) => {
                    setShowFilterModal(false);
                    setFilter(filterData);
                }}
            />
        </>
    );
}

export function getStockCheckDetailRoute(id) {
    if (id) {
        return `/produtos/estoque/conferencias/${id}`;
    } else {
        return getStockCheckRoute();
    }
}
