export const PaymentsEnum = {
    DINNER: "DINNER",
    BANK_CHECK: "BANK_CHECK",
    CREDIT_CARD: "CREDIT_CARD",
    DEBIT_CARD: "DEBIT_CARD",
    STORE_CREDIT: "STORE_CREDIT",
    FOOD_VOUCHER: "FOOD_VOUCHER",
    MEAL_VOUCHER: "MEAL_VOUCHER", 
    GIFT_VOUCHER: "GIFT_VOUCHER",
    FUEL_VOUCHER: "FUEL_VOUCHER",
    BANK_SLIP: "BANK_SLIP",
    BANK_DEPOSIT: "BANK_DEPOSIT",
    PIX: "PIX",
    BANK_TRANSFER: "BANK_TRANSFER",
    DIGITAL_WALLET: "DIGITAL_WALLET",
    LOYALTY_PROGRAM: "LOYALTY_PROGRAM",
    CASHBACK: "CASHBACK",
    VIRTUAL_CREDIT: "VIRTUAL_CREDIT",
    NO_PAYMENT: "NO_PAYMENT",
    OTHERS: "OTHERS"
} as const;

export function PaymentsTypeText(key: string) : string {
    const paymentType = PaymentsEnum[key];
    switch (paymentType) {
        case "DINNER":
            return "Dinheiro";
        case "BANK_CHECK":
            return "Cheque";
        case "CREDIT_CARD":
            return "Cartão de Crédito";
        case "DEBIT_CARD":
            return "Cartão de Débito";
        case "STORE_CREDIT":
            return "Crédito Loja";
        case "FOOD_VOUCHER":
            return "Vale Alimentação";
        case "MEAL_VOUCHER":
            return "Vale Refeição";
        case "FUEL_VOUCHER":
            return "Vale Combustível";
        case "GIFT_VOUCHER":
            return "Vale Presente";
        case "BANK_SLIP":
            return "Boleto Bancário";
        case "BANK_DEPOSIT":
            return "Depósito Bancário";
        case "PIX":
            return "Pix";
        case "BANK_TRANSFER":
            return "Transferência Bancária";
        case "DIGITAL_WALLET":
            return "Carteira Digital";
        case "LOYALTY_PROGRAM":
            return "Programa Fidelidade";
        case "CASHBACK":
            return "Cashback";
        case "VIRTUAL_CREDIT":
            return "Crédito Virtual";
        case "NO_PAYMENT":
            return "Sem pagamento";
        case "OTHERS":
            return "Outros";
    }
}


export function PaymentsTypeValue(key: string) {
    const paymentType = PaymentsEnum[key];
    switch (paymentType) {
        case "DINNER":
            return 1;
        case "BANK_CHECK":
            return 2;
        case "CREDIT_CARD":
            return 3;
        case "DEBIT_CARD":
            return 4;
        case "STORE_CREDIT":
            return 5;
        case "FOOD_VOUCHER":
            return 10;
        case "MEAL_VOUCHER":
            return 11;
        case "GIFT_VOUCHER":
            return 12;
        case "FUEL_VOUCHER":
            return 13;
        case "BANK_SLIP":
            return 15;
        case "BANK_DEPOSIT":
            return 16;
        case "PIX":
            return 17;
        case "BANK_TRANSFER":
            return 18;
        case "DIGITAL_WALLET":
            return 18;
        case "LOYALTY_PROGRAM":
            return 19;
        case "CASHBACK":
            return 19;
        case "VIRTUAL_CREDIT":
            return 19;
        case "NO_PAYMENT":
            return 90;
        case "OTHERS":
            return 99;
    }
}

