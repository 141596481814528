import api from '../util/api';

export async function getAll(filter) {
    if (!filter) return [];

    return (await api.get('/customers', {
        params: filter,
    })).data;
}

