import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import ScreenHeader from '../../components/logged/ScreenHeader';
import PaymentList from '../../components/PaymentList';
import Button, { ButtonColor } from '../../components/Button';
import { useEffect, useState } from 'react';
import { extractCurrencyNumber } from '../../components/FieldCurrency';

function SalesPaymentModal({
    show,
    onCancel,
    onConfirm,
    formData,
    setFormData,
    amount,
    exchangeTotal,
    emptyPayments,
    updateFormData,
    addRowPayment,
    disabled,
    ...props
}) {
    const [purchaseTotal, setPurchaseTotal] = useState(0);
    const [balanceToPay, setBalanceToPay] = useState(0);
    const [edit, setEdit] = useState(false);
    useEffect(() => {
        if (formData) {
            // noinspection JSIgnoredPromiseFromCall
            fetchTotals();
        }
    }, [exchangeTotal]);

    useEffect(() => {
        if (!show) {
            setEdit(false);
        }
    }, [show]);

    useEffect(() => {
        if (formData) {
            // noinspection JSIgnoredPromiseFromCall
            fetchTotals();
        }
    }, [formData.payments]);

    function confirm() {
        const updatedPayments = formData.payments.map(payment => {
            if (!edit) {
                payment.total = balanceToPay?.toLocaleString(undefined, { style: 'currency', currency: 'BRL' });
                payment.amountInstallments = payment.total;
            }
            return payment;
        });

        updateFormData({ payments: updatedPayments });
        onConfirm();
    }

    function cancel() {
        const payments = formData.payments.filter((payment) => !payment.newRow);
        updateFormData({ payments: [...payments] });
        setEdit(false);
        onCancel();
    }

    async function fetchTotals() {
        let total = 0;
        let totalini = balanceToPay?.toLocaleString(undefined, { style: 'currency', currency: 'BRL' });

        for (const payment of formData.payments) {
            const value = extractCurrencyNumber(payment.total);
            total += value;
        }
        setPurchaseTotal(!edit ? totalini : total);
        setBalanceToPay(amount - exchangeTotal - total);

    }

    // I18N
    const totals = [{
        name: 'Total da compra',
        value: amount,
    }, {
        name: 'Valor total',
        value: purchaseTotal,
    }, {
        name: 'Valor da troca',
        value: exchangeTotal,
    }, {
        name: 'Saldo a pagar',
        value: !edit ?  0 : balanceToPay,
        className: 'balance-to-pay',
    }];

    return (
        <div className={'sales-payment-modal'}>
            <SimpleConfirmModal
                // I18N
                show={show}
                onCancel={cancel}
                onConfirm={confirm}
                confirmText={'Concluir'}
                disabled={disabled}
            >
                <div className={'gd'}>
                    <div className={'gd-col-12'}>
                        <ScreenHeader
                            // I18N
                            title={'Recebimento'}
                            hideStore={true}
                            breadcrumbs={[
                                // I18N
                                { name: 'Venda', route: '' },
                                { name: 'Nova Venda', route: '' },
                                { name: 'Recebimento', route: '' },
                            ]}
                        />
                    </div>
                </div>
                <div className={'gd'}>
                    <div className={'gd-col-12'}>
                        <PaymentList
                            setPurchaseTotal={setPurchaseTotal}
                            balanceToPay={balanceToPay}
                            formData={formData}
                            edit={edit}
                            setEdit={setEdit}
                            updateFormData={updateFormData}
                            setFormData={setFormData}
                            emptyPayments={emptyPayments}
                        />
                    </div>
                </div>
                <div className={'gd container-button-new-field'}>
                    <div className={'gd-col-4'}>
                        <Button
                            // I18N
                            color={ButtonColor.BUTTON_COLOR_WHITE}
                            className={'button-new-field'}
                            onClick={addRowPayment}>
                            {'+ (F2) Adicionar novo campo'}
                        </Button>
                    </div>
                </div>

                <div className={'gd totals-gd'}>
                    <div className={'gd-col-12 totals-container'}>
                        {
                            totals.map(({ name, value, className }, i) => {
                                return (
                                    <div key={i}
                                        className={`total-payment-list ${className === 'balance-to-pay' ? (value >= 0 ? 'green' : 'red') : ''}`}>
                                        <div className={'gd gd-col--between col-styled'}>
                                            <div className={'gd-col-3'}>
                                                <span>
                                                    {name}
                                                </span>
                                            </div>
                                            <div className={'gd-col-9 justify-content-end'}>
                                                <span className={'value'}>
                                                    {value?.toLocaleString(undefined, {
                                                        style: 'currency',
                                                        currency: 'BRL'
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </SimpleConfirmModal>
        </div>
    );
}

export default SalesPaymentModal;
