import ConfirmModal from './modal/ConfirmModal';
import {
    unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import { useEffect } from 'react';

function RouteChangePrompt({
    enabled,
    title,
    message,
    onConfirm
}) {
    const { state, proceed, reset } = useBlocker(enabled);

    // I18N
    const defaultMessage = 'Há modificações não salvas no formulário. Tem certeza que deseja sair da página?';

    useEffect(() => {
        window.onbeforeunload = () => {
            if (enabled) {
                // I18N
                return defaultMessage;
            }
        };
        return () => {
            window.onbeforeunload = undefined;
        };
    }, [enabled]);
    const handleCancel = () => {
        if (state === 'blocked') {
            proceed();
        } else {
            reset();
        }
    };

    return (
        <ConfirmModal
            show={state === 'blocked'}
            // I18N
            title={title ?? 'Sair da página'}
            message={message ?? defaultMessage}
            onConfirm={onConfirm || proceed}
            onCancel={handleCancel}
        />
    );
}

export default RouteChangePrompt;
