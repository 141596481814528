import {
    ValidationErrorProductsText,
    ProductTagTypeEnum,
    ProductTagTypeText,
    TagEnum,
    TagText
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldCfop from '../../components/FieldCfop';
import FieldCheckbox from '../../components/FieldCheckbox';
import FieldCurrency, { extractCurrencyNumber, formatCurrency } from '../../components/FieldCurrency';
import FieldIcms from '../../components/FieldIcms';
import FieldInteger from '../../components/FieldInteger';
import FieldLiveSearchSelectionTable from '../../components/FieldLiveSearchSelectionTable';
import FieldPercentage, { extractPercentageNumber } from '../../components/FieldPercentage';
import FieldProductBrand from '../../components/FieldProductBrand';
import FieldProductCategory from '../../components/FieldProductCategory';
import FieldProductFamily from '../../components/FieldProductFamily';
import FieldProductSubCategory from '../../components/FieldProductSubCategory';
import FieldSegmentation from '../../components/FieldSegmentation';
import FieldSimilarProduct from '../../components/FieldSimilarProduct';
import FieldStore from '../../components/FieldStore';
import FieldTaxClassification from '../../components/FieldTaxClassification';
import FieldTaxSituation from '../../components/FieldTaxSituation';
import FieldText from '../../components/FieldText';
import { Loading, LoadingSize } from '../../components/Loading';
import Pills from '../../components/Pills';
import ProductTable from '../../components/ProductTable';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import Table from '../../components/Table';
import {
    IconLog,
    Picture as PictureIcon,
    Picture2x,
    IconEdit,
    IconEdit2x,
    IconEditSvg,
    IconButtonRemove,
    IconButtonRemove2x,
    IconButtonRemoveSvg,
    IconTagNatural,
    IconTagCrueltyFree,
    IconTagOrganico,
    IconTagVegano,
} from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as ProductsService from '../../services/ProductsService';
import { getStoresFormRoute } from '../stores/StoreForm';
import ProductInventoryMovement from './ProductInventoryMovement';
import { getProductsRoute } from './Products';
import { getProductLogsRoute } from './ProductLogs';
import MultipleImages from '../../components/modal/MultipleImages';
import BarCodesModal from '../../components/BarCodesModal';
import FieldSelect from '../../components/FieldSelect';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import FieldTextarea from '../../components/FieldTextarea';
import Section from '../../components/Section';
import FieldNumber from '../../components/FieldNumber';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import './ProductsForm.scss';
import { handleValidateEAN } from '../../util/validateEAN';
import ConfirmDeleteBarCodesModal from '../../components/ConfirmDeleteBarCodesModal';
import FieldCollaborator from '../../components/FieldCollaborator';
import { formatValue } from '../../util/formatValue';
import SearchProductModal from '../../components/SearchProductModal';
import FieldNcm from '../../components/FieldNcm';

const ICONS_TAG = {
    [TagEnum.CRUELTY_FREE]: IconTagCrueltyFree,
    [TagEnum.NATURAL]: IconTagNatural,
    [TagEnum.ORGANICO]: IconTagOrganico,
    [TagEnum.VEGANO]: IconTagVegano,
};

export default function ProductForm() {
    const emptyFormData = {
        barCodes: '',
        name1: '',
        shortName1: '',
        name2: '',
        shortName2: '',
        name3: '',
        shortName3: '',
        description: '',
        shortDescription: '',
        brand: '',
        segmentation: '',
        category: '',
        family: '',
        icms: '',
        ncm: '',
        taxClassification: '',
        taxSituation: '',
        cfop: '',
        subCategory: '',
        stores: [],
        packageType: '',
        packageQuantity: 1,
        pictures: [],
        cost: '',
        tag: ProductTagTypeEnum.DEFAULT_TAG,
        tagQuantity: 1,
        weight: '',
        newPrice: '',
        isBestOfEdos: false,
        notPurchaseSuggestion: false,
        tags: [],
        variations: [],
    };
    const emptyFormError = {
        ...emptyFormData,
        packageQuantity: '',
        tagQuantity: ''
    };

    const location = useLocation();
    const { uuid } = useParams();
    const navigate = useNavigate();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        setWindowTitle,
        addHotkey,
        removeHotkey,
    } = useContext(EnvironmentContext);
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();

    const queryParams = new URLSearchParams(location.search);
    const uuidCopy = queryParams.get('uuid');
    const createParentProduct = queryParams.get('parentProduct') === 'true';

    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [loadingProduct, setLoadingProduct] = useState(!!uuid);
    const [productName, setProductName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const [categoryId, setCategoryId] = useState({ id: null });
    const [productUUID, setProductUUID] = useState('');
    const [barCodes, setBarCodes] = useState([]);
    const [similarProducts, setSimilarProducts] = useState([]);
    const [showImagesModal, setShowImagesModal] = useState(false);
    const [barCodesModal, setBarCodesModal] = useState(false);
    const [confirmDeleteBarCodesModal, setConfirmDeleteBarCodesModal] =
        useState(false);
    const [showConfirmCriarUnidadeModal, setShowConfirmCriarUnidadeModal] =
        useState(false);
    const [initialSuggestion, setInitialSuggestion] = useState('40,00');
    const [finalSuggestion, setFinalSuggestion] = useState('60,00');
    const [descriptionModal, setDescriptionModal] = useState({
        show: false,
        title: '',
        value: ''
    });
    const [idBarCode, setIdBarCode] = useState();
    const [idBarCodeToDelete, setIdBarCodeToDelete] = useState();
    const [barCode, setBarCode] = useState();
    const [showProductModal, setShowProductModal] = useState(false);

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchProduct(uuid) {
        try {
            setLoadingProduct(true);
            const product = await ProductsService.getProduct(uuid);

            if (!product) {
                return setInfoModal({
                    title,
                    // I18N
                    message: 'Produto não encontrado.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            }

            setProductName(product.name1);
            setProductUUID(product.uuid);
            setBarCodes(uuidCopy ? [] : product?.barCodes || []);
            setSimilarProducts(product.similarProducts?.map(item => {
                return {
                    id: item.id,
                    value: item.shortDescription
                };
            }) || []);
            setFormData({
                ...product,
                id: uuidCopy ? null : product.id,
                productCode: uuidCopy ? null : product.productCode,
                parentProduct: createParentProduct ? uuidCopy : product.parentProduct,
                budget: product?.budget?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                }) ?? '',
                newPrice: product?.newPrice?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                }) ?? '',
                pictures: uuidCopy ? [] : product?.pictures,
                brand: product.brand ? { id: product.brand, value: null } : null,
                segmentation: product.segmentation ? { id: product.segmentation, value: null } : null,
                category: product.category ? { id: product.category, value: null } : null,
                subCategory: product.subCategory ? { id: product.subCategory, value: null } : null,
                family: product.family ? { id: product.family, value: null } : null,
                icms: product.icms ? { id: product.icms, value: null } : null,
                ncm: product.ncm ? { id: product.ncm, value: null } : null,
                taxClassification: product.taxClassification ? { id: product.taxClassification, value: null } : null,
                taxSituation: product.taxSituation ? { id: product.taxSituation, value: null } : null,
                cfop: product.cfop ? { id: product.cfop, value: null } : null,
                stores: product.stores || [],
                packageQuantity: createParentProduct ? '' : uuidCopy ? 1 : product.packageQuantity,
                stock: product.stock || 0,
                isBestOfEdos: product.isBestOfEdos,
                cost: product?.cost?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                }) ?? '',
                tag: product?.tag ?? ProductTagTypeEnum.DEFAULT_TAG,
                tagQuantity: product?.tagQuantity ?? '',
                weight: product?.weight ? String(product.weight) : '',
                notPurchaseSuggestion: product?.notPurchaseSuggestion || false,
                variation: createParentProduct ? '' : product?.variation,
                tags: product?.tags || [],
                variations: product?.variations || [],
            });

        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    // I18N
                    message: 'Produto não encontrado.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch product', e, null, title);
            }
        } finally {
            setLoadingProduct(false);
        }
    }

    useEffect(() => {
        if (uuid || uuidCopy) {
            // noinspection JSIgnoredPromiseFromCall
            fetchProduct(uuid || uuidCopy);
        }
    }, [uuid, uuidCopy]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData, barCodes]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!barCodes.length || !barCodes.find(bc => bc.main)) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, barCodes: 'Obrigatório ter um código principal' }));
        }

        if (!formData.tagQuantity) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, tagQuantity: 'Campo obrigatório' }));
        }

        if (!formData.name1?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, name1: 'Digite um nome para o produto' }));
        }

        if (!formData.variation?.trim()) {
            hasError = true;
            setFormError((formError) => ({ ...formError, variation: 'Digite o nome da variação do produto' }));
        }

        if (!formData.parentProduct && formData.variations.some(x => !x.variation)) {
            hasError = true;
            setFormError((formError) => ({ ...formError, variations: 'Preencha todos os valores' }));
        }

        if (!formData.shortName1?.trim()) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, shortName1: 'Digite um nome web para o produto' }));
        }

        if (!formData.brand) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, brand: 'Selecione uma marca' }));
        }

        if (!formData.category) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, category: 'Selecione uma categoria' }));
        }

        if (!formData.icms) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, icms: 'Campo obrigatório' }));
        }

        if (!formData.ncm) {
            hasError = true;
            setFormError((formError) => ({ ...formError, ncm: 'Campo obrigatório' }));
        }

        if (!formData.taxClassification) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, taxClassification: 'Campo obrigatório' }));
        }

        if (!formData.taxSituation) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, taxSituation: 'Campo obrigatório' }));
        }

        if (!formData.cfop) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, cfop: 'Campo obrigatório' }));
        }

        if (!formData.packageQuantity || formData.packageQuantity == 0) {
            hasError = true;
            // I18N
            setFormError((formError) => ({ ...formError, packageQuantity: 'Quantidade deve ser no mínimo 1' }));
        }

        return hasError;
    }

    useEffect(() => {
        let f3Hotkey;
        let f6Hotkey;
        if (uuid) {
            f3Hotkey = addHotkey('F3', () => {
                buttonDeleteRef.current?.click();
            });
        }
        f6Hotkey = addHotkey('F6', () => {
            buttonSaveRef.current?.click();
        });
        return () => {
            removeHotkey(f3Hotkey);
            removeHotkey(f6Hotkey);
        };
    }, []);

    useEffect(() => {
        setWindowTitle(getTitle(true));
        return () => {
            setWindowTitle();
        };
    }, [productName]);

    function back() {
        navigate(getProductsRoute());
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);
            const cloneBarCodes = cloneDeep(barCodes);

            const body = {
                ...formData,
                barCodes: cloneBarCodes.map((item) => {
                    if (!item?.product) delete item.id;
                    return item;
                }),
                budget: extractCurrencyNumber(formData.budget ?? ''),
                newPrice: extractCurrencyNumber(formData.newPrice ?? ''),
                brand: formData.brand?.id ?? null,
                segmentation: formData.segmentation?.id ?? null,
                category: formData.category?.id ?? null,
                subCategory: formData.subCategory?.id ?? null,
                family: formData.family?.id ?? null,
                icms: formData.icms?.id ?? null,
                ncm: formData.ncm?.id ?? null,
                taxClassification: formData.taxClassification?.id ?? null,
                taxSituation: formData.taxSituation?.id ?? null,
                cfop: formData.cfop?.id ?? null,
                isBestOfEdos: formData.isBestOfEdos,
                similarProducts: similarProducts.map((item) => {
                    return { id: item.id };
                }),
                weight: formData?.weight
                    ? formData.weight.replace(/[.g]/g, '')
                    : null,
                packageQuantity: !(
                    createParentProduct ||
                    (formData?.parentProduct && !uuidCopy)
                ) ? 1 : formData.packageQuantity,
                tags: formData.tags || [],
                variations: formData.variations || [],
            };

            if(createParentProduct && uuidCopy) body.parentProduct = uuidCopy;

            let message;
            if (!uuid) { // new product
                await ProductsService.addProduct(body);
                // I18N
                message = 'Produto salvo com sucesso!';
            } else {
                await ProductsService.editProduct(uuid, body);
                // I18N
                message = 'Produto editado com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to create/edit product', e, null, title, ValidationErrorProductsText);
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteProduct() {
        // I18N
        let title = 'Excluir produto';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await ProductsService.deleteProduct(uuid);
                // I18N
                const message = 'Produto excluído com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete product', e, null, title, ValidationErrorProductsText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            // I18N
            message: 'Você tem certeza que deseja excluir o produto?',
            onConfirm: proceed,
            show: true,
        });
    }

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            // I18N
            let title = 'Editar produto';
            if (windowTitle && productName) title += ` - ${productName}`;
            return title;
        } else {
            return 'Novo produto';
        }
    }

    const handleTags = (e) => {
        const value = e.target.value;
        const tags = [...formData.tags].filter(x => x !== value);
        const checked = e.target.checked;

        if (checked) {
            tags.push(value);
        }

        updateFormData({ tags });
    };

    function renderTable() {
        return (
            <ProductTable>
                <tr>
                    <td
                        style={{
                            borderRadius: '0 0 0 8px',
                            paddingLeft: '1%',
                            textAlign: 'left',
                        }}
                    >
                        {formData?.id || '#'}
                    </td>
                    <td>
                        {
                            [formData.name1, formData.name2, formData.name3].filter(Boolean).join(' ')
                        }
                    </td>
                    <td>
                        <div
                            style={{
                                color: '#fff',
                                backgroundColor: formData?.parentProduct
                                    ? '#B741B3'
                                    : '#1F92E2',
                                textAlign: 'center',
                                borderRadius: '4px',
                                padding: '4px',
                            }}
                        >
                            {formData?.parentProduct ? 'Variação' : 'Principal'}
                        </div>
                    </td>
                    <td>{formData.minValue1 || 0}</td>
                    <td>{formData.minValue2 || 0}</td>
                    <td>{formData.cdStock || 0}</td>
                    <td>{formData.stock || 0}</td>
                    <td>{formatValue(formData.avgCost || 0)}</td>
                    <td>{'12,90'}</td>
                    <td>{formData?.newPrice || '0,00'}</td>
                    <td>{'60%'}</td>
                    <td>
                        <FieldCheckbox
                            fieldGroup={false}
                            inputs={[
                                {
                                    value: formData.isBestOfEdos,
                                    onChange: () => {
                                        updateFormData({
                                            isBestOfEdos:
                                                !formData.isBestOfEdos,
                                        });
                                    },
                                    checked: formData.isBestOfEdos,
                                },
                            ]}
                        />
                    </td>
                    {uuid ? (
                        <td
                            style={{ borderRadius: '0 0 8px 0' }}
                            onClick={() => navigate(getProductLogsRoute(uuid))}
                        >
                            {
                                <Button className={'transparent button-sort'}>
                                    <img src={IconLog}></img>
                                </Button>
                            }
                        </td>
                    ) : (
                        <td></td>
                    )}
                </tr>
            </ProductTable>
        );
    }

    const handleCalcSuggestedPrice = (profitMargin) => {
        if(!profitMargin || !formData?.cost) return 'R$ 0,00';

        const cost = extractCurrencyNumber(formData.cost);
        const formattedMargin = extractPercentageNumber(profitMargin);
        const suggestionPrice = cost + (cost * (formattedMargin / 100));
        return suggestionPrice.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    };

    const handleProductTagsOptions = () => {
        const tagOptions = [];
        for(const tag in ProductTagTypeEnum){
            tagOptions.push({
                id: tag,
                value: ProductTagTypeText(tag)
            });
        }

        return tagOptions;
    };

    const handleEditDescriptions = (field, value) => {
        if(field){
            field === 'Descrição web 1' ?
                updateFormData({ description: value }) :
                updateFormData({ shortDescription: value });
        }
    };

    const updateVariationItem = (uuid, value) => {
        const newVariations = [...formData.variations].map(x => {
            if (x.uuid === uuid) {
                return {
                    ...x,
                    ...value,
                };
            }

            return x;
        });

        updateFormData({ variations: newVariations });
    };

    useEffect(() => {
        if (idBarCodeToDelete) {
            setConfirmDeleteBarCodesModal(true);
        }
    }, [idBarCodeToDelete]);

    const renderForm = () => {
        if (loadingProduct) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <fieldset className={'product-grid'}>
                    <div className={'image-container'}>
                        <div className={'image-header'}>
                            <label>{'Imagens'}</label>
                            <Button
                                onClick={() => setShowImagesModal(true)}
                                className={'transparent'}
                                style={{ textDecoration: 'underline' }}
                            >
                                {'Editar'}
                            </Button>
                        </div>
                        <div className={'image-content'}>
                            {formData?.pictures?.length ? (
                                <img src={formData.pictures[0].imageURL} />
                            ) : (
                                <img
                                    src={PictureIcon}
                                    srcSet={`${PictureIcon} 1x, ${Picture2x} 2x`}
                                />
                            )}
                        </div>
                        <MultipleImages
                            showModal={showImagesModal}
                            onCloseModal={setShowImagesModal}
                            pictures={formData?.pictures || []}
                            editPicture={true}
                            productName={<p>{[formData.name1, formData.name2, formData.name3].filter(Boolean).join(' ')}</p>}
                            onChange={(image) => {
                                updateFormData({
                                    pictures: [...formData.pictures, image],
                                });
                            }}
                            onRemoveImage={(image) => {
                                const filteredImages = formData.pictures.filter(
                                    (img) =>
                                        image?.uuid
                                            ? img.uuid !== image.uuid
                                            : img.id !== image.id
                                );
                                updateFormData({ pictures: filteredImages });
                            }}
                        />
                    </div>
                    <div
                        className={`barcodes-container ${
                            formError.barCodes && 'container-form-error'
                        }`}
                    >
                        <div className={'barcodes-header'}>
                            <label>{'Código de barras'}</label>
                            <Button
                                onClick={() => setBarCodesModal(true)}
                                className={'transparent'}
                                style={{ textDecoration: 'underline' }}
                            >
                                {'+ novo'}
                            </Button>
                            {/* <Button
                                onClick={() => setBarCodesModal(true)}
                                className={'transparent'}
                                style={{ textDecoration: 'underline' }}
                            >
                                {'Editar'}
                            </Button> */}
                        </div>
                        {barCodes?.length ? (
                            <div className={'barcodes-content'}>
                                {barCodes?.map((bc) => {
                                    return (
                                        <div
                                            key={bc.id}
                                            className={
                                                'd-flex justify-content-between'
                                            }
                                        >
                                            <p
                                                style={{
                                                    fontWeight: bc?.main
                                                        ? 'bold'
                                                        : 'normal',
                                                    lineBreak: 'anywhere',
                                                }}
                                            >
                                                {bc.barCode}
                                                {bc?.main ? ' (Principal)' : ''}
                                            </p>
                                            <div
                                                className={
                                                    'd-flex align-items-start'
                                                }
                                            >
                                                <Button
                                                    className={
                                                        'transparent mr-16'
                                                    }
                                                    onClick={() => {
                                                        setIdBarCode(bc.id);
                                                        setBarCode(bc.barCode);
                                                        setBarCodesModal(true);
                                                    }}
                                                >
                                                    <img
                                                        src={IconEditSvg}
                                                        srcSet={`${IconEdit} 1x, ${IconEdit2x} 2x`}
                                                        alt={'Editar'}
                                                        title={'Editar'}
                                                        style={{
                                                            width: '16px',
                                                        }}
                                                    />
                                                </Button>
                                                <Button
                                                    className={'transparent'}
                                                    onClick={() =>
                                                        setIdBarCodeToDelete(
                                                            bc.id
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            IconButtonRemoveSvg
                                                        }
                                                        srcSet={`${IconButtonRemove} 1x, ${IconButtonRemove2x} 2x`}
                                                        alt={'Excluir'}
                                                        title={'Excluir'}
                                                        style={{
                                                            width: '11px',
                                                        }}
                                                    />
                                                </Button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <></>
                        )}
                        {formError.barCodes && (
                            <p className={'barcodes-msg-error'}>
                                {formError.barCodes}
                            </p>
                        )}
                        <BarCodesModal
                            showModal={barCodesModal}
                            onCloseModal={setBarCodesModal}
                            barCodes={barCodes}
                            onSetBarCodes={setBarCodes}
                            idBarCode={idBarCode}
                            setIdBarCode={setIdBarCode}
                            barCode={barCode}
                            setBarCode={setBarCode}
                        />
                        <ConfirmDeleteBarCodesModal
                            showModal={confirmDeleteBarCodesModal}
                            onConfirm={() => {
                                const newBarCodes = barCodes.filter(
                                    (code) => code.id !== idBarCodeToDelete
                                );

                                if (newBarCodes?.length === 1) {
                                    newBarCodes[0].main = true;
                                } else {
                                    if (
                                        newBarCodes[0]?.main &&
                                        !handleValidateEAN(
                                            newBarCodes[0]?.barCode
                                        )
                                    ) {
                                        newBarCodes[0].main = false;
                                    }
                                }

                                setBarCodes(newBarCodes);
                                setIdBarCodeToDelete(null);
                                setConfirmDeleteBarCodesModal(false);
                            }}
                            onCancel={() => {
                                setIdBarCodeToDelete(null);
                                setConfirmDeleteBarCodesModal(false);
                            }}
                        />
                    </div>
                    <div className={'product-infos-container'}>
                        <div className={'infos-content'}>
                            <div className={'suggestion-container'}>
                                <p>{'Sugestão inicial / Margem'}</p>
                                <div className={'percentage-container'}>
                                    <span>
                                        {`${handleCalcSuggestedPrice(
                                            initialSuggestion
                                        )}`}
                                    </span>
                                    <FieldPercentage
                                        // I18N
                                        fieldGroup={false}
                                        value={initialSuggestion}
                                        onChange={({ target }) =>
                                            setInitialSuggestion(target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className={'suggestion-container'}>
                                <p>{'Sugestão final / Margem'}</p>
                                <div className={'percentage-container'}>
                                    <span>
                                        {`${handleCalcSuggestedPrice(
                                            finalSuggestion
                                        )}`}
                                    </span>
                                    <FieldPercentage
                                        // I18N
                                        fieldGroup={false}
                                        value={finalSuggestion}
                                        onChange={({ target }) =>
                                            setFinalSuggestion(target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{ width: '132px' }}>
                                <FieldCurrency
                                    // I18N
                                    label={'Novo preço'}
                                    fieldGroup={false}
                                    value={formData?.newPrice}
                                    onChange={(e) =>
                                        updateFormData({
                                            newPrice: formatCurrency(
                                                e.target.value
                                            ),
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <FieldSelect
                                    // I18N
                                    label={'Etiqueta'}
                                    options={handleProductTagsOptions()}
                                    onChange={({ target }) =>
                                        updateFormData({ tag: target.value })
                                    }
                                    value={formData?.tag}
                                    validationError={formError?.tag}
                                />
                            </div>
                            <div style={{ width: '112px' }}>
                                <FieldNumber
                                    // I18N
                                    label={'Qtd. etiquetas'}
                                    minValue={1}
                                    thousandsSeparator={false}
                                    onChange={({ target }) =>
                                        updateFormData({
                                            tagQuantity: target.value,
                                        })
                                    }
                                    value={formData?.tagQuantity}
                                    validationError={formError?.tagQuantity}
                                />
                            </div>
                            <div style={{ width: '112px' }}>
                                <FieldNumber
                                    label={'Peso'}
                                    suffix={'g'}
                                    onChange={({ target }) =>
                                        updateFormData({ weight: target.value })
                                    }
                                    value={formData?.weight}
                                    validationError={formError?.weight}
                                />
                            </div>
                            <div style={{ width: '132px' }}>
                                <FieldCurrency
                                    // I18N
                                    label={'Verba'}
                                    fieldGroup={false}
                                    value={formData?.budget}
                                    onChange={(e) =>
                                        updateFormData({
                                            budget: formatCurrency(
                                                e.target.value
                                            ),
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className={'relevant-infos-container mt-16'}>
                            <span>
                                {formData?.dateLastPurchase
                                    ? `* Última compra, ${format(
                                        new Date(formData.dateLastPurchase),
                                        'dd/MM/yy'
                                    )}`
                                    : ''}
                            </span>
                            <span>
                                {formData?.dateLastSale
                                    ? `* Última venda, ${format(
                                        new Date(formData.dateLastSale),
                                        'dd/MM/yy'
                                    )}`
                                    : ''}
                            </span>
                            <span>
                                {formData?.dateLastPriceUpdate
                                    ? `* Última atualização do preço, ${format(
                                        new Date(
                                            formData.dateLastPriceUpdate
                                        ),
                                        'dd/MM/yy'
                                    )}`
                                    : ''}
                            </span>
                        </div>
                    </div>
                    <div className={'names-container'}>
                        <div className={'gd'}>
                            <div className={'gd-col-4'}>
                                <FieldText
                                    // I18N
                                    label={'Nome do produto 1/3'}
                                    onChange={({ target }) =>
                                        updateFormData({ name1: target.value })
                                    }
                                    value={formData?.name1 || ''}
                                    validationError={formError?.name1}
                                    maxLength={120}
                                />
                            </div>
                            <div className={'gd-col-4'}>
                                <FieldText
                                    // I18N
                                    label={'Nome do produto 2/3'}
                                    onChange={({ target }) =>
                                        updateFormData({ name2: target.value })
                                    }
                                    value={formData?.name2 || ''}
                                    validationError={formError?.name2}
                                    maxLength={120}
                                />
                            </div>
                            <div className={'gd-col-4'}>
                                <FieldText
                                    // I18N
                                    label={'Nome do produto 3/3'}
                                    onChange={({ target }) =>
                                        updateFormData({ name3: target.value })
                                    }
                                    value={formData?.name3 || ''}
                                    validationError={formError?.name3}
                                    maxLength={120}
                                />
                            </div>
                        </div>
                        <div className={'result-name mt-16'}>
                            <span>{'Nome do produto:'}&nbsp;</span>
                            <p>{[formData.name1, formData.name2, formData.name3].filter(Boolean).join(' ')}</p>
                        </div>
                    </div>
                    <div className={'web-names-container'}>
                        <div className={'gd'}>
                            <div className={'gd-col-4'}>
                                <FieldText
                                    // I18N
                                    label={'Nome Web 1/3'}
                                    onChange={({ target }) =>
                                        updateFormData({
                                            shortName1: target.value,
                                        })
                                    }
                                    value={formData?.shortName1 || ''}
                                    validationError={formError?.shortName1}
                                    maxLength={120}
                                />
                            </div>
                            <div className={'gd-col-4'}>
                                <FieldText
                                    // I18N
                                    label={'Nome Web 2/3'}
                                    onChange={({ target }) =>
                                        updateFormData({
                                            shortName2: target.value,
                                        })
                                    }
                                    value={formData?.shortName2 || ''}
                                    validationError={formError?.shortName2}
                                    maxLength={120}
                                />
                            </div>
                            <div className={'gd-col-4'}>
                                <FieldText
                                    // I18N
                                    label={'Nome Web 3/3'}
                                    onChange={({ target }) =>
                                        updateFormData({
                                            shortName3: target.value,
                                        })
                                    }
                                    value={formData?.shortName3 || ''}
                                    validationError={formError?.shortName3}
                                    maxLength={120}
                                />
                            </div>
                        </div>
                        <div className={'result-name mt-16'}>
                            <span>
                                {'Nome Web do produto:'}&nbsp;
                            </span>
                            <p>{[formData.shortName1, formData.shortName2, formData.shortName3].filter(Boolean).join(' ')}</p>
                        </div>
                    </div>
                </fieldset>

                <fieldset className={'fields-container'}>
                    <div className={'field-content'}>
                        <div className={'content-header'}>
                            <label>{'Descrição web 1'}</label>
                            <Button
                                onClick={() =>
                                    setDescriptionModal({
                                        show: true,
                                        title: 'Descrição web 1',
                                        value: formData.description,
                                    })
                                }
                                className={'transparent'}
                                style={{ textDecoration: 'underline' }}
                            >
                                {'Editar'}
                            </Button>
                        </div>
                        <FieldText value={formData.description} readOnly />
                    </div>
                    <div className={'field-content'}>
                        <div className={'content-header'}>
                            <label>{'Descrição web 2'}</label>
                            <Button
                                onClick={() =>
                                    setDescriptionModal({
                                        show: true,
                                        title: 'Descrição web 2',
                                        value: formData.shortDescription,
                                    })
                                }
                                className={'transparent'}
                                style={{ textDecoration: 'underline' }}
                            >
                                {'Editar'}
                            </Button>
                        </div>
                        <FieldText value={formData.shortDescription} readOnly />
                    </div>
                    <div className={'field-content'}>
                        <FieldProductFamily
                            // I18N
                            addButton
                            label={'Família'}
                            onSelected={(family) => updateFormData({ family })}
                            select={formData.family}
                            validationError={formError.family}
                        />
                    </div>
                    <SimpleConfirmModal
                        title={descriptionModal.title}
                        show={descriptionModal.show}
                        cancelText={'Fechar'}
                        onCancel={() => {
                            handleEditDescriptions(
                                descriptionModal.title,
                                descriptionModal.value
                            );
                            setDescriptionModal({
                                show: false,
                                title: '',
                                value: '',
                            });
                        }}
                        className={'description-modal'}
                    >
                        <FieldTextarea
                            value={descriptionModal.value}
                            onChange={({ target }) =>
                                setDescriptionModal({
                                    ...descriptionModal,
                                    value: target.value,
                                })
                            }
                        />
                    </SimpleConfirmModal>
                </fieldset>

                <Section title={'Fornecedores'}>
                    <Table
                        columns={[
                            {
                                name: 'Fornecedor',
                            },
                            {
                                name: 'Representante',
                            },
                            {
                                name: 'Código de Produto',
                                align: 'center',
                            },
                            {
                                name: 'Valor de custo',
                                align: 'center',
                            },
                            {
                                name: 'Embalagem mínima',
                                align: 'center',
                            },
                            {
                                name: 'Observações',
                                align: 'center',
                            },
                            {
                                name: 'Responsável',
                                align: 'center',
                            },
                        ]}
                    >
                        {formData?.representativeSuppliers?.length ? (
                            formData?.representativeSuppliers?.map(
                                (item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.supplierName}</td>
                                            <td>{item.representativeName}</td>
                                            <td className={'text-center'}>
                                                {item.supplierCode || '-'}
                                            </td>
                                            <td className={'text-center'}>
                                                {formatValue(item.cost)}
                                            </td>
                                            <td className={'text-center'}>
                                                {item.minPackageQuantity}
                                            </td>
                                            <td className={'text-center'}>
                                                {item.observations || '-'}
                                            </td>
                                            <td className={'text-center'}>
                                                {item.responsibleName || '-'}
                                            </td>
                                        </tr>
                                    );
                                }
                            )
                        ) : (
                            <></>
                        )}
                    </Table>
                </Section>

                <Section title={'Cotações, marca, segmentação e categorias'}>
                    <div className={'fields-container'}>
                        <div className={'field-content'}>
                            <FieldStore
                                // I18N
                                label={'Ativo (por loja)'}
                                multipleSelection={true}
                                onAddItem={(store) => {
                                    updateFormData({
                                        stores: [
                                            ...formData.stores,
                                            {
                                                uuid: store.id,
                                                fantasyName: store.value,
                                            },
                                        ],
                                    });
                                }}
                                ignoreItems={formData.stores.map(
                                    (store) => store.uuid
                                )}
                            >
                                <FieldLiveSearchSelectionTable
                                    descriptionField={'fantasyName'}
                                    value={formData.stores}
                                    onRemove={(record) => {
                                        updateFormData({
                                            stores: formData.stores.filter(
                                                (item) =>
                                                    item.uuid !== record.uuid
                                            ),
                                        });
                                    }}
                                    onGoto={(record) => {
                                        window.open(
                                            getStoresFormRoute(record.uuid),
                                            '',
                                            ''
                                        );
                                    }}
                                />
                            </FieldStore>
                        </div>
                        <div className={'field-content'}>
                            <FieldProductBrand
                                // I18N
                                label={'Marca'}
                                addButton={true}
                                onSelected={(brand) =>
                                    updateFormData({ brand })
                                }
                                select={formData.brand}
                                validationError={formError.brand}
                            />
                        </div>
                        <div className={'field-content'}>
                            <FieldSegmentation
                                // I18N
                                label={'Segmentação'}
                                addButton={true}
                                onSelected={(segmentation) =>
                                    updateFormData({ segmentation })
                                }
                                select={formData.segmentation}
                                validationError={formError.segmentation}
                            />
                        </div>
                        <div className={'field-content'}>
                            <FieldProductCategory
                                // I18N
                                label={'Categoria'}
                                addButton={true}
                                onSelected={(category) => {
                                    setCategoryId(category);
                                    updateFormData({ category });
                                }}
                                select={formData.category}
                                validationError={formError.category}
                            />
                        </div>
                        <div className={'field-content'}>
                            <FieldProductSubCategory
                                // I18N
                                label={'Sub-categoria'}
                                addButton={true}
                                validationCategory={
                                    categoryId.id
                                        ? categoryId
                                        : formData.category
                                }
                                onSelected={(subCategory) =>
                                    updateFormData({ subCategory })
                                }
                                select={formData.subCategory}
                                validationError={formError.subCategory}
                                form={true}
                            />
                        </div>
                        <div className={'field-content'}>
                            <FieldSimilarProduct
                                // I18N
                                label={'Produtos'}
                                onSelected={(product) => {
                                    if (product) {
                                        setSimilarProducts((previous) => [
                                            ...previous,
                                            product,
                                        ]);
                                    }
                                }}
                                ignoreItems={similarProducts.map(
                                    (product) => product.id
                                )}
                                uuid={productUUID}
                            >
                                <Pills
                                    pills={similarProducts}
                                    onRemoveItem={(product) => {
                                        setSimilarProducts((item) => {
                                            return item.filter(
                                                (item) => item !== product
                                            );
                                        });
                                    }}
                                />
                            </FieldSimilarProduct>
                        </div>
                    </div>
                </Section>

                <Section title={'Dados fiscais'}>
                    <div className={'fiscal-container gd gray-background'}>
                        <div className={'gd-col-2'}>
                            <FieldIcms
                                // I18N
                                label={'ICMS'}
                                onSelected={(icms) => updateFormData({ icms })}
                                select={formData.icms}
                                validationError={formError.icms}
                            />
                        </div>
                        <div className={'gd-col-2'}>
                            <FieldNcm
                                label={'NCM'}
                                onSelected={(ncm) => updateFormData({ ncm })}
                                select={formData.ncm}
                                validationError={formError.ncm}
                            />
                        </div>
                        <div className={'gd-col-2'}>
                            <FieldTaxClassification
                                // I18N
                                label={'Classificação fiscal'}
                                onSelected={(taxClassification) =>
                                    updateFormData({ taxClassification })
                                }
                                select={formData.taxClassification}
                                validationError={formError.taxClassification}
                            />
                        </div>
                        <div className={'gd-col-2'}>
                            <FieldTaxSituation
                                // I18N
                                label={'Situação tributária'}
                                onSelected={(taxSituation) =>
                                    updateFormData({ taxSituation })
                                }
                                select={formData.taxSituation}
                                validationError={formError.taxSituation}
                            />
                        </div>
                        <div className={'gd-col-2'}>
                            <FieldCfop
                                // I18N
                                label={'CFOP'}
                                onSelected={(cfop) => updateFormData({ cfop })}
                                select={formData.cfop}
                                validationError={formError.cfop}
                            />
                        </div>
                    </div>
                </Section>

                <Section title={'Configurações de unidade de compra e venda'}>
                    <div className={'fiscal-container gd gray-background'}>
                        <div className={'gd-col-2'}>
                            <FieldInteger
                                // I18N
                                label={'Quantidade comercializada'}
                                onChange={({ target }) =>
                                    updateFormData({
                                        packageQuantity: target.value,
                                    })
                                }
                                value={formData?.packageQuantity}
                                thousandsSeparator={false}
                                validationError={formError?.packageQuantity}
                                maxLength={15}
                                readOnly={
                                    !(
                                        createParentProduct ||
                                        (formData?.parentProduct && !uuidCopy)
                                    )
                                }
                            />
                        </div>
                        {
                            formData?.parentProduct && (
                                <div className={'gd-col-2'}>
                                    <FieldText
                                        label={'Variação do produto'}
                                        onChange={({ target }) =>
                                            updateFormData({ variation: target.value })
                                        }
                                        value={formData?.variation || ''}
                                        validationError={formError?.variation}
                                    />
                                </div>
                            )
                        }
                        <div className={'gd-col-2'}>
                            <FieldCheckbox
                                // I18N
                                label={'Pedidos'}
                                inputs={[
                                    {
                                        value: formData.notPurchaseSuggestion,
                                        label: 'Não incluir na lista de compras',
                                        onChange: () => {
                                            updateFormData({
                                                notPurchaseSuggestion:
                                                    !formData.notPurchaseSuggestion,
                                            });
                                        },
                                        checked: formData.notPurchaseSuggestion,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </Section>

                {!formData?.parentProduct && uuid && (
                    <Section title={'Variações do produto'}>
                        <>
                            <div className={'gd gray-background'}>
                                <div className={'gd-col-2'}>
                                    <FieldText
                                        label={'Variação do produto principal'}
                                        onChange={({ target }) =>
                                            updateFormData({ variation: target.value })
                                        }
                                        value={formData?.variation || ''}
                                        validationError={formError?.variation}
                                    />
                                </div>
                                <div className={'gd-col-12'}>
                                    <Table
                                        columns={[
                                            {
                                                name: 'EAN',
                                                width: '20%',
                                            },
                                            {
                                                name: 'Nome do produto',
                                            },
                                            {
                                                name: 'Variação',
                                                width: '20%',
                                            },
                                            {},
                                        ]}
                                    >
                                        {formData?.variations?.map((p) => {
                                            return (
                                                <tr key={p.uuid}>
                                                    <td>{p.barCodes[0].barCode}</td>
                                                    <td>{[p.name1, p.name2, p.name3].filter(Boolean).join(' ')}</td>
                                                    <td>
                                                        <FieldText
                                                            fieldGroup={false}
                                                            required={true}
                                                            value={p.variation}
                                                            validationError={formError?.variations}
                                                            onChange={({ target }) =>
                                                                updateVariationItem(p.uuid, {
                                                                    variation: target.value,
                                                                })
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            className={'transparent'}
                                                            onClick={() =>
                                                                navigate(
                                                                    `/produtos/listagem/${p.uuid}`
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={IconEditSvg}
                                                                srcSet={`${IconEdit} 1x, ${IconEdit2x} 2x`}
                                                                alt={'Editar'}
                                                                title={'Editar'}
                                                                style={{
                                                                    width: '16px',
                                                                }}
                                                            />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </Table>
                                </div>
                            </div>
                            <div className={'col-12 align-right mt-16'}>
                                <div className={'btn-create-container'}>
                                    <Button
                                        onClick={() => setShowConfirmCriarUnidadeModal(true)}
                                        className={'button-color-orange button-shadow'}
                                    >
                                        {'Criar nova variação'}
                                    </Button>
                                    <Button
                                        onClick={() => setShowProductModal(true)}
                                        className={'button-color-green button-shadow ml-20'}
                                    >
                                        {'Adicionar nova variação'}
                                    </Button>
                                </div>
                            </div>
                        </>
                    </Section>
                )}
                <Section title={'Tags'}>
                    <div className={'col-12 d-flex'}>
                        {
                            Object.values(TagEnum).map(tag => (
                                <div className={'tag-icon mr-10'} key={tag}>
                                    <label htmlFor={tag}>
                                        <input id={tag} name={'tags'} type={'checkbox'} value={tag} onChange={(e) => handleTags(e)} checked={formData?.tags?.includes(tag)} />
                                        <div>
                                            <img src={ICONS_TAG[tag]} width={46} height={46} alt={TagText(tag)} />
                                            {TagText(tag)}
                                        </div>
                                    </label>
                                </div>
                            ))
                        }
                    </div>
                </Section>

                <SearchProductModal
                    globalSearch
                    onlyParent
                    show={showProductModal}
                    onCancel={() => setShowProductModal(false)}
                    onSelect={(prod) => {
                        if (prod) {
                            const newVariations = [...formData.variations];

                            newVariations.push(prod);

                            updateFormData({
                                variations: newVariations,
                            });

                            setShowProductModal(false);
                        }
                    }}
                />

                <SimpleConfirmModal
                    show={showConfirmCriarUnidadeModal}
                    title={'Excluir Código de barras'}
                    zIndex={6}
                    onCancel={() => setShowConfirmCriarUnidadeModal(false)}
                    onConfirm={() => {
                        navigate(
                            `/produtos/listagem/novo?uuid=${uuid}&parentProduct=true`
                        );
                        setShowConfirmCriarUnidadeModal(false);
                    }}
                    confirmText={'Sim, prosseguir'}
                    cancelText={'Cancelar'}
                >
                    <p>
                        {
                            'Será criada uma nova Unidade de Compra e Venda deste produto principal, deseja prosseguir?'
                        }
                    </p>
                </SimpleConfirmModal>

                <ProductInventoryMovement />

                <div className={'controls'}>
                    <div className={'row'}>
                        <div className={'col-8'} />
                        <div className={'col-2'}>
                            <Button
                                className={'w-100'}
                                ref={buttonDeleteRef}
                                color={ButtonColor.BUTTON_COLOR_RED}
                                style={{ display: `${uuid ? '' : 'none'}` }}
                                loading={deleteLoading}
                                onClick={deleteProduct}
                            >
                                {'Excluir [F3]'}
                            </Button>
                        </div>
                        <div className={'col-2'}>
                            <Button
                                className={'w-100'}
                                ref={buttonSaveRef}
                                color={ButtonColor.BUTTON_COLOR_GREEN}
                                fontColor={
                                    ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                }
                                loading={saveLoading}
                                onClick={save}
                            >
                                {'Salvar [F6]'}
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const title = getTitle();
    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'} style={{ backgroundColor: '#fff' }}>
                <div className={'product-screen-header'}>
                    <ScreenHeader
                        title={title}
                        breadcrumbs={[
                            { name: 'Produtos', route: getProductsRoute() },
                            { name: 'Listagem', route: getProductsRoute() },
                            { name: uuid ? (loadingProduct ? '...' : productName) : title },
                        ]}
                        backRoute={getProductsRoute()}
                    >
                    </ScreenHeader>
                    {renderTable()}
                </div>

                {renderForm()}
            </div>

        </>
    );
}

export function getProductFormRoute(uuid, mode) {
    // I18N
    if (uuid) {
        return `/produtos/listagem/${uuid}`;
    }
    else {
        return '/produtos/listagem/novo';
    }
}
