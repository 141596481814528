import { useNavigate } from 'react-router';
import Table from '../../../components/Table';
import { DateTime } from 'luxon';
import { OrderByProductStockCheckEnum, ProductStockCheckFilterTabEnum, ProductStockCheckFilterTabText } from 'erva-doce-common';
import classNames from 'classnames';
import Button from '../../../components/Button';

export default function AdminStockCheckDetailTable({ stockCheckId, data, filter, isLoading, onSortChange }) {
    const navigate = useNavigate();

    return (
        <Table
            loading={isLoading}
            currentSort={filter.orderProduct}
            columns={[
                {
                    name: 'Data da conferência',
                    sortAsc: OrderByProductStockCheckEnum.ID_ASC,
                    sortDesc: OrderByProductStockCheckEnum.ID_DESC,
                    onSortChange: (order) => onSortChange('orderProduct', order),
                },
                {
                    name: 'Produto',
                    sortAsc: OrderByProductStockCheckEnum.PRODUCT_ASC,
                    sortDesc: OrderByProductStockCheckEnum.PRODUCT_DESC,
                    onSortChange: (order) => onSortChange('orderProduct', order),
                },
                {
                    name: 'Quantidade inserida',
                    align: 'center',
                    sortAsc: OrderByProductStockCheckEnum.COUNT_ASC,
                    sortDesc: OrderByProductStockCheckEnum.COUNT_DESC,
                    onSortChange: (order) => onSortChange('orderProduct', order),
                },
                {
                    name: 'Divergência de quantidade',
                    align: 'center',
                    // sortAsc: OrderByProductStockCheckEnum.DIVERGENCE_COUNT_ASC,
                    // sortDesc: OrderByProductStockCheckEnum.DIVERGENCE_COUNT_DESC,
                    // onSortChange: (order) => onSortChange('orderProduct', order),
                },
                {
                    name: 'Motivo da divergência',
                    align: 'center',
                    sortAsc: OrderByProductStockCheckEnum.DIVERGENCE_REASON_ASC,
                    sortDesc: OrderByProductStockCheckEnum.DIVERGENCE_REASON_DESC,
                    onSortChange: (order) => onSortChange('orderProduct', order),
                },
                {
                    name: 'Status',
                    align: 'center',
                    sortAsc: OrderByProductStockCheckEnum.STATUS_ASC,
                    sortDesc: OrderByProductStockCheckEnum.STATUS_DESC,
                    onSortChange: (order) => onSortChange('orderProduct', order),
                },
                {
                    name: 'Responsável',
                    align: 'center',
                    sortAsc: OrderByProductStockCheckEnum.RESPONSIBLE_ASC,
                    sortDesc: OrderByProductStockCheckEnum.RESPONSIBLE_DESC,
                    onSortChange: (order) => onSortChange('orderProduct', order),
                },
                {},
            ]}
        >
            {data?.map((item, index) => (
                <tr
                    className={classNames('table-row', { hasDivergence: item.status === ProductStockCheckFilterTabEnum.WAITING_SOLUTION })}
                    key={index}
                >
                    <td className={'supplier'}>
                        {DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy HH:mm')}
                    </td>
                    <td>{item.productName}</td>
                    <td className={'text-center'}>{item.count}</td>
                    <td className={'text-center'}>{item.count - item.stock > 0 && '+'}{item.count - item.stock}</td>
                    <td className={'text-center'}>{item.divergenceReason ? item.divergenceReason : '-'}</td>
                    <td className={'text-center'}>{ProductStockCheckFilterTabText(item.status)}</td>
                    <td className={'text-center'}>{item.responsibleName}</td>
                    <td className={'text-center'}>
                        {
                            item.status === ProductStockCheckFilterTabEnum.WAITING_SOLUTION && (
                                <div className={'d-flex justify-content-end'}>
                                    <Button
                                        // onClick={() => navigate(getAdminStockCheckRoute(stockCheckId, item.uuid))}
                                        className={'transparent'}
                                        style={{ textDecoration: 'underline' }}
                                    >
                                        {'Resolver'}
                                    </Button>
                                </div>
                            )
                        }
                    </td>
                </tr>
            ))}
        </Table>
    );
}
