import * as Permissions from "./permissions";

export const RoleEnum = {
  ...Permissions.PermissionCollaboratorEnum,
  ...Permissions.PermissionSupplierEnum,
  ...Permissions.PermissionRepresentativeEnum,
  ...Permissions.PermissionStoreEnum,
  ...Permissions.PermissionSegmentationEnum,
  ...Permissions.PermissionStoreTypeEnum,
  ...Permissions.PermissionJobTitleEnum,
  ...Permissions.PermissionExpenseTypeEnum,
  ...Permissions.PermissionQuotationTypeEnum,
  ...Permissions.PermissionProductFamilyEnum,
  ...Permissions.PermissionTaxSituationEnum,
  ...Permissions.PermissionTaxClassificationEnum,
  ...Permissions.PermissionProductCategoryEnum,
  ...Permissions.PermissionCfopEnum,
  ...Permissions.PermissionProductBrandEnum,
  ...Permissions.PermissionIcmsEnum,
  ...Permissions.PermissionNcmEnum,
  ...Permissions.PermissionProductEnum,
  ...Permissions.PermissionSaleEnum,
  ...Permissions.PermissionExchangesEnum,
  ...Permissions.PermissionPaymentTypeEnum,
  ...Permissions.PermissionMachineEnum,
  ...Permissions.PermissionBillsToReceiveEnum,
  ...Permissions.PermissionCancelReasonEnum,
  ...Permissions.PermissionOrderReasonEnum,
  ...Permissions.PermissionPurchaseSuggestionReasonEnum,
  ...Permissions.PermissionStockEnum,
} as const;

export function RoleText(key: string): string {
  const role = RoleEnum[key];
  if (role) {
    if (key.endsWith("_VISUALIZE")) {
      // I18N
      return "Visualizar";
    } else if (key.endsWith("_EDIT")) {
      // I18N
      return "Editar";
    } else if (key.endsWith("_ADD")) {
      // I18N
      return "Incluir";
    } else if (key.endsWith("_REMOVE")) {
      // I18N
      return "Excluir";
    }

    // exceptions here
    // switch (role) {
    //
    // }
  }
  throw new Error(`Not implemented for ${key}`);
}
