import { isValidElement, useContext, useEffect, useRef } from 'react';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import './InfoModal.scss';
import classNames from 'classnames';

function SimpleConfirmModal({
    title,
    show,
    onConfirm,
    onCancel,
    cancelText,
    confirmText,
    children,
    zIndex,
    className,
    danger = false,
    disabled,
    footerColumn,
}) {
    const lastShowRef = useRef(false);
    const cancelButtonRef = useRef(null);
    const confirmButtonRef = useRef(null);
    const {
        addHotkey,
        removeHotkey,
    } = useContext(EnvironmentContext);

    useEffect(() => {
        // avoid unnecessary actions
        if (show === lastShowRef.current) return;
        lastShowRef.current = show;

        let f4Hotkey;
        let f6Hotkey;

        function removeHotkeys() {
            removeHotkey(f4Hotkey);
            removeHotkey(f6Hotkey);
        }

        if (show) {
            f4Hotkey = addHotkey('F4', () => cancelButtonRef.current?.click());
            f6Hotkey = addHotkey('F6', () => confirmButtonRef.current?.click());

        } else {
            removeHotkeys();

        }
        return () => {
            removeHotkeys();

        };
    }, [show]);

    if (!isValidElement(title) && title) {
        title = (
            <h2>
                {title}
            </h2>
        );
    }

    return (
        <div
            style={{ zIndex: zIndex ?? 10010 }}
            className={`
            modal
            info-modal
            ${danger ? 'error' : 'success'}
            ${show ? 'show' : ''}
            ${className ?? ''}`
            }>
            <div className={'backdrop'} />
            <div className={'container'}>
                <div className={'body'}>
                    {
                        title && (
                            <div className={'title'}>
                                {title}
                            </div>
                        )
                    }
                    {children}
                    <div className={'controls'}>
                        <div className={classNames('controls', { footerColumn })}>
                            {onCancel &&
                                <Button
                                    ref={cancelButtonRef}
                                    color={ButtonColor.BUTTON_COLOR_GRAY}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    onClick={onCancel}
                                >
                                    {/*I18N*/}
                                    {`${cancelText ?? 'Cancelar'} [F4]`}
                                </Button>
                            }

                            {onConfirm &&
                                <Button
                                    ref={confirmButtonRef}
                                    color={danger ? ButtonColor.BUTTON_COLOR_RED : ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    onClick={onConfirm}
                                    className={disabled && 'disabled'}
                                    disabled={disabled}
                                >
                                    {/*I18N*/}
                                    {`${confirmText ?? 'Confirmar'} [F6]`}
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SimpleConfirmModal;
