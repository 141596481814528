import './SearchProductModal.scss';
import * as ProductsService from '../services/ProductsService';
import * as Sentry from '@sentry/react';
import SimpleConfirmModal from '../components/modal/SimpleConfirmModal';
import { useEffect, useRef, useState } from 'react';
import FieldTextSearch from './FieldTextSearch';
import Table from './Table';
import axios from 'axios';

function SearchProductModal({
    show,
    onCancel,
    onSelect,
    globalSearch,
    onlyParent,
}) {
    const emptyFormData = {
        products: []
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [selectedIdx, setSelectedIdx] = useState(-1);
    const inputRef = useRef();

    const [filter, setFilter] = useState({
        search: null,
    });

    function updateFormData(data) {
        setFormData((formData) => ({ ...formData, ...data }));
    }

    function cancel() {
        onCancel();
    }

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus();
        }, 200);
    }, [show]);


    useEffect(() => {
        fetchProductsByName();
    }, [filter]);

    async function fetchProductsByName() {
        try {
            if (filter.search) {
                const products = await ProductsService.getProductsByName(filter.search, { globalSearch, onlyParent });

                updateFormData([]);
                updateFormData({ products:  products });
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
            }
        }
    }

    function selectProduct(prod){
        onSelect && onSelect(prod);
    }

    const checkKey = (event) => {
        event = event || window.event;

        if (event.key === 'ArrowUp') {
            let newSelectionIdx = selectedIdx - 1;
            if (newSelectionIdx < 0) newSelectionIdx = 0;
            setSelectedIdx(newSelectionIdx);
        } else if (event.key === 'ArrowDown') {
            let newSelectionIdx = selectedIdx + 1;
            if (newSelectionIdx >= formData.products.length) newSelectionIdx = formData.products.length - 1;
            setSelectedIdx(newSelectionIdx);
        }
    };

    document.onkeydown = checkKey;

    function renderTable() {
        // if (loading && loading instanceof Error) {
        //     return (
        //         <LoadError
        //             // I18N
        //             message={'Não foi possível carregar os itens'}
        //             // onTryAgain={fetchExchanges}
        //         />
        //     );
        // }

        let rows = [];
        // noinspection JSUnresolvedReference
        for (const [index, p] of formData.products.entries()) {
            const selectedRow = index === selectedIdx;
            rows.push(
                <tr key={p.uuid} onClick={() => selectProduct(p)} className={selectedRow ? 'selected' : null}>
                    <td>
                        {p?.barCodes?.find(x => x.main)?.barCode || ''}
                    </td>
                    <td>
                        {[p.name1, p.name2, p.name3].join(' ')}
                    </td>
                    <td>
                        {p.newPrice?.toLocaleString(undefined, { style: 'currency', currency: 'BRL', })}
                    </td>
                </tr>
            );
        }

        return (
            <div>
                <Table
                // loading={loading}
                    columns={[
                        {
                        // I18N
                            name: 'Cod. Barras'
                        },
                        {
                        // I18N
                            name: 'Produto'
                        },
                        {
                        // I18N
                            name: 'Valor'
                        },
                    ]}>
                    {rows}
                </Table>
            </div>
        );
    }

    return (

        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={() => selectProduct(formData.products[selectedIdx])}
            // I18N
            title={'Buscar Produto'}
            className={'search-product-modal'}
            confirmText={'Selecionar'}
        >
            <fieldset>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-12'}>
                        <FieldTextSearch
                            ref={inputRef}
                            // I18N
                            label={'<em>Digite o produto desejado</em>'}
                            onChange={({ target }) =>
                                setFilter({ ...filter, search: target.value })
                            }
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-12'}>
                        {renderTable()}
                    </div>
                </div>
            </fieldset>
            {selectedIdx != -1 && (
                <div className={'selected-product'}>
                    {formData.products[selectedIdx].name1 + ' ' + formData.products[selectedIdx].name2 + ' ' + formData.products[selectedIdx].name3}
                    <span>{formData.products[selectedIdx].newPrice?.toLocaleString(undefined, { style: 'currency', currency: 'BRL', })}</span>
                </div>

            )}


        </SimpleConfirmModal>
    );
}

export default SearchProductModal;
