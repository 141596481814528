import Button from './Button';
import { IconCheckBox, IconSortAsc, IconSortAsc2x, IconSortDesc, IconSortDesc2x } from './images';
import { Loading, LoadingColor, LoadingSize } from './Loading';
import { useEffect, useRef, useState } from 'react';
import './Table.scss';

export default function Table({
    columns,
    currentSort,
    children,
    loading,
    selectAll,
    handleSelectAll,
}) {
    const tbodyRef = useRef();
    const [loadingStyle, setLoadingStyle] = useState({
        height: 'unset',
        top: 'unset',
    });

    const [sort, setSort] = useState(currentSort);

    const empty = !children || !children.length;

    const currentSortFormmated = String(currentSort).includes('ASC') ? 'ascendant' : 'descendant';

    useEffect(() => {
        setLoadingStyle({
            height: tbodyRef.current?.clientHeight ? `${tbodyRef.current?.clientHeight}px` : 'unset',
            top: tbodyRef.current?.offsetTop ? `${tbodyRef.current?.offsetTop}px` : 'unset',
        });
    }, [loading]);

    return (
        <div className={'table-container'}>
            <div
                className={`loading-container ${loading ? 'show' : ''}`}
                style={loadingStyle}
            >
                <Loading color={LoadingColor.GREEN} size={LoadingSize.LARGE} />
            </div>
            <table className={'table'}>
                <thead>
                    <tr>
                        {handleSelectAll && (
                            <th>
                                <label className={'custom-checkbox'}>
                                    <input
                                        type={'checkbox'}
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                        hidden
                                    />
                                    <span className={'custom-checkbox-icon'}>
                                        <img src={IconCheckBox} alt={'Check'} />
                                    </span>
                                </label>
                            </th>
                        )}
                        {columns.map((column, index) => {
                            const isOrderedColumn = Boolean(
                                column.onSortChange
                            );

                            return (
                                <th
                                    key={index}
                                    className={column.className ? `${column.className}` : ''}
                                    style={
                                        column.width
                                            ? { width: column.width }
                                            : {}
                                    }
                                >
                                    <div className={`table-header-container${
                                        column.align
                                            ? ` justify-content-${column.align}`
                                            : ''
                                    }`}>
                                        {isOrderedColumn ? (
                                            <Button
                                                className={
                                                    'transparent button-sort'
                                                }
                                                onClick={() => {
                                                    column.onSortChange(
                                                        currentSortFormmated ===
                                                            'ascendant'
                                                            ? column.sortDesc
                                                            : column.sortAsc
                                                    );
                                                    setSort(
                                                        currentSortFormmated ===
                                                            'ascendant'
                                                            ? column.sortDesc
                                                            : column.sortAsc
                                                    );
                                                }}
                                            >
                                                <div className={'icon-sort'}>
                                                    <div
                                                        className={
                                                            'icon-ascendant'
                                                        }
                                                    >
                                                        {sort ===
                                                            column.sortAsc && (
                                                            <img
                                                                src={
                                                                    IconSortAsc
                                                                }
                                                                srcSet={`${IconSortAsc} 1x, ${IconSortAsc2x} 2x`}
                                                                alt={''}
                                                            />
                                                        )}
                                                        {sort ===
                                                            column.sortDesc && (
                                                            <img
                                                                src={
                                                                    IconSortDesc
                                                                }
                                                                srcSet={`${IconSortDesc} 1x, ${IconSortDesc2x} 2x`}
                                                                alt={''}
                                                            />
                                                        )}
                                                    </div>

                                                    {sort !== column.sortAsc &&
                                                        sort !==
                                                            column.sortDesc && (
                                                        <div
                                                            className={
                                                                'icon-descendant'
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    IconSortAsc
                                                                }
                                                                srcSet={`${IconSortAsc} 1x, ${IconSortAsc2x} 2x`}
                                                                alt={''}
                                                            />
                                                            <img
                                                                src={
                                                                    IconSortDesc
                                                                }
                                                                srcSet={`${IconSortDesc} 1x, ${IconSortDesc2x} 2x`}
                                                                alt={''}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                {column.name ?? ''}
                                            </Button>
                                        ) : (
                                            <span>{column.name ?? ''}</span>
                                        )}
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody ref={tbodyRef}>
                    {empty ? (
                        <tr>
                            <td
                                colSpan={columns?.length + (handleSelectAll ? 1 : 0)}
                                className={'status-container'}
                            >
                                {'Não há dados para exibir'}
                            </td>
                        </tr>
                    ) : (
                        <>{children}</>
                    )}
                </tbody>
            </table>
        </div>
    );
}
