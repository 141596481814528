import {
    RoleEnum,
    ValidationErrorCancelReasonText,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonColor, ButtonFontColor } from '../../components/Button';
import FieldText from '../../components/FieldText';
import { Loading, LoadingSize } from '../../components/Loading';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as CancelReasonService from '../../services/CancelReasonService';
import { getCancelReasonRoute } from './CancelReason';

export default function CancelReasonForm() {
    const emptyFormData = {
        name: '',
        code: ''
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [formError, setFormError] = useState(emptyFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const buttonDeleteRef = useRef();
    const buttonSaveRef = useRef();
    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        setWindowTitle,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);
    const { uuid } = useParams();
    const [isLoading, setIsLoading] = useState(!!uuid);
    const [cancelReasonName, setCancelReasonName] = useState('');
    const [hasChange, setHasChange] = useState(false);
    const navigate = useNavigate();

    const canSave = uuid
        ? user.roles?.includes(RoleEnum.PERMISSION_CANCEL_REASON_EDIT)
        : user.roles?.includes(RoleEnum.PERMISSION_CANCEL_REASON_ADD);
    const canRemove = user.roles?.includes(RoleEnum.PERMISSION_CANCEL_REASON_REMOVE);

    function updateFormData(data) {
        setFormData((formData) => ({ ...formData, ...data }));
        setHasChange(true);
    }

    async function fetchCancelReason() {
        try {
            setIsLoading(true);
            const cancelReason = await CancelReasonService.getCancelReason(uuid);

            setCancelReasonName(cancelReason.name);
            setFormData({
                ...cancelReason
            });
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Motivo de cancelamento não encontrado.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch cancelReason', e, null, title);
            }
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (uuid) {
            fetchCancelReason();
        }
    }, [uuid]);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);

        if (!formData.name?.trim()) {
            hasError = true;
            setFormError((formError) => ({ ...formError, name: 'Digite o nome do Motivo de cancelamento' }));
        }

        return hasError;
    }

    useEffect(() => {
        let f3Hotkey;
        let f6Hotkey;
        if (uuid) {
            if (canRemove) {
                f3Hotkey = addHotkey('F3', () => {
                    buttonDeleteRef.current?.click();
                });
            }
        }
        if (canSave) {
            f6Hotkey = addHotkey('F6', () => {
                buttonSaveRef.current?.click();
            });
        }
        return () => {
            removeHotkey(f3Hotkey);
            removeHotkey(f6Hotkey);
        };
    }, []);

    useEffect(() => {
        setWindowTitle(getTitle(true));
        return () => {
            setWindowTitle();
        };
    }, [cancelReasonName]);

    function back() {
        navigate(getCancelReasonRoute());
    }

    async function save() {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);
            const body = {
                name: formData.name.trim(),
            };

            let message;
            if (!uuid) {
                await CancelReasonService.addCancelReason(body);
                message = 'Motivo de cancelamento salvo com sucesso!';
            } else {
                await CancelReasonService.editCancelReason(uuid, body);
                message = 'Motivo de cancelamento editado com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = back;

            setHasChange(false);
            setInfoModal({
                title,
                message,
                style,
                show: true,
                onClose,
            });
        } catch (e) {
            backendConnectionError('Fail to create/edit cfop', e, null, title, ValidationErrorCancelReasonText);
        } finally {
            setSaveLoading(false);
        }
    }

    async function deleteCancelReason() {
        let title = 'Excluir motivo de cancelamento';
        async function proceed() {
            try {
                setDeleteLoading(true);
                await CancelReasonService.deleteCancelReason(uuid);
                const message = 'Motivo de cancelamento excluído com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete cancelReason', e, null, title, ValidationErrorCancelReasonText);
            } finally {
                setDeleteLoading(false);
            }
        }

        setConfirmModal({
            title,
            message: 'Você tem certeza que deseja excluir o motivo de cancelamento?',
            onConfirm: proceed,
            show: true,
        });
    }

    useEffect(() => {
        setLoading(saveLoading, true);
    }, [saveLoading]);

    function getTitle(windowTitle = false) {
        if (uuid) {
            let title = 'Editar Motivo de cancelamento';
            if (windowTitle && cancelReasonName) title += ` - ${cancelReasonName}`;
            return title;
        } else {
            return 'Novo Motivo de cancelamento';
        }
    }

    const renderForm = () => {
        if (isLoading) {
            return (
                <Loading
                    size={LoadingSize.LARGE}
                />
            );
        }

        return (
            <>
                <div>
                    <fieldset>
                        <legend>
                            {/*I18N*/}
                            {'Dados'}
                        </legend>
                        <div className={'gd field-group'}>
                            <div className={'gd-col-8'}>
                                <FieldText
                                    label={'Nome'}
                                    onChange={({ target }) => updateFormData({ name: target.value })}
                                    value={formData?.name}
                                    validationError={formError?.name}
                                    maxLength={120}
                                    readOnly={!canSave}
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={`gd-col ${canSave ? 'gd-col-8' : 'gd-col-10'}`} />
                        <div className={'gd-col gd-col-2'}>
                            {(uuid && canRemove && formData?.canExclude) && (
                                <Button
                                    ref={buttonDeleteRef}
                                    color={ButtonColor.BUTTON_COLOR_RED}
                                    style={{ display: `${uuid ? '' : 'none'}` }}
                                    loading={deleteLoading}
                                    onClick={deleteCancelReason}
                                >
                                    {'Excluir [F3]'}
                                </Button>
                            )}
                        </div>
                        {canSave && (
                            <div className={'gd-col gd-col-2'}>
                                <Button
                                    ref={buttonSaveRef}
                                    color={ButtonColor.BUTTON_COLOR_GREEN}
                                    fontColor={ButtonFontColor.BUTTON_FONT_COLOR_LIGHT}
                                    loading={saveLoading}
                                    onClick={save}
                                >
                                    {'Salvar [F6]'}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const title = getTitle();
    return (
        <>
            <RouteChangePrompt enabled={hasChange} />
            <div className={'crud-form'}>
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Compras', route: getDashboardRoute() },
                        {
                            name: 'Motivos de cancelamento',
                            route: getCancelReasonRoute(),
                        },
                        {
                            name: uuid
                                ? isLoading
                                    ? '...'
                                    : cancelReasonName
                                : title,
                        },
                    ]}
                    backRoute={getCancelReasonRoute()}
                ></ScreenHeader>

                {renderForm()}
            </div>
        </>
    );
}

export function getCancelReasonFormRoute(uuid) {
    if (uuid) {
        return `/compras/motivos-cancelamento/${uuid}`;
    } else {
        return '/compras/motivos-cancelamento/novo';
    }
}
