import * as Sentry from '@sentry/react';
import axios from 'axios';
import { RoleEnum, unmaskCpf } from 'erva-doce-common';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Amount from '../../components/Amount';
import Button, { ButtonColor, ButtonFontColor, ButtonStyle } from '../../components/Button';
import ButtonRemove from '../../components/ButtonRemove';
import { extractCurrencyNumber } from '../../components/FieldCurrency';
import FieldInteger from '../../components/FieldInteger';
import FieldTextSearch from '../../components/FieldTextSearch';
import RouteChangePrompt from '../../components/RouteChangePrompt';
import SearchExchangeModal from '../../components/SearchExchangeModal';
import SearchProductModal from '../../components/SearchProductModal';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import * as ExchangesService from '../../services/ExchangesService';
import * as ProductsService from '../../services/ProductsService';
import * as SalesService from '../../services/SalesService';
import { formatValue } from '../../util/formatValue';
import { getSalesRoute } from './Sales';
import './SalesForm.scss';
import SalesModal from './SalesModal';
import SalesPaymentModal from './SalesPaymentModal';

export default function SalesForm() {
    const {
        backendConnectionError,
        setInfoModal,
        setWindowTitle,
        addHotkey,
        removeHotkey,
        user,
        setConfirmModal
    } = useContext(EnvironmentContext);
    const emptyFormData = {
        payments: [],
        clientName: ''
    };
    const emptyPayments = {
        quantityInstallments: 1,
        amountInstallments: 'R$ 0,00',
        total: 'R$ 0,00',
        paymentType: null,
        machine: null,
        newRow: true,
    };
    const emptyFormError = emptyFormData;
    const [formData, setFormData] = useState(emptyFormData);
    const [, setFormError] = useState(emptyFormError);
    const [, setValidateOnChange] = useState(false);
    const { uuid } = useParams();
    const [saleName, setSaleName] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [, setDeleteLoading] = useState(false);
    const [filter, setFilter] = useState({
        search: '',
    });
    const inputAmount = useRef();
    const inputSearchRef = useRef();
    const [amount, setAmount] = useState(1);
    const [exchangeTotal, setExchangeTotal] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showSaleModal, setShowSaleModal] = useState(true);
    const [showExchangeModal, setShowExchangeModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const [disable, setDisable] = useState(false);
    const [disableRescueExchangeButton, setDisableRescueExchangeButton] = useState(false);
    const [isSaleEditable, setIsSaleEditable] = useState(true);
    const [productsSelected, setProductsSelected] = useState([]);
    const [salesForm, setSalesForm] = useState();

    const navigate = useNavigate();

    const canSave = uuid
        ? user.roles?.includes(RoleEnum.PERMISSION_SALE_EDIT)
        : user.roles?.includes(RoleEnum.PERMISSION_SALE_ADD);
    const canRemove = user.roles?.includes(RoleEnum.PERMISSION_SALE_REMOVE);



    useEffect(() => {
        async function fetchSale() {
            try {
                const sale = await SalesService.getSale(uuid);

                if (sale.payments.length <= 0) {
                    setIsSaleEditable(false);

                } else {
                    setIsSaleEditable(true);
                }
            } catch (error) {
                console.error('Failed to fetch sale:', error);
            }
        }

        const expectedPath = `/vendas/${uuid}`;
        const isNewSale = location.pathname === '/vendas/novo';

        if (uuid && location.pathname === expectedPath) {
            fetchSale();
        } else if (isNewSale) {
            setIsSaleEditable(false);

        }
    }, [uuid, location.pathname]);

    useEffect(() => {
        const isNewSale = location.pathname === '/vendas/novo';
        if (isNewSale) {
            setFormData(emptyFormData);
            setProductsSelected([]);
            setExchangeTotal(0);
            setShowSaleModal(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (uuid) {
            setShowSaleModal(false);
            // noinspection JSIgnoredPromiseFromCall
            fetchSale();
        }
    }, [uuid]);

    useEffect(() => {
        setLoading(loading, true);
    }, [loading]);

    useEffect(() => {
        setWindowTitle('Venda de produtos');

        let f1Hotkey;
        if (!isSaleEditable) {
            f1Hotkey = addHotkey('F1', () => {
                setShowModal(true);
            });
        }

        const f8Hotkey = addHotkey('F8', () => {
            setShowExchangeModal(true);
        });

        return () => {
            if (f1Hotkey) removeHotkey(f1Hotkey);
            removeHotkey(f8Hotkey);
            setWindowTitle();
        };
    }, [isSaleEditable]);

    useEffect(() => {
        const f4Hotkey = addHotkey('F4', () => {
            if (!showExchangeModal && !showSaleModal) {
                deleteSale();
            }
            if (showSaleModal) setShowSaleModal(false);
        });
        return () => {
            removeHotkey(f4Hotkey);
        };
    }, [showSaleModal]);

    useEffect(() => {

        if (showPaymentModal) {
            const f2Hotkey = addHotkey('F2', () => {
                addRowPayment();
            });

            return () => {
                removeHotkey(f2Hotkey);
            };
        }
    }, [showPaymentModal]);

    useEffect(() => {
        if (uuid && formData.exchange) {
            // noinspection JSIgnoredPromiseFromCall
            fetchExchangeTotal();
        }
    }, [uuid && formData.exchange]);

    useEffect(() => {
        if (!productsSelected.length) setHasChange(false);
        else setHasChange(true);

        hasValidationError();

        const f6Hotkey = addHotkey('F6', () => {
            if (productsSelected.length) {
                if (!formData.payments.length) addRowPayment();
                setShowPaymentModal(true);
            }
            if (showSaleModal) {
                updateFormData({ cpfNote: salesForm.cpfNote });
                updateFormData({ seller: salesForm.seller });
                setShowSaleModal(false);

            }

        });

        return () => {
            removeHotkey(f6Hotkey);
            setWindowTitle();
        };
    }, [formData && productsSelected, salesForm]);

    useEffect(() => {
        const disabled = formData.payments.some(({
            amountInstallments,
            paymentType,
            machine,
            quantityInstallments,
            total
        }) => {
            const fullTotal = extractCurrencyNumber(total) + extractCurrencyNumber(emptyPayments.total);
            return (
                extractCurrencyNumber(amountInstallments) === extractCurrencyNumber(emptyPayments.amountInstallments) ||
                paymentType === emptyPayments.paymentType ||
                machine === emptyPayments.machine ||
                fullTotal !== extractCurrencyNumber(totalSale) ||
                quantityInstallments < emptyPayments.quantityInstallments
            );
        });
        setDisable(disabled);

        if (uuid && (formData.payments.length || formData.exchange)) {
            setDisableRescueExchangeButton(true);
            addHotkey('F8', () => {
                setShowExchangeModal(false);
            });
        } else {
            setDisableRescueExchangeButton(false);
            addHotkey('F8', () => {
                setShowExchangeModal(true);
            });
        }
    }, [formData.payments]);

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
        if (canSave) setHasChange(true);
    }

    async function fetchSale() {
        try {
            setLoading(true);
            const sale = await SalesService.getSale(uuid);

            setProductsSelected(sale.products);

            const payments = [];
            for (const p of sale.payments) {
                const { paymentType, machine, total, amountInstallments, quantityInstallments } = p;
                const pt = paymentType ? { id: paymentType, value: null } : null;
                const m = machine ? { id: machine, value: null } : null;

                payments.push({
                    quantityInstallments,
                    paymentType: pt,
                    machine: m,
                    total: total?.toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'BRL'
                    }) ?? '',
                    amountInstallments: amountInstallments?.toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'BRL'
                    }) ?? '',
                });
            }

            setFormData({
                ...sale,
                payments,
            });

            setSaleName(sale.clientName);
        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Venda não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch sale', e, null, title);
            }
        } finally {
            setLoading(false);
        }
    }

    async function fetchExchangeTotal() {
        try {
            const exchange = await ExchangesService.getExchangeTotal(formData.exchange);

            if (exchange) setExchangeTotal(exchange.total);

        } catch (e) {
            const title = getTitle();
            const { response } = e;
            if (response?.status === 404) {
                setInfoModal({
                    title,
                    message: 'Troca não encontrada.',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: back,
                });
            } else {
                backendConnectionError('Fail to fetch exchange', e, null, title);
            }
        }
    }

    function addRowPayment() {
        formData.payments.push(emptyPayments);
        updateFormData({ payments: [...formData.payments] });
    }

    function back() {
        navigate(getSalesRoute());
    }

    function hasValidationError() {
        let hasError = false;
        setFormError(emptyFormError);
        setValidateOnChange(true);
        return hasError;
    }

    async function deleteSale() {
        setConfirmModal({
            title: 'Cancelar venda',
            message: 'Você tem certeza que deseja cancelar a venda?',
            onConfirm: proceed,
            show: true,
        });


        async function proceed() {
            try {
                setDeleteLoading(true);
                await SalesService.deleteSale(uuid);

                const message = 'Venda cancelada com sucesso!';
                const style = InfoModalStyle.SUCCESS;
                const onClose = back;
                setHasChange(false);
                setInfoModal({

                    title: 'Cancelar venda',
                    message,
                    style,
                    show: true,
                    onClose,
                });
            } catch (e) {
                backendConnectionError('Fail to delete sale', e, null, title);
            } finally {
                setDeleteLoading(false);
            }
        }
    }

    function getTitle(windowTitle = false) {
        if (uuid) {

            let title = 'Editar venda';
            if (windowTitle && saleName) title += ` - ${saleName}`;
            return title;
        } else {

            return 'Nova venda';
        }
    }

    const addProduct = useCallback(
        (product, isBarcodeSearch = false) => {
            const productFound = productsSelected.find(
                cartProduct => cartProduct.uuid === product.uuid,
            );
            const alreadyAdded = productFound?.amount || 0;
            if (Number(amount) <= 0) {
                setInfoModal({
                    title: 'Alerta',
                    message: 'Quantidade inválida!',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: () => {
                        setFilter({ ...filter, search: '' });
                    },
                });
                return;
            }


            if (!isBarcodeSearch && Number(amount) > product.stock - alreadyAdded) {
                setInfoModal({
                    title: 'Alerta',
                    message: 'Estoque insuficiente!',
                    style: InfoModalStyle.ERROR,
                    show: true,
                    onClose: () => {
                    },
                });
                return;
            }

            if (!productFound) {
                setProductsSelected(prevProducts => [
                    ...prevProducts,
                    {
                        ...product,
                        amount: Number(amount),
                        total: product.newPrice * Number(amount)
                    },
                ]);
            } else {
                setProductsSelected(prevProducts =>
                    prevProducts.map(product => {
                        if (productFound.uuid === product.uuid) {
                            return {
                                ...product,
                                amount: Number(product.amount) + Number(amount),
                                total: product.newPrice * (Number(product.amount) + Number(amount))
                            };
                        }

                        return product;
                    }),
                );
            }
        },
        [amount, productsSelected],
    );

    const fetchProductsByBarCode = async () => {
        try {
            if (filter.search) {
                const prod = await ProductsService.getProductByBarCode(filter.search);
                const productFound = productsSelected.find(
                    cartProduct => cartProduct.uuid === prod.uuid,
                );
                const alreadyAdded = productFound?.amount || 0;
                if (prod.uuid) {
                    if (Number(amount) > prod.stock - alreadyAdded) {
                        setConfirmModal({
                            title: 'Estoque insuficiente!',
                            message: 'Deseja adicionar o produto mesmo assim?',
                            onConfirm: () => {
                                addProduct(prod, true);
                                setAmount(1);
                                setFilter({ ...filter, search: '' });
                            },
                            onCancel: () => {
                                setFilter({ ...filter, search: '' });
                            },
                            show: true,
                        });
                    } else {

                        addProduct(prod, true);
                        setAmount(1);
                        setFilter({ ...filter, search: '' });
                    }
                } else {
                    setInfoModal({
                        title: 'Alerta',
                        message: 'Produto não encontrado na loja!',
                        style: InfoModalStyle.ERROR,
                        show: true,
                        onClose: () => {
                        },
                    });
                    setFilter({ ...filter, search: '' });
                }
            }
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
            }
        }
    };

    // useEffect(() => {
    //     // noinspection JSIgnoredPromiseFromCall
    //     fetchProductsByBarCode();
    // }, [filter.search, addProduct]);

    const totalSale = useMemo(() => {
        const total = (productsSelected.reduce((sum, product) => {
            return sum + Number(product.total);
        }, 0)) ?? 0;

        return formatValue(total);
    }, [productsSelected]);

    const totalPayable = useMemo(() => {
        return formatValue((productsSelected.reduce((sum, product) => {
            return sum + Number(product.total);
        }, 0) - exchangeTotal) ?? 0);
    }, [productsSelected, exchangeTotal]);

    const totalItens = useMemo(() => {
        return productsSelected.reduce((sum, product) => {
            return sum + Number(product.amount);
        }, 0) ?? 0;
    }, [productsSelected]);

    async function save(returnRoute) {
        if (saveLoading) return;
        if (hasValidationError()) return;

        const title = getTitle();
        try {
            setSaveLoading(true);

            const payments = [];
            for (const p of formData.payments) {
                const { paymentType, machine, total, amountInstallments } = p;
                const payment = {
                    ...p,
                    paymentType: paymentType?.id ?? null,
                    machine: machine?.id ?? null,
                    total: extractCurrencyNumber(total ?? ''),
                    amountInstallments: extractCurrencyNumber(amountInstallments ?? ''),
                };
                payments.push(payment);
            }

            const body = {
                ...formData,
                lineQuantity: productsSelected.length,
                itemsQuantity: productsSelected.reduce((sum, pro) => sum + Number(pro.amount), 0),
                total: productsSelected.reduce((sum, pro) => sum + Number(pro.total), 0) - exchangeTotal,
                cpfNote: unmaskCpf(formData.cpfNote),
                seller: formData.seller?.id ?? null,
                exchange: formData.exchange ?? null,
                products: productsSelected,
                payments: payments,
                cashier: user.uuid,
            };

            let message;
            if (!uuid) { // new sale
                await SalesService.addSale(body);

                message = 'Venda salva com sucesso!';
            } else {
                await SalesService.editSale(uuid, body);

                message = 'Venda editada com sucesso!';
            }

            let style = InfoModalStyle.SUCCESS;
            let onClose = () => {
                navigate(0);
            };
            setHasChange(false);

            if (returnRoute) {
                setTimeout(() => {
                    navigate(getSalesRoute());
                }, 200);
            } else {
                setInfoModal({
                    title,
                    message,
                    style,
                    show: true,
                    onClose,
                });
            }
        } catch (e) {
            backendConnectionError('Fail to create/edit sale', e, null, title);
        } finally {
            setSaveLoading(false);
        }
    }

    const title = getTitle();

    return (
        <>
            <RouteChangePrompt
                enabled={hasChange}
                message={
                    'Deseja salvar a venda com o status <strong>Em edição</strong>?'
                }
                onConfirm={() => {
                    save(true);
                }}
            />
            <div
                className={'crud-form sales-form'}
                style={{ height: 'calc(100vh - 40px)' }}
            >
                <ScreenHeader
                    title={title}
                    breadcrumbs={[
                        { name: 'Vendas', route: getSalesRoute() },
                        { name: uuid ? (loading ? '...' : 'Editar venda') : title },
                    ]}
                    backRoute={getSalesRoute()}
                />
                <div className={'mb-28'}>
                    <div className={'row'}>
                        <div className={'col-4 d-flex'}>
                            <div className={'quantity-field mr-4'}>
                                <p> {'Quantidade:'} </p>
                                <FieldInteger
                                    disabled={isSaleEditable}
                                    ref={inputAmount}
                                    value={amount}
                                    onChange={({ target }) =>
                                        setAmount(target.value)
                                    }
                                    thousandsSeparator={false}
                                    maxLength={4}
                                    height={'54px'}
                                    fieldGroup={false}
                                    className={'amount-field'}
                                />
                            </div>
                            <FieldTextSearch
                                disabled={isSaleEditable}
                                ref={inputSearchRef}
                                label={
                                    '<em>Buscar <strong>produto</strong></em> [F1]'
                                }
                                value={filter.search}
                                onChangeDebounce={fetchProductsByBarCode}
                                onChange={({ target }) => {
                                    setFilter({
                                        ...filter,
                                        search: target.value,
                                    });

                                }}
                                className={'text_filter'}
                            />
                        </div>
                        <div className={'col-8 align-right'}>
                            <div className={'row col-12 align-right'}>
                                {uuid && canRemove && (
                                    <div className={'col-3'}>
                                        <Button
                                            disabled={isSaleEditable}
                                            className={'w-100'}
                                            buttonStyle={
                                                ButtonStyle.BUTTON_SHADOW
                                            }
                                            color={
                                                ButtonColor.BUTTON_COLOR_GRAY
                                            }
                                            onClick={deleteSale}

                                        >
                                            {'Cancelar venda [F4]'}
                                        </Button>
                                    </div>
                                )}
                                <div className={'col-3'}>
                                    <Button
                                        className={'w-100'}
                                        buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                        fontColor={
                                            ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                        }
                                        onClick={() => {
                                            setShowExchangeModal(true);
                                        }}
                                        disabled={isSaleEditable}
                                    >
                                        {'Resgatar troca [F8]'}
                                    </Button>
                                </div>
                                <div className={'col-3'}>
                                    <Button
                                        className={'w-100'}
                                        buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                        fontColor={
                                            ButtonFontColor.BUTTON_FONT_COLOR_LIGHT
                                        }
                                        onClick={() => {
                                            if (productsSelected.length) {
                                                if (!formData.payments.length)
                                                    addRowPayment();
                                                setShowPaymentModal(true);
                                            }
                                        }}
                                        disabled={isSaleEditable}
                                    >
                                        {'Finalizar venda [F6]'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'table-container'}>
                        <table className={'products_header table'}>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }}>{'Linha'}</th>
                                    <th
                                        style={{ width: '40%' }}
                                        className={'text-start'}
                                    >
                                        {'Descrição do produto'}
                                    </th>
                                    <th>{'Quantidade'}</th>
                                    <th>{'Preço unitário'}</th>
                                    <th>{'Total'}</th>
                                    <th style={{ width: '5%' }}></th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <div className={'middle-container'}>
                    <table className={'products_body'}>
                        <tbody>
                            {productsSelected.length ? (
                                productsSelected.map((product, index) => (
                                    <tr key={product.uuid}>
                                        <td style={{ width: '5%' }}>
                                            {index + 1}
                                        </td>
                                        <td
                                            style={{ width: '40%' }}
                                            className={'text-start'}
                                        >
                                            {product.name1}
                                        </td>
                                        <td>{product.amount}</td>
                                        <td>{formatValue(product.newPrice)}</td>
                                        <td>{formatValue(product.total)}</td>
                                        <td style={{ width: '5%' }}>
                                            {!isSaleEditable && (
                                                <ButtonRemove
                                                    onClick={() =>
                                                        setProductsSelected((prevState) => {
                                                            return prevState.filter(
                                                                (selectedProduct) =>
                                                                    selectedProduct.uuid !== product.uuid
                                                            );
                                                        })
                                                    }
                                                />
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={6}>
                                        {'Nenhum produto adicionado'}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className={'mt-8 row'}>
                    <div className={'col-12 totals__container'}>
                        <div className={'totals__wrapper mr-6'}>
                            <div className={'d-flex'}>
                                <Amount
                                    title={'Qtd produtos'}
                                    amount={productsSelected.length}
                                    radius={'12px 0px 0px 12px'}
                                    className={'amount-sale mr-6'}
                                    inline
                                />
                                <Amount
                                    title={'Total a pagar'}
                                    amount={totalSale}
                                    radius={'0px 0px 0px 0px'}
                                    className={'amount-sale'}
                                />
                            </div>
                            <div className={'d-flex mt-6'}>
                                <Amount
                                    title={'Qtd itens'}
                                    amount={totalItens}
                                    radius={'12px 0px 0px 12px'}
                                    className={'amount-sale mr-6'}
                                    inline
                                />
                                <Amount
                                    title={'Troca'}
                                    amount={formatValue(exchangeTotal)}
                                    radius={'0px 0px 0px 0px'}
                                    className={'amount-sale'}
                                />
                            </div>
                        </div>
                        <div className={'totals__sale'}>
                            <p>{'Total da compra'}</p>
                            <span>{totalPayable}</span>
                        </div>
                    </div>
                </div>
            </div>
            <SalesModal
                show={showSaleModal && !uuid}
                onCancel={() => {
                    setShowSaleModal(false);
                    inputSearchRef?.current?.focus();
                }}
                onChangeForm={(form) => {
                    setSalesForm(form);
                }}
                onConfirm={(target) => {
                    updateFormData({ cpfNote: target.formData.cpfNote });
                    updateFormData({ seller: target.formData.seller });
                    setShowSaleModal(false);
                    inputSearchRef?.current?.focus();
                }}
            />

            <SearchProductModal
                show={showModal}
                onCancel={() => setShowModal(false)}
                onSelect={(prod) => {
                    if (prod) {
                        addProduct(prod);
                        setAmount(1);
                        setShowModal(false);
                    }
                }}
            />

            <SalesPaymentModal
                show={showPaymentModal}
                formData={formData}
                setFormData={setFormData}
                amount={productsSelected.reduce(
                    (sum, pro) => sum + Number(pro.total),
                    0
                )}
                emptyPayments={emptyPayments}
                exchangeTotal={exchangeTotal}
                updateFormData={updateFormData}
                addRowPayment={addRowPayment}
                disabled={disable}
                onCancel={() => setShowPaymentModal(false)}
                onConfirm={() => {
                    setShowPaymentModal(false);
                    save(false);
                }}
            />

            <SearchExchangeModal
                show={showExchangeModal}
                onCancel={() => setShowExchangeModal(false)}
                onSelect={(target) => {
                    if (target) {
                        setExchangeTotal(target.total);
                        updateFormData({ exchange: target.uuid });
                        setShowExchangeModal(false);
                    }
                }}
            />
        </>
    );
}

export function getSalesFormRoute(uuid) {
    if (uuid) {
        return `/vendas/${uuid}`;
    } else {
        return '/vendas/novo';
    }
}
