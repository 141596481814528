import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    OrderByNcmEnum,
    RoleEnum
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as NcmService from '../../services/NcmService';
import { getNcmFormRoute } from './NcmForm';

export default function NcmTypes() {
    const [ncm, setNcm] = useState(null);
    const [ncmLoading, setNcmLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderByNcmEnum.NAME_ASC,
        search: null,
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        setWindowTitle,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);

    const canAdd = true;
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    useEffect(() => {
        fetchNcm();
    }, [filter]);

    useEffect(() => {
        setWindowTitle('NCM');
        const f2Hotkey = addHotkey('F2', () => {
            inputSearchRef.current?.focus();
        });

        return () => {
            removeHotkey(f2Hotkey);
            setWindowTitle();
        };
    }, []);

    async function fetchNcm() {
        if (ncmLoading?.cancel) {
            ncmLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setNcmLoading(cancelToken);
            const response = await NcmService.getNcmList(
                filter.page,
                filter.search,
                filter.order,
                cancelToken
            );
            setNcm(response);
            setNcmLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setNcmLoading(e);
                setNcm(null);
            }
        }
    }

    function renderTable() {
        if (ncmLoading && ncmLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar os NCM'}
                    onTryAgain={fetchNcm}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = ncm?.records ?? [];
        for (const s of records) {
            rows.push(
                <tr key={s.uuid} onClick={() => handleRowClick(getNcmFormRoute(s.uuid))}>
                    <td>
                        {s.name}
                    </td>
                    <td>
                        {s.code}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={ncmLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            // I18N
                            name: 'Nome',
                            sortAsc: OrderByNcmEnum.NAME_ASC,
                            sortDesc: OrderByNcmEnum.NAME_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        },
                        {
                            // I18N
                            name: 'Código',
                            sortAsc: OrderByNcmEnum.CODE_ASC,
                            sortDesc: OrderByNcmEnum.CODE_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={ncm?.page}
                    pageSize={ncm?.pageSize}
                    count={ncm?.count}
                    recordCount={ncm?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }



    return (
        <div className={'crud-list'}>
            <ScreenHeader
                // I18N
                title={'NCM'}
                breadcrumbs={[
                    // I18N
                    { name: 'Fiscal', route: getDashboardRoute() },
                    // I18N
                    { name: 'NCM', route: getNcmRoute() },
                ]}
            />
            <div className={'controls'}>
                <div className={'gd'}>
                    <div className={`gd-col ${canAdd ? 'gd-col-9' : 'gd-col-12'}`}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            // I18N
                            label={'<em>Buscar por <strong>NCM</strong> [F2]</em>'}
                            onChange={({ target }) =>
                                setFilter({ ...filter, search: target.value })
                            }
                        />
                    </div>
                    {canAdd && (
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                route={getNcmFormRoute()}
                            >
                                {'Novo NCM'}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <div className={'table-scroll'}>
                {renderTable()}
            </div>
        </div>
    );

}

export function getNcmRoute() {
    //I18N
    return '/fiscal/ncm';
}
