import FieldLiveSearch from './FieldLiveSearch';
import { useRef } from 'react';
import { createCancelTokenSource } from '../util/api';
import * as SalesService from '../services/SalesService';
import { OrderBySalesEnum } from 'erva-doce-common';
import { IconArrowDropdown } from './images';
import { formatValue } from '../util/formatValue';

function FieldSale({
    label,
    onSelected,
    select,
    ...props
}) {
    const cancelTokenSourceRef = useRef(null);
    const liveSearchRef = useRef(null);

    async function fetchValue(uuid) {
        const result = await SalesService.getSale(uuid);        
        return result['records'].length ? result['records'].id : '';
    }

    async function fetch(searchStr, page) {
        cancelTokenSourceRef.current?.cancel();
        cancelTokenSourceRef.current = await createCancelTokenSource();
        const result = await SalesService.getSales(page, searchStr,
            OrderBySalesEnum.DATE_DESC, undefined, undefined, undefined, undefined, cancelTokenSourceRef.current.token);

        return result['records'].map((record) => ({
            id: record['uuid'],
            value: `Número: ${record['id']} | Valor: ${formatValue(record['total'])}`,
        }));
    }

    return (
        <FieldLiveSearch
            ref={liveSearchRef}
            // I18N
            className={'field-sale'}
            label={label && label}
            fetchFn={fetch}
            fetchValueFn={fetchValue}
            onSelected={onSelected}
            select={select}
            hasPagination={false}
            icon={IconArrowDropdown}
            icon2x={IconArrowDropdown}
            {...props}
        />
    );
}

export default FieldSale;