export enum ValidationErrorExchangesEnum {
    EXCHANGE_CLIENT_NAME_ALREADY_IN_USE = "EXCHANGE_CLIENT_NAME_ALREADY_IN_USE",
   
}

export function ValidationErrorExchangesText(validationError: ValidationErrorExchangesEnum): string {
    switch (validationError) {
    case ValidationErrorExchangesEnum.EXCHANGE_CLIENT_NAME_ALREADY_IN_USE:
        // I18N
        return "O nome do cliente já está em uso por outra troca";
    default:
        throw new Error(`Not implemented for ${validationError}`);
    }
}