import React, { useEffect } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import { Controller, useFormContext } from 'react-hook-form';

import FieldDate from '../../components/FieldDate';
import FieldCurrency from '../../components/FieldCurrency';
import FieldPaymentType from '../../components/FieldPaymentType';
import { FieldTextLabelStyle } from '../../components/FieldText';

function ReceiptFormModal({
    open,
    onCancel,
    onConfirm,
    numberOfReceipts,
}) {
    const { control } = useFormContext();

    return (
        <SimpleConfirmModal
            show={open}
            title={'Novo Recebimento'}
            zIndex={6}
            onCancel={onCancel}
            onConfirm={onConfirm}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <Controller
                        name={`receipts.${numberOfReceipts}.deliveryDate`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <FieldDate
                                name={name}
                                onChange={(e) => {
                                    onChange(e.target.value);
                                }}
                                value={value}
                                label={'Data do Recebimento'}
                                required={false}
                            >
                                { error && <span>{ error.message }</span> }
                            </FieldDate>
                        )}
                    />
               
                    <Controller
                        name={`receipts.${numberOfReceipts}.value`}
                        control={control}
                        render={({ field: { name, onChange, value, ref }, fieldState: { error } }) => (
                            <FieldCurrency 
                                name={name}
                                onChange={onChange}
                                value={value}
                                label={'Valor do Recebimento'}
                                ref={ref}
                            >
                                { error && <span>{ error.message }</span> }
                            </FieldCurrency>
                        )}
                    />
                               
                    <Controller
                        name={`receipts.${numberOfReceipts}.receiptType`}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FieldPaymentType
                                label={'Tipo Do Recebimento'}
                                multipleSelection={false}
                                fieldGroup={false}
                                labelStyle={FieldTextLabelStyle.LABEL_ABOVE}
                                onSelected={(e) => {
                                    onChange(e);
                                }}
                                select={value}
                            >
                                { error && <span>{ error.message }</span> }
                            </FieldPaymentType>
                        )}
                    />
                </div>
            </div>
        </SimpleConfirmModal>
    );
}

export default ReceiptFormModal;