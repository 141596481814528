import './OrderDetailHeaderTable.scss';
import { formatValue } from '../../../util/formatValue';
import FieldDate from '../../../components/FieldDate';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { EnvironmentContext } from '../../../contexts/EnviromentContext';
import { OrderStatusEnum, RoleEnum } from 'erva-doce-common';

export default function OrderDetailHeaderTable({ data, updateOrderData, virtualTotal }) {
    const empty = !data;
    const { user } = useContext(EnvironmentContext);
    const canEdit = user.roles?.includes(RoleEnum.PERMISSION_ORDER_EDIT) && [
        OrderStatusEnum.A_CONFERIR,
        OrderStatusEnum.CONFERIDO,
        OrderStatusEnum.NEGOCIACAO,
    ].includes(data.status);
    const isCanceled = data.status === OrderStatusEnum.CANCELADO;

    return (
        <div className={'purchase-detail-table'}>
            <table className={'table'}>
                <thead>
                    <tr>
                        <th className={'text-start'}>{'N° Pedido'}</th>
                        <th className={'text-start'}>{'Fornecedor'}</th>
                        <th>{'Valor'}</th>
                        <th>{'Em andamento'}</th>
                        <th>{'Valor pendência'}</th>
                        <th style={{ minWidth: '200px' }}>
                            {'Previsão faturamento'}
                        </th>
                        <th style={{ minWidth: '200px' }}>
                            {'Previsão entrega'}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {empty ? (
                        <tr>
                            <td colSpan={7} className={'status-container'}>
                                {'Não há dados para exibir'}
                            </td>
                        </tr>
                    ) : (
                        <tr>
                            <td className={'supplier'}>
                                <div>
                                    <p>{`${data.id}`.padStart(7, '0')}</p>
                                    <p>
                                        {DateTime.fromISO(
                                            data.createdAt
                                        ).toFormat('dd/MM/yyyy')}
                                    </p>
                                </div>
                            </td>
                            <td className={'supplier'}>
                                <div>
                                    <p>{data.supplierName}</p>
                                    <p>{data.representativeName}</p>
                                </div>
                            </td>
                            <td className={'text-center'}>
                                {formatValue(virtualTotal || 0)}
                            </td>
                            <td className={'supplier text-center'}>
                                {data.pendingOrdersValue ? (
                                    <div
                                        className={'d-flex align-items-center'}
                                    >
                                        <p>
                                            {formatValue(
                                                data.pendingOrdersValue
                                            )}
                                        </p>
                                        <p>{`${`${data.pendingOrdersQty}`.padStart(
                                            2,
                                            '0'
                                        )} andamento`}</p>
                                    </div>
                                ) : (
                                    <p>{'sem pedido'}</p>
                                )}
                            </td>
                            <td className={'supplier text-center'}>
                                {data.pendingValue ? (
                                    <div
                                        className={'d-flex align-items-center'}
                                    >
                                        <p>{formatValue(data.pendingValue)}</p>
                                        <p>{data.pendingDate}</p>
                                    </div>
                                ) : (
                                    <p>{'sem pendência'}</p>
                                )}
                            </td>
                            <td className={'count-selected text-center'}>
                                {canEdit && !isCanceled ? (
                                    <FieldDate
                                        fieldGroup={false}
                                        onChange={({ target }) =>
                                            updateOrderData({
                                                billingDate: target.value,
                                            })
                                        }
                                        value={data.billingDate || ''}
                                    />
                                ) : (
                                    <>
                                        {data.billingDate
                                            ? DateTime.fromFormat(
                                                data.billingDate,
                                                'yyyy-MM-dd'
                                            ).toFormat('dd/MM/yyyy')
                                            : 'sem previsão'}
                                    </>
                                )}
                            </td>
                            <td className={'count-selected text-center'}>
                                {canEdit && !isCanceled ? (
                                    <FieldDate
                                        fieldGroup={false}
                                        onChange={({ target }) =>
                                            updateOrderData({
                                                deliveryDate: target.value,
                                            })
                                        }
                                        value={data.deliveryDate || ''}
                                    />
                                ) : (
                                    <>
                                        {data.deliveryDate
                                            ? DateTime.fromFormat(
                                                data.deliveryDate,
                                                'yyyy-MM-dd'
                                            ).toFormat('dd/MM/yyyy')
                                            : 'sem previsão'}
                                    </>
                                )}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
