import Button from './Button';
import './Pagination.scss';
import {
    IconPaginationNext,
    IconPaginationNext2x,
    IconPaginationNextDisabled,
    IconPaginationNextDisabled2x,
    IconPaginationPrior,
    IconPaginationPrior2x,
    IconPaginationPriorDisabled,
    IconPaginationPriorDisabled2x
} from './images';

export default function Pagination({
    count,
    page,
    pageSize,
    recordCount,
    onPageChange,
}) {

    let iconPrior, iconPrior2x;
    let iconNext, iconNext2x;

    let start = page != null ? Number(page) * pageSize + 1 : null;
    let end = start && recordCount ? start + recordCount - 1 : null;
    const hasPrior = page ? Number(page) > 0 : false;
    const hasNext = end && count ? end < count : false;

    if (isNaN(end)) start = null;
    if (recordCount === 0) {
        start = 0;
        end = 0;
    } else if (!recordCount) {
        start = null;
        end = null;
    }

    if (hasPrior) {
        iconPrior = IconPaginationPrior;
        iconPrior2x = IconPaginationPrior2x;
    } else {
        iconPrior = IconPaginationPriorDisabled;
        iconPrior2x = IconPaginationPriorDisabled2x;
    }

    if (hasNext) {
        iconNext = IconPaginationNext;
        iconNext2x = IconPaginationNext2x;
    } else {
        iconNext = IconPaginationNextDisabled;
        iconNext2x = IconPaginationNextDisabled2x;
    }

    function changePage(newPage) {
        if (newPage !== page && onPageChange) {
            onPageChange(newPage);
        }
    }

    return (
        <div className={'pagination'}>
            <div className={'control-container'}>
                <Button
                    className={'transparent'}
                    disabled={!hasPrior}
                    onClick={() => changePage(Number(page) - 1)}
                >
                    <img src={iconPrior} srcSet={`${iconPrior} 1x, ${iconPrior2x} 2x`} alt={'<'} />
                </Button>
            </div>
            <div className={'page-info'}>
                {`${start?.toLocaleString() ?? '...'} - ${end?.toLocaleString() ?? '...'} de ${count?.toLocaleString() ?? '...'}`}
            </div>
            <div className={'control-container'}>
                <Button
                    className={'transparent'}
                    disabled={!hasNext}
                    onClick={() => changePage(Number(page) + 1)}
                >
                    <img src={iconNext} srcSet={`${iconNext} 1x, ${iconNext2x} 2x`} alt={'>'} />
                </Button>
            </div>
        </div>
    );
}
