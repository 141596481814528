import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    RoleEnum,
} from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as DivergenceReasonsService from '../../services/DivergenceReasonsService';
import { getDivergenceReasonFormRoute } from './DivergenceReasonForm';

export default function DivergenceReason() {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [filter, setFilter] = useState({
        search: null,
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        setWindowTitle,
        addHotkey,
        removeHotkey,
        user,
    } = useContext(EnvironmentContext);

    const canAdd = user.roles?.includes(RoleEnum.PERMISSION_STOCK_EDIT);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    useEffect(() => {
        fetchData();
    }, [filter]);

    useEffect(() => {
        setWindowTitle('Motivos de divergência');
        const f2Hotkey = addHotkey('F2', () => {
            inputSearchRef.current?.focus();
        });

        return () => {
            removeHotkey(f2Hotkey);
            setWindowTitle();
        };
    }, []);

    async function fetchData() {
        if (isLoading?.cancel) {
            isLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setIsLoading(cancelToken);
            const response = await DivergenceReasonsService.getList(
                filter.page,
                filter.search,
                filter.order,
                cancelToken
            );
            setData(response);
            setIsLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setIsLoading(e);
                setData(null);
            }
        }
    }

    function renderTable() {
        if (isLoading && isLoading instanceof Error) {
            return (
                <LoadError
                    message={'Não foi possível carregar os Motivos de divergência'}
                    onTryAgain={fetchData}
                />
            );
        }

        let rows = [];
        const records = data?.records ?? [];
        for (const s of records) {
            rows.push(
                <tr key={s.uuid} onClick={() => handleRowClick(getDivergenceReasonFormRoute(s.uuid))}>
                    <td>
                        {s.name}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={isLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            name: 'Nome',
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={data?.page}
                    pageSize={data?.pageSize}
                    count={data?.count}
                    recordCount={data?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    return (
        <div className={'crud-list'}>
            <ScreenHeader
                title={'Motivos de divergência'}
                breadcrumbs={[
                    { name: 'Produtos', route: getDashboardRoute() },
                    { name: 'Estoque' },
                    { name: 'Motivos de divergência', route: getDivergenceReasonRoute() },
                ]}
            />
            <div className={'controls'}>
                <div className={'gd'}>
                    <div className={`gd-col ${canAdd ? 'gd-col-9' : 'gd-col-12'}`}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            label={'<em>Buscar por <strong>Motivo de divergência</strong> [F2]</em>'}
                            onChange={({ target }) =>
                                setFilter({ ...filter, search: target.value })
                            }
                        />
                    </div>
                    {canAdd && (
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                route={getDivergenceReasonFormRoute()}
                            >
                                {'Novo Motivo de divergência'}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <div className={'table-scroll'}>
                {renderTable()}
            </div>
        </div>
    );
}

export function getDivergenceReasonRoute() {
    return '/produtos/estoque/motivos-divergencia';
}
