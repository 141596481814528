import Dashboard, { getDashboardRoute } from '../dashboard/Dashboard';
import MainScreen from '../components/logged/MainScreen';
import Collaborators, { getCollaboratorsRoute } from './collaborators/Collaborators';
import CollaboratorForm, { getCollaboratorFormRoute } from './collaborators/CollaboratorForm';
import Representatives, { getRepresentativesRoute } from './representatives/Representatives';
import RepresentativeForm, { getRepresentativeFormRoute } from './representatives/RepresentativeForm';
import Suppliers, { getSuppliersRoute } from './suppliers/Suppliers';
import SupplierForm, { getSupplierFormRoute } from './suppliers/SupplierForm';
import Stores, { getStoresRoute } from './stores/Stores';
import StoreForm, { getStoresFormRoute } from './stores/StoreForm';
import Segmentations, { getSegmentationsRoute } from './segmentations/Segmentations';
import SegmentationForm, { getSegmentationFormRoute } from './segmentations/SegmentationForm';
import React, { useContext } from 'react';
import RouteErrorPage from '../components/RouteErrorPage';
import { Navigate, Outlet, useRouteError } from 'react-router-dom';
import StoresTypes, { getStoresTypesRoute } from './stores-types/StoresTypes';
import StoreTypeForm, { getStoreTypeFormRoute } from './stores-types/StoreTypeForm';
import JobsTitles, { getJobsTitlesRoute } from './jobs-titles/JobsTitles';
import JobTitleForm, { getJobTitleFormRoute } from './jobs-titles/JobTitleForm';
import ExpensesTypes, { getExpensesTypesRoute } from './expenses-types/ExpensesTypes';
import ExpenseTypeForm, { getExpenseTypeFormRoute } from './expenses-types/ExpenseTypeForm';
import QuotationsTypes, { getQuotationsTypesRoute } from './quotations-types/QuotationsTypes';
import QuotationTypeForm, { getQuotationTypeFormRoute } from './quotations-types/QuotationTypeForm';
import ProductsFamilies, { getProductFamiliesRoute } from './products-families/ProductsFamilies';
import ProductFamilyForm, { getProductFamilyFormRoute } from './products-families/ProductFamilyForm';
import TaxesSituations, { getTaxesSituationsRoute } from './taxes-situations/TaxesSituations';
import TaxSituationForm, { getTaxSituationFormRoute } from './taxes-situations/TaxSituationForm';
import TaxesClassifications, { getTaxesClassificationsRoute } from './taxes-classifications/TaxesClassifications';
import TaxClassificationForm, { getTaxClassificationFormRoute } from './taxes-classifications/TaxClassificationForm';
import ProductsCategories, { getProductsCategoriesRoute } from './products-categories/ProductsCategories';
import ProductCategoryForm, { getProductsCategoriesFormRoute } from './products-categories/ProductCategoryForm';
import Cfop, { getCfopRoute } from './cfop/Cfop';
import CfopForm, { getCfopFormRoute } from './cfop/CfopForm';
import ProductsBrands, { getProductsBrandsRoute } from './products-brands/ProductsBrands';
import ProductBrandForm, { getProductBrandFormRoute } from './products-brands/ProductBrandForm';
import Icms, { getIcmsRoute } from './icms/Icms';
import IcmsForm, { getIcmsFormRoute } from './icms/IcmsForm';
import Products, { getProductsRoute } from './products/Products';
import ProductForm, { getProductFormRoute } from './products/ProductsForm';
import Exchanges, { getExchangesRoute } from './exchanges/Exchanges';
import ExchangesForm, { getExchangesFormRoute } from './exchanges/ExchangesForm';
import Sales, { getSalesRoute } from './sales/Sales';
import SalesForm, { getSalesFormRoute } from './sales/SalesForm';
import BillsToTeceive, { getBillsToReceiveRoute } from './bills-to-receive/BillsToReceive';
import BillsToReceiveForm, { getBillsToReceiveFormRoute } from './bills-to-receive/BilsToReceiveForm';
import PaymentsTypes, { getPaymentsTypesRoute } from './payments-types/PaymentsTypes';
import PaymentTypeForm, { getPaymentTypeFormRoute } from './payments-types/PaymentTypeForm';
import MachineForm, { getMachineFormRoute } from './machines/MachineForm';
import Machines, { getMachinesRoute } from './machines/Machines';
import PurchaseSuggestion, { getPurchaseSuggestionRoute } from './purchase-suggestion/PurchaseSuggestion';
import ProductLogs, { getProductLogsRoute } from './products/ProductLogs';
import Tags, { getTagsRoute } from './tags/Tags';
import Orders, { getOrdersRoute } from './order/Orders';
import SuggestionDetail, { getSuggestionDetailRoute } from './purchase-suggestion/SuggestionDetail';
import OrderDetail, { getOrderDetailRoute } from './order/order-detail/OrderDetail';
import CancelReason, { getCancelReasonRoute } from './cancel-reason/CancelReason';
import CancelReasonForm, { getCancelReasonFormRoute } from './cancel-reason/CancelReasonForm';
import { RoleEnum } from 'erva-doce-common';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import OrderList, { getOrderListRoute } from './order-list/OrderList';
import OrderBilling, { getOrderBillingRoute } from './order/order-billing/OrderBilling';
import OrderDocuments, { getOrderDocumentsRoute } from './order-documents/OrderDocuments';
import Stock, { getStockRoute } from './stock/Stock';
import StockBulk, { getStockBulkRoute } from './stock/StockBulk';
import StockProductLog, { getStockProductLogRoute } from './stock/StockProductLog';
import StockCheck, { getStockCheckRoute } from './stock/stock-check/StockCheck';
import AdminStockCheck, { getAdminStockCheckRoute } from './stock/admin-stock-check/AdminStockCheck';
import StockCheckForm, { getStockCheckFormRoute } from './stock/stock-check/StockCheckForm';
import AdminStockCheckDetail, { getAdminStockCheckDetailRoute } from './stock/admin-stock-check/AdminStockCheckDetail';
import StockCheckDetail, { getStockCheckDetailRoute } from './stock/stock-check/StockCheckDetail';
import DivergenceReason, { getDivergenceReasonRoute } from './divergence-reason/DivergenceReason';
import DivergenceReasonForm, { getDivergenceReasonFormRoute } from './divergence-reason/DivergenceReasonForm';
import Customers, { getCustomersRoute } from './customers/Customers';
import Ncm, { getNcmRoute } from './ncm/Ncm';
import NcmForm, { getNcmFormRoute } from './ncm/NcmForm';
import DeliveryArea, { getDeliveryAreaRoute } from './loja-virtual/areas-de-entrega';
import DeliveryAreaForm, { getDeliveryAreaFormRoute } from './loja-virtual/areas-de-entrega/DeliveryAreaForm';
import Banners, { getBannersRoute } from './loja-virtual/banners';
import Parametros, { getParametrosRoute } from './loja-virtual/parametros';
import MenuPrincipal, { getMenuPrincipalRoute } from './loja-virtual/menu-principal';
import Rodape, { getRodapeRoute } from './loja-virtual/rodape';

function HandleError() {
    return <RouteErrorPage error={useRouteError()}/>;
}

function RoleGuard({ role, children }) {
    const { user } = useContext(EnvironmentContext);
    const isAllowed = user.roles?.includes(role);

    return isAllowed ? children : <Navigate to={getDashboardRoute()} />;
}

const adminRoutes = [
    {
        path: getDashboardRoute(),
        element: (
            <MainScreen>
                <Dashboard />
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getCollaboratorsRoute(),
        element: (
            <MainScreen>
                <Collaborators />
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getCollaboratorFormRoute(),
        element: (
            <MainScreen>
                <CollaboratorForm />
            </MainScreen>
        ),
    },
    {
        path: getCollaboratorFormRoute(':uuid'),
        element: (
            <MainScreen>
                <CollaboratorForm />
            </MainScreen>
        ),
    },
    {
        path: getRepresentativesRoute(),
        element: (
            <MainScreen>
                <Representatives />
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getRepresentativeFormRoute(),
        element: (
            <MainScreen>
                <RepresentativeForm />
            </MainScreen>
        ),
    },
    {
        path: getRepresentativeFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RepresentativeForm />
            </MainScreen>
        ),
    },
    {
        path: getSuppliersRoute(),
        element: (
            <MainScreen>
                <Suppliers />
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getSupplierFormRoute(),
        element: (
            <MainScreen>
                <SupplierForm />
            </MainScreen>
        ),
    },
    {
        path: getSupplierFormRoute(':uuid'),
        element: (
            <MainScreen>
                <SupplierForm />
            </MainScreen>
        ),
    },
    {
        path: getStoresRoute(),
        element: (
            <MainScreen>
                <Stores />
            </MainScreen>
        ),
        errorElement: <HandleError />,
    },
    {
        path: getStoresFormRoute(),
        element: (
            <MainScreen>
                <StoreForm />
            </MainScreen>
        ),
    },
    {
        path: getStoresFormRoute(':uuid'),
        element: (
            <MainScreen>
                <StoreForm />
            </MainScreen>
        ),
    },
    {
        path: getSegmentationsRoute(),
        element: (
            <MainScreen>
                <Segmentations />
            </MainScreen>
        ),
    },
    {
        path: getSegmentationFormRoute(),
        element: (
            <MainScreen>
                <SegmentationForm />
            </MainScreen>
        ),
    },
    {
        path: getSegmentationFormRoute(':uuid'),
        element: (
            <MainScreen>
                <SegmentationForm />
            </MainScreen>
        ),
    },
    {
        path: getStoresTypesRoute(),
        element: (
            <MainScreen>
                <StoresTypes />
            </MainScreen>
        ),
    },
    {
        path: getStoreTypeFormRoute(),
        element: (
            <MainScreen>
                <StoreTypeForm />
            </MainScreen>
        ),
    },
    {
        path: getStoreTypeFormRoute(':uuid'),
        element: (
            <MainScreen>
                <StoreTypeForm />
            </MainScreen>
        ),
    },
    {
        path: getJobsTitlesRoute(),
        element: (
            <MainScreen>
                <JobsTitles />
            </MainScreen>
        ),
    },
    {
        path: getJobTitleFormRoute(),
        element: (
            <MainScreen>
                <JobTitleForm />
            </MainScreen>
        ),
    },
    {
        path: getJobTitleFormRoute(':uuid'),
        element: (
            <MainScreen>
                <JobTitleForm />
            </MainScreen>
        ),
    },
    {
        path: getExpensesTypesRoute(),
        element: (
            <MainScreen>
                <ExpensesTypes />
            </MainScreen>
        ),
    },
    {
        path: getExpenseTypeFormRoute(),
        element: (
            <MainScreen>
                <ExpenseTypeForm />
            </MainScreen>
        ),
    },
    {
        path: getExpenseTypeFormRoute(':uuid'),
        element: (
            <MainScreen>
                <ExpenseTypeForm />
            </MainScreen>
        ),
    },
    {
        path: getQuotationsTypesRoute(),
        element: (
            <MainScreen>
                <QuotationsTypes />
            </MainScreen>
        ),
    },
    {
        path: getQuotationTypeFormRoute(),
        element: (
            <MainScreen>
                <QuotationTypeForm />
            </MainScreen>
        ),
    },
    {
        path: getQuotationTypeFormRoute(':uuid'),
        element: (
            <MainScreen>
                <QuotationTypeForm />
            </MainScreen>
        ),
    },
    {
        path: getProductFamiliesRoute(),
        element: (
            <MainScreen>
                <ProductsFamilies />
            </MainScreen>
        ),
    },
    {
        path: getProductFamilyFormRoute(),
        element: (
            <MainScreen>
                <ProductFamilyForm />
            </MainScreen>
        ),
    },
    {
        path: getProductFamilyFormRoute(':uuid'),
        element: (
            <MainScreen>
                <ProductFamilyForm />
            </MainScreen>
        ),
    },
    {
        path: getTaxesSituationsRoute(),
        element: (
            <MainScreen>
                <TaxesSituations />
            </MainScreen>
        ),
    },
    {
        path: getTaxSituationFormRoute(),
        element: (
            <MainScreen>
                <TaxSituationForm />
            </MainScreen>
        ),
    },
    {
        path: getTaxSituationFormRoute(':uuid'),
        element: (
            <MainScreen>
                <TaxSituationForm />
            </MainScreen>
        ),
    },
    {
        path: getTaxesClassificationsRoute(),
        element: (
            <MainScreen>
                <TaxesClassifications />
            </MainScreen>
        ),
    },
    {
        path: getTaxClassificationFormRoute(),
        element: (
            <MainScreen>
                <TaxClassificationForm />
            </MainScreen>
        ),
    },
    {
        path: getTaxClassificationFormRoute(':uuid'),
        element: (
            <MainScreen>
                <TaxClassificationForm />
            </MainScreen>
        ),
    },
    {
        path: getProductsCategoriesRoute(),
        element: (
            <MainScreen>
                <ProductsCategories />
            </MainScreen>
        ),
    },
    {
        path: getProductsCategoriesFormRoute(),
        element: (
            <MainScreen>
                <ProductCategoryForm />
            </MainScreen>
        ),
    },
    {
        path: getProductsCategoriesFormRoute(':uuid'),
        element: (
            <MainScreen>
                <ProductCategoryForm />
            </MainScreen>
        ),
    },
    {
        path: getCfopRoute(),
        element: (
            <MainScreen>
                <Cfop />
            </MainScreen>
        ),
    },
    {
        path: getCfopFormRoute(),
        element: (
            <MainScreen>
                <CfopForm />
            </MainScreen>
        ),
    },
    {
        path: getCfopFormRoute(':uuid'),
        element: (
            <MainScreen>
                <CfopForm />
            </MainScreen>
        ),
    },
    {
        path: getNcmRoute(),
        element: (
            <MainScreen>
                <Ncm />
            </MainScreen>
        ),
    },
    {
        path: getNcmFormRoute(),
        element: (
            <MainScreen>
                <NcmForm />
            </MainScreen>
        ),
    },
    {
        path: getNcmFormRoute(':uuid'),
        element: (
            <MainScreen>
                <NcmForm />
            </MainScreen>
        ),
    },
    {
        path: getProductsBrandsRoute(),
        element: (
            <MainScreen>
                <ProductsBrands />
            </MainScreen>
        ),
    },
    {
        path: getProductBrandFormRoute(),
        element: (
            <MainScreen>
                <ProductBrandForm />
            </MainScreen>
        ),
    },
    {
        path: getProductBrandFormRoute(':uuid'),
        element: (
            <MainScreen>
                <ProductBrandForm />
            </MainScreen>
        ),
    },
    {
        path: getIcmsRoute(),
        element: (
            <MainScreen>
                <Icms />
            </MainScreen>
        ),
    },
    {
        path: getIcmsFormRoute(),
        element: (
            <MainScreen>
                <IcmsForm />
            </MainScreen>
        ),
    },
    {
        path: getIcmsFormRoute(':uuid'),
        element: (
            <MainScreen>
                <IcmsForm />
            </MainScreen>
        ),
    },
    {
        path: getProductsRoute(),
        element: (
            <MainScreen>
                <Products />
            </MainScreen>
        ),
    },
    {
        path: getProductFormRoute(),
        element: (
            <MainScreen className={'product-main-screen'}>
                <ProductForm />
            </MainScreen>
        ),
    },
    {
        path: getProductFormRoute(':uuid'),
        element: (
            <MainScreen className={'product-main-screen'}>
                <ProductForm />
            </MainScreen>
        ),
    },
    {
        path: getExchangesRoute(),
        element: (
            <MainScreen>
                <Exchanges />
            </MainScreen>
        ),
    },
    {
        path: getExchangesFormRoute(),
        element: (
            <MainScreen>
                <ExchangesForm />
            </MainScreen>
        ),
    },
    {
        path: getExchangesFormRoute(':uuid'),
        element: (
            <MainScreen>
                <ExchangesForm />
            </MainScreen>
        ),
    },
    {
        path: getSalesRoute(),
        element: (
            <MainScreen>
                <Sales />
            </MainScreen>
        ),
    },
    {
        path: getSalesFormRoute(),
        element: (
            <MainScreen>
                <SalesForm />
            </MainScreen>
        ),
    },
    {
        path: getSalesFormRoute(':uuid'),
        element: (
            <MainScreen>
                <SalesForm />
            </MainScreen>
        ),
    },
    {
        path: getBillsToReceiveRoute(),
        element: (
            <MainScreen>
                <BillsToTeceive />
            </MainScreen>
        ),
    },
    {
        path: getBillsToReceiveFormRoute(),
        element: (
            <MainScreen>
                <BillsToReceiveForm />
            </MainScreen>
        ),
    },
    {
        path: getBillsToReceiveFormRoute(':uuid'),
        element: (
            <MainScreen>
                <BillsToReceiveForm />
            </MainScreen>
        ),
    },
    {
        path: getPaymentsTypesRoute(),
        element: (
            <MainScreen>
                <PaymentsTypes />
            </MainScreen>
        ),
    },
    {
        path: getPaymentTypeFormRoute(),
        element: (
            <MainScreen>
                <PaymentTypeForm />
            </MainScreen>
        ),
    },
    {
        path: getPaymentTypeFormRoute(':uuid'),
        element: (
            <MainScreen>
                <PaymentTypeForm />
            </MainScreen>
        ),
    },
    {
        path: getMachinesRoute(),
        element: (
            <MainScreen>
                <Machines />
            </MainScreen>
        ),
    },
    {
        path: getMachineFormRoute(),
        element: (
            <MainScreen>
                <MachineForm />
            </MainScreen>
        ),
    },
    {
        path: getMachineFormRoute(':uuid'),
        element: (
            <MainScreen>
                <MachineForm />
            </MainScreen>
        ),
    },
    {
        path: getPurchaseSuggestionRoute(),
        element: (
            <MainScreen>
                <RoleGuard
                    role={RoleEnum.PERMISSION_PURCHASE_SUGGESTION_VISUALIZE}
                >
                    <PurchaseSuggestion />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getSuggestionDetailRoute(':id'),
        element: (
            <MainScreen>
                <RoleGuard
                    role={RoleEnum.PERMISSION_PURCHASE_SUGGESTION_VISUALIZE}
                >
                    <SuggestionDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getProductLogsRoute(':uuid'),
        element: (
            <MainScreen>
                <ProductLogs />
            </MainScreen>
        ),
    },
    {
        path: getOrdersRoute(),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_ORDER_VISUALIZE}>
                    <Orders />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderDetailRoute(':id'),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_ORDER_VISUALIZE}>
                    <OrderDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getTagsRoute(),
        element: (
            <MainScreen>
                <Tags />
            </MainScreen>
        ),
    },
    {
        path: getCancelReasonRoute(),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_CANCEL_REASON_VISUALIZE}>
                    <CancelReason />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCancelReasonFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_CANCEL_REASON_ADD}>
                    <CancelReasonForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCancelReasonFormRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_CANCEL_REASON_VISUALIZE}>
                    <CancelReasonForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderListRoute(),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_ORDER_VISUALIZE}>
                    <OrderList />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderBillingRoute(),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_ORDER_EDIT}>
                    <OrderBilling />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderBillingRoute(':orderId', ':billingId'),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_ORDER_EDIT}>
                    <OrderBilling />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getOrderDocumentsRoute(),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_ORDER_EDIT}>
                    <OrderDocuments />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockRoute(),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_STOCK_EDIT}>
                    <Stock />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockBulkRoute(),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_STOCK_EDIT}>
                    <StockBulk />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockProductLogRoute(':uuid'),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_STOCK_EDIT}>
                    <StockProductLog />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockCheckRoute(),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_STOCK_EDIT}>
                    <StockCheck />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getAdminStockCheckRoute(),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_STOCK_EDIT}>
                    <AdminStockCheck />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockCheckFormRoute(':id', ':itemUUID'),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_STOCK_EDIT}>
                    <StockCheckForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getAdminStockCheckDetailRoute(':id'),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_STOCK_EDIT}>
                    <AdminStockCheckDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getStockCheckDetailRoute(':id'),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_STOCK_EDIT}>
                    <StockCheckDetail />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getDivergenceReasonRoute(),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_STOCK_EDIT}>
                    <DivergenceReason />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getDivergenceReasonFormRoute(),
        element: (
            <MainScreen>
                <RoleGuard role={RoleEnum.PERMISSION_STOCK_ADD}>
                    <DivergenceReasonForm />
                </RoleGuard>
            </MainScreen>
        ),
    },
    {
        path: getCustomersRoute(),
        element: (
            <MainScreen>
                {/* <RoleGuard role={RoleEnum.PERMISSION_STOCK_ADD}> */}
                <Customers />
                {/* </RoleGuard> */}
            </MainScreen>
        ),
    },
    {
        path: getDeliveryAreaRoute(),
        element: (
            <MainScreen>
                {/* <RoleGuard role={RoleEnum.PERMISSION_STOCK_ADD}> */}
                <DeliveryArea />
                {/* </RoleGuard> */}
            </MainScreen>
        ),
    },
    {
        path: getDeliveryAreaFormRoute(':uuid'),
        element: (
            <MainScreen>
                {/* <RoleGuard role={RoleEnum.PERMISSION_STOCK_ADD}> */}
                <DeliveryAreaForm />
                {/* </RoleGuard> */}
            </MainScreen>
        ),
    },
    {
        path: getBannersRoute(),
        element: (
            <MainScreen>
                {/* <RoleGuard role={RoleEnum.PERMISSION_STOCK_ADD}> */}
                <Banners />
                {/* </RoleGuard> */}
            </MainScreen>
        ),
    },
    {
        path: getParametrosRoute(),
        element: (
            <MainScreen>
                {/* <RoleGuard role={RoleEnum.PERMISSION_STOCK_ADD}> */}
                <Parametros />
                {/* </RoleGuard> */}
            </MainScreen>
        ),
    },
    {
        path: getMenuPrincipalRoute(),
        element: (
            <MainScreen>
                {/* <RoleGuard role={RoleEnum.PERMISSION_STOCK_ADD}> */}
                <MenuPrincipal />
                {/* </RoleGuard> */}
            </MainScreen>
        ),
    },
    {
        path: getRodapeRoute(),
        element: (
            <MainScreen>
                {/* <RoleGuard role={RoleEnum.PERMISSION_STOCK_ADD}> */}
                <Rodape />
                {/* </RoleGuard> */}
            </MainScreen>
        ),
    },
];

export default adminRoutes;
