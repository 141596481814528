/* eslint-disable indent */
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { ExchangeStatusEnum, ExchangeStatusText, OrderByExchangesEnum } from 'erva-doce-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { IconAdd, IconAdd2x, IconExchangeStatusAwaitingRescue, IconExchangeStatusInEdit, IconExchangeStatusRescued, IconFilter, IconFilter2x } from '../../components/images';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as ExchangesService from '../../services/ExchangesService';
import ExchangesFilterModal from './ExchangesFilterModal';
import { getExchangesFormRoute } from './ExchangesForm';

export default function Exchanges() {
    const [exchanges, setExchanges] = useState(null);
    const [exchangesLoading, setExchangesLoading] = useState(null);
    const [filter, setFilter] = useState({
        order: OrderByExchangesEnum.STATUS_DESC,
        search: null,
        page: 0
    });
    const inputSearchRef = useRef();
    const {
        setWindowTitle,
        addHotkey,
        removeHotkey,
    } = useContext(EnvironmentContext);
    const [showFilter, setShowFilter] = useState(false);

    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);


    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchExchanges();
    }, [filter]);

    useEffect(() => {
        // I18N
        setWindowTitle('Trocas');
        const f2Hotkey = addHotkey('F2', () => {
            inputSearchRef.current?.focus();
        });

        return () => {
            removeHotkey(f2Hotkey);
            setWindowTitle();
        };
    }, []);

    async function fetchExchanges() {
        if (exchangesLoading?.cancel) {
            exchangesLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setExchangesLoading(cancelToken);
            const response = await ExchangesService.getExchanges(filter.page, filter.search, filter.order,
                cancelToken);
            setExchanges(response);
            setExchangesLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setExchangesLoading(e);
                setExchanges(null);
            }
        }
    }

    function verifyStatus(status) {
        switch (status) {
            case ExchangeStatusEnum.IN_EDIT:
                return IconExchangeStatusInEdit;
            case ExchangeStatusEnum.AWAITING_RESCUE:
                return IconExchangeStatusAwaitingRescue;
            case ExchangeStatusEnum.RESCUED:
                return IconExchangeStatusRescued;
        }
    }

    function renderTable() {
        if (exchangesLoading && exchangesLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar as trocas'}
                    onTryAgain={fetchExchanges}
                />
            );
        }

        let rows = [];
        // noinspection JSUnresolvedReference
        const records = exchanges?.records ?? [];
        for (const e of records) {
            rows.push(
                <tr key={e.uuid} onClick={() => handleRowClick(getExchangesFormRoute(e.uuid))}>
                    <td className={'center'}>
                        <img src={verifyStatus(e.status)}></img>
                    </td>
                    <td>
                        {e.number}
                    </td>
                    <td>
                        {new Date(e.createdAt).toLocaleDateString('pt-br')}
                    </td>
                    <td>
                        {e.clientName}
                    </td>
                    <td>
                        {e.store}
                    </td>
                    <td>
                        {e.total?.toLocaleString(undefined, { style: 'currency', currency: 'BRL', })}
                    </td>
                    <td>
                        {ExchangeStatusText(e.status)}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={exchangesLoading}
                    currentSort={filter.order}
                    columns={[
                        {}, // icon status
                        {
                            // I18N
                            name: 'Nº Troca',
                        },
                        {
                            // I18N
                            name: 'Data',
                            sortAsc: OrderByExchangesEnum.DATE_ASC,
                            sortDesc: OrderByExchangesEnum.DATE_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        },
                        {
                            // I18N
                            name: 'Nome do cliente',
                        },
                        {
                            // I18N
                            name: 'Loja',

                        },
                        {
                            // I18N
                            name: 'Valor',
                        },
                        {
                            // I18N
                            name: 'Status',
                            sortAsc: OrderByExchangesEnum.STATUS_ASC,
                            sortDesc: OrderByExchangesEnum.STATUS_DESC,
                            onSortChange: (order) => setFilter({ ...filter, order })
                        },
                    ]}>
                    {rows}
                </Table>
                <Pagination
                    page={exchanges?.page}
                    pageSize={exchanges?.pageSize}
                    count={exchanges?.count}
                    recordCount={exchanges?.records?.length}
                    onPageChange={page => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    // I18N
                    title={'Trocas'}
                    breadcrumbs={[
                        // I18N
                        { name: 'Vendas', route: getDashboardRoute() },
                        // I18N
                        { name: 'Trocas', route: getExchangesRoute() },
                    ]}
                />
                <div className={'controls'}>
                    <div className={'gd'}>
                        <div className={'gd-col gd-col-6'}>
                            <FieldTextSearch
                                ref={inputSearchRef}
                                // I18N
                                label={'<em>Buscar por <strong>Nome ou Nº Troca</strong> [F2]</em>'}
                                onChange={({ target }) =>
                                    setFilter({ ...filter, search: target.value })
                                }
                            />
                        </div>
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                icon={IconAdd}
                                icon2x={IconAdd2x}
                                route={getExchangesFormRoute()}
                            >
                                {'Nova troca'}
                            </Button>
                        </div>
                        <div className={'gd-col gd-col-3'}>
                            <Button
                                buttonStyle={ButtonStyle.BUTTON_SHADOW}
                                color={ButtonColor.BUTTON_COLOR_ORANGE}
                                icon={IconFilter}
                                icon2x={IconFilter2x}
                                onClick={() => setShowFilter(true)}
                            >
                                {'Filtrar'}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={'table-scroll'}>
                    {renderTable()}
                </div>
            </div>
            <ExchangesFilterModal
                show={showFilter}
                onCancel={() => setShowFilter(false)}
                filter={filter}
                onConfirm={(complementaryFilter) => {
                    setShowFilter(false);
                    // noinspection JSCheckFunctionSignatures
                    setFilter({
                        ...filter,
                        ...complementaryFilter,
                    });
                }}
            />
        </>
    );
}

export function getExchangesRoute() {
    //I18N
    return '/vendas/trocas';
}