import Table from '../../components/Table';
import { useEffect, useState } from 'react';
import ButtonGoto from '../../components/ButtonGoto';
import ButtonRemove from '../../components/ButtonRemove';
import SubCategoryModal from './SubCategoryModal';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';

function SubCategoryRow({
    value,
    onRemove,
    onConfirm,
    subCategories,
    readOnly,
}) {
    const [ subCategory, setSubCategory ] = useState({
        ...value,
        minimumOrder: value.minimumOrder ?? '',
    });
    const [ showEditModal, setShowEditModal ] = useState(false);

    function updateSubCategory(subCategory) {
        setSubCategory(subCategory);
        if (onConfirm) onConfirm(subCategory);
    }

    return (
        <>
            <tr>
                <td>{ subCategory.name }</td>
                <td className={'controls'}>
                    { !readOnly && (
                        <>
                            <ButtonRemove
                                onClick={onRemove}
                            />
                            <SimpleConfirmModal
                                show={false}
                                onCancel={null}
                                onConfirm={confirm}
                                // I18N
                                title={'Tem certeza que deseja remover a subcategoria?'}
                            >

                            </SimpleConfirmModal>
                            <ButtonGoto
                                onClick={() => setShowEditModal(true)}
                            />
                            <SubCategoryModal
                                show={showEditModal}
                                subCategory={value}
                                subCategories={subCategories}
                                onCancel={() => setShowEditModal(false)}
                                onConfirm={(subCategory) => {
                                    updateSubCategory(subCategory);
                                    setShowEditModal(false);
                                }}
                            />
                        </>
                    ) }
                </td>
            </tr>
        </>
    );
}

function SubCategories({
    value = [],
    onChange,
    readOnly,
}) {
    const [ subCategories, setSubCategories ] = useState(value);

    function updateSubCategories(subCategories) {
        setSubCategories(subCategories);
        if (onChange) onChange(subCategories);
    }

    function renderTable() {
        const rows = [];
        subCategories.forEach((subCategory, i) => {
            rows.push((
                <SubCategoryRow
                    key={subCategory.uuid || subCategory.name}
                    value={subCategory}
                    onRemove={() => updateSubCategories(subCategories.filter((s) => s.uuid !== subCategory.uuid))}
                    onConfirm={(data) => {
                        subCategories[i] = data;
                        updateSubCategories(subCategories);
                    }}
                    subCategories={subCategories}
                    readOnly={readOnly}
                />
            ));
        });
        return rows;
    }

    useEffect(() => {
        updateSubCategories(value);
    }, [ value ]);

    return (
        <>
            <div className={'gd'} style={{ paddingTop: '20px' }}>
                <div className={'gd-col-12'}>

                    <Table
                        columns={[
                            {
                                // I18N
                                name: 'Nome',
                            },
                            {}, // controls
                        ]}
                    >
                        { renderTable() }
                    </Table>
                </div>
            </div>
        </>
    );

}

export default SubCategories;