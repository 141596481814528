import { useEffect, useState, useRef } from 'react';
import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import FieldTextSearch from '../../components/FieldTextSearch';
import Table from '../../components/Table';
import './ReprintModal.scss';
import Section from '../../components/Section';

export default function ReprintModal({ 
    showModal,
    onCloseModal
}) {
    const inputSearchRef = useRef();
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    return (
        <SimpleConfirmModal
            title={'Reimpressão de etiquetas'}
            show={showModal}
            confirmText={'Imprimir'}
            onConfirm={() => {}}
            cancelText={'Cancelar'}
            onCancel={() => onCloseModal(false)}
            className={'reprint-modal'}
        >
            <fieldset>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-12'}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            // I18N
                            label={'<em>Buscar <strong>produto</strong></em>'}
                            onChange={({ target }) => {}}
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-12'}>
                        <Table
                            columns={[
                                { name: '' },
                                { name: 'Cod. Barras' },
                                { name: 'Nome produto' },
                                { name: 'Preço' },
                                { name: 'Etiqueta' }                            
                            ]}
                        >
                        
                        </Table>
                    </div>
                </div>
            </fieldset>
            <Section title={'Produtos selecionados'}>
    
                <div className={'gd'}>
                    <div className={'gd-col gd-col-12'}>
                        <Table
                            columns={[
                                { name: '' },
                                { name: 'Cod. Barras' },
                                { name: 'Nome produto' },
                                { name: 'Preço' },
                                { name: 'Etiqueta' }                            
                            ]}
                        >
                        
                        </Table>
                    </div>
                </div>
            </Section>
        </SimpleConfirmModal>
    );
}