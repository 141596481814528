import * as Checkbox from '@radix-ui/react-checkbox';
import * as Tabs from '@radix-ui/react-tabs';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router';
import ButtonGoto from '../../components/ButtonGoto';
import FieldDate from '../../components/FieldDate';
import FieldPaymentType from '../../components/FieldPaymentType';
import { FieldTextLabelStyle } from '../../components/FieldText';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Pills from '../../components/Pills';
import Table from '../../components/Table';
import { IconRoundedCheckBlue, IconRoundedCheckGray } from '../../components/images/Icons';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { getBillsToReceiveList } from '../../services/BillsToReceiveService';
import { formatValue } from '../../util/formatValue';
import styles from './BillsToReceive.module.scss';
import { getBillsToReceiveFormRoute } from './BilsToReceiveForm';

function BillsToReceive() {
    const [billsToReceiveLoading, setBillsToReceiveLoading] = useState(false);
    const [haveFetchBillsToReceiveLoadingError, setHaveFetchBillsToReceiveLoadingError] = useState(false);
    const [billsToReceive, setBillsToReceive] = useState([]);

    const inputSearchRef = useRef();
    const inputInitialEmissionDateRef = useRef();
    const inputFinalEmissionDateRef = useRef();
    const inputInitialDueDateRef = useRef();
    const inputFinalDueDate = useRef();

    const [filtersBillsToReceive, setFiltersBillsToReceive] = useState({
        page: 0,
        salesNumber: undefined,
        paymentTypes: null,
        billsTypes: ['forecast', 'received'],
        initialEmissionDate: undefined,
        finalEmissionDate: undefined,
        initialDueDate: undefined,
        finalDueDate: undefined,
    });

    const [paymentTypes, setPaymentTypes] = useState([]);
    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    const fetchBillsToReceive = useCallback(async () => {
        try {
            setBillsToReceiveLoading(true);
            const cancelToken = axios.CancelToken.source().token;

            if (filtersBillsToReceive.forecastBills === true) {

            }

            const response = await getBillsToReceiveList(
                filtersBillsToReceive.page,
                filtersBillsToReceive.finalDueDate,
                filtersBillsToReceive.finalEmissionDate,
                filtersBillsToReceive.initialDueDate,
                filtersBillsToReceive.initialEmissionDate,
                filtersBillsToReceive.paymentTypes,
                filtersBillsToReceive.salesNumber,
                filtersBillsToReceive.billsTypes,
                cancelToken
            );

            setBillsToReceive(response);
        } catch (e) {
            setHaveFetchBillsToReceiveLoadingError(true);

            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
            }
        } finally {
            setBillsToReceiveLoading(false);
        }
    }, [filtersBillsToReceive]);

    useEffect(() => {
        fetchBillsToReceive();
    }, [fetchBillsToReceive]);

    const changeBillsTypeFilter = useCallback((billType, checked) => {
        const findFilter = filtersBillsToReceive.billsTypes.find(item => item === billType);

        setFiltersBillsToReceive((prevState) => {
            if (findFilter && checked) {
                return {
                    ...prevState
                };
            } else if (!findFilter && checked) {
                return {
                    ...prevState,
                    billsTypes: [...prevState.billsTypes, billType]
                };
            } else if (prevState.billsTypes.length === 1 && checked === false) {
                return {
                    ...prevState,
                    billsTypes: ['received']
                };
            } else if (findFilter && checked === false) {
                return {
                    ...prevState,
                    billsTypes: prevState.billsTypes.filter(item => item !== billType)
                };
            }
        });

    }, [filtersBillsToReceive]);

    return haveFetchBillsToReceiveLoadingError ? (
        <LoadError
            // I18N
            message={'Não foi possível carregar as faturas'}
            onTryAgain={fetchBillsToReceive}
        />
    ) : (
        <div className={'crud-list'}>
            <ScreenHeader
                title={'Contas a Receber'}
                breadcrumbs={[
                    { name: 'Financeiro', route: '/' },
                    { name: 'Contas a Receber', route: getBillsToReceiveRoute() },
                ]}
            />

            <div className={'controls'} style={{
                marginBottom: 0
            }}>
                <div className={'gd'}>
                    <div className={'gd-col gd-col-4'} style={{ marginTop: 40 }}>
                        <FieldPaymentType
                            label={'<em>Tipo de pagamento</em>'}
                            multipleSelection={true}
                            fieldGroup={false}
                            labelStyle={FieldTextLabelStyle.LABEL_INLINE}
                            onAddItem={(record) => {
                                setPaymentTypes((paymentTypes) => {
                                    return [
                                        ...paymentTypes,
                                        record
                                    ];
                                });

                                setFiltersBillsToReceive(prevState => ({
                                    ...prevState,
                                    paymentTypes: Array.isArray(prevState.paymentTypes) ? [...prevState.paymentTypes, record.value] : [record.value]
                                }));
                            }}
                        />
                        <Pills
                            pills={paymentTypes}
                            onRemoveItem={(record) => {
                                setPaymentTypes((paymentTypes) => {
                                    return paymentTypes.filter(item => item.id !== record.id);
                                });

                                setFiltersBillsToReceive(prevState => ({
                                    ...prevState,
                                    paymentTypes: prevState.paymentTypes.filter(paymentType => paymentType !== record.value)
                                }));
                            }}
                        />
                    </div>
                    <div className={'gd-col gd-col-4'} style={{ marginTop: 40 }}>
                        <FieldTextSearch
                            ref={inputSearchRef}
                            // I18N
                            label={'<em>Número da venda</em>'}
                            onChange={({ target }) => {
                                setFiltersBillsToReceive(prevState => {
                                    return {
                                        ...prevState,
                                        salesNumber: target.value
                                    };
                                });
                            }}
                        />
                    </div>
                    <div className={'gd-col gd-col-2'}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ textAlign: 'center', marginBottom: '20px' }}>{'Emissão'}</span>
                            <FieldDate
                                ref={inputInitialEmissionDateRef}
                                height={'50px'}
                                value={filtersBillsToReceive.initialEmissionDate}
                                onChange={({ target }) => setFiltersBillsToReceive(prevState => {
                                    return {
                                        ...prevState,
                                        initialEmissionDate: target.value
                                    };
                                })}
                            />
                            <FieldDate
                                ref={inputFinalEmissionDateRef}
                                height={'50px'}
                                value={filtersBillsToReceive.finalEmissionDate}
                                onChange={({ target }) => setFiltersBillsToReceive(prevState => {
                                    return {
                                        ...prevState,
                                        finalEmissionDate: target.value
                                    };
                                })}
                            />
                        </div>
                    </div>
                    <div className={'gd-col gd-col-2'}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ textAlign: 'center', marginBottom: '20px' }}>{'Vencimento'}</span>
                            <FieldDate
                                ref={inputInitialDueDateRef}
                                height={'50px'}
                                value={filtersBillsToReceive.initialDueDate}
                                onChange={({ target }) => setFiltersBillsToReceive(prevState => {
                                    return {
                                        ...prevState,
                                        initialDueDate: target.value
                                    };
                                })}
                            />
                            <FieldDate
                                ref={inputFinalDueDate}
                                height={'50px'}
                                value={filtersBillsToReceive.finalDueDate}
                                onChange={({ target }) => setFiltersBillsToReceive(prevState => {
                                    return {
                                        ...prevState,
                                        finalDueDate: target.value
                                    };
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.bills_types}>
                <div className={styles.checkbox_container}>
                    <Checkbox.Root
                        className={styles.checkbox_root}
                        defaultChecked
                        id={'forecast'}
                        checked={!!filtersBillsToReceive.billsTypes.find(item => item === 'forecast')}
                        onCheckedChange={(checked) => {
                            changeBillsTypeFilter('forecast', checked);
                        }}
                    >
                        <Checkbox.Indicator>
                            {filtersBillsToReceive.billsTypes.find(item => item === 'forecast') ? '✓' : null}
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label className={styles.checkbox_label} htmlFor={'forecast'}>
                        <img src={IconRoundedCheckGray} />
                        {'Previsionadas'}
                    </label>
                </div>
                <div className={styles.checkbox_container}>
                    <Checkbox.Root
                        className={styles.checkbox_root}
                        id={'received'}
                        checked={!!filtersBillsToReceive.billsTypes.find(item => item === 'received')}
                        onCheckedChange={(checked) => {
                            changeBillsTypeFilter('received', checked);
                        }}>
                        <Checkbox.Indicator>
                            {filtersBillsToReceive.billsTypes.find(item => item === 'received') ? '✓' : null}
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label className={styles.checkbox_label} htmlFor={'received'}>
                        <img src={IconRoundedCheckBlue} />
                        {'Recebidas'}
                    </label>
                </div>
            </div>

            <div className={styles.bills_container}>
                <Tabs.Root defaultValue={'forecast'} className={styles.tabs_container}>
                    <Tabs.List className={styles.tabs_list} aria-label={'Manage your account'} defaultValue={'forecast'}>
                        <Tabs.Trigger className={styles.tabs_trigger} value={'forecast'}>
                            {'Contas a receber'}
                        </Tabs.Trigger>
                        <Tabs.Trigger className={styles.tabs_trigger} value={'received'}>
                            {'Recebimentos'}
                        </Tabs.Trigger>
                    </Tabs.List>
                    <Tabs.Content className={styles.tabs_content} value={'forecast'}>
                        <Table
                            columns={[
                                {
                                    name: 'Data de Lançamento',
                                },
                                {
                                    name: 'Venda',
                                },
                                {
                                    name: 'Parcela',
                                },
                                {
                                    name: 'Data de Vencimento',
                                },
                                {
                                    name: 'Valor da Parcela',
                                },
                                {
                                    name: 'Valor Pendente',
                                },
                                {
                                    name: 'Tipo de Pagamento',
                                },
                                {
                                    name: 'Maquina',
                                },
                                {}, // control column
                                {}, // control column
                            ]}>
                            {billsToReceive.records && billsToReceive.records.map(bill => {
                                return (
                                    <tr key={bill.id} onClick={() => handleRowClick(getBillsToReceiveFormRoute(bill.uuid))}>
                                        <td style={{ textAlign: 'center' }}>{format(new Date(bill.releaseDate), 'dd/MM/yyyy')}</td>
                                        <td style={{ textAlign: 'center' }}>{bill.sale.id ?? ''}</td>
                                        <td style={{ textAlign: 'center' }}>{bill.amountInstallments}</td>
                                        <td style={{ textAlign: 'center' }}>{format(new Date(bill.dueDate), 'dd/MM/yyyy')}</td>
                                        <td style={{ textAlign: 'center' }}>{formatValue(bill.value)}</td>
                                        <td style={{ textAlign: 'center' }}>{formatValue((bill.sale.total - bill.totalReceived) < 0 ? 0 : (bill.sale.total - bill.totalReceived))}</td>
                                        <td style={{ textAlign: 'center' }}>{bill.receiptType?.name ? bill.receiptType.name : ''}</td>
                                        <td style={{ textAlign: 'center' }}>{bill.machine?.name ? bill.machine.name : ''}</td>
                                        <td style={{ textAlign: 'center' }}>{bill.status === 'received' ? (
                                            <img src={IconRoundedCheckBlue} />
                                        ) : (
                                            <img src={IconRoundedCheckGray} />
                                        )}</td>
                                    </tr>
                                );
                            })}
                        </Table>

                        <div>
                            <Pagination
                                page={billsToReceive?.page}
                                pageSize={billsToReceive?.pageSize}
                                count={1}
                                recordCount={billsToReceive?.records?.length}
                            />
                        </div>
                    </Tabs.Content>
                    <Tabs.Content className={styles.tabs_content} value={'received'}>
                        <Table
                            columns={[
                                {
                                    name: 'Fatura',
                                },
                                {
                                    name: 'Venda',
                                },
                                {
                                    name: 'Data de Recebimento',
                                },
                                {
                                    name: 'Valor do Recebimento',
                                },
                                {
                                    name: 'Tipo de Pagamento',
                                },
                            ]}>
                            {billsToReceive.records && billsToReceive.records.map(bill => {
                                return bill.receipts?.map(receipt => {
                                    return (
                                        <tr key={bill.id}>
                                            <td style={{ textAlign: 'center' }}>{bill.id}</td>
                                            <td style={{ textAlign: 'center' }}>{bill.sale.id ?? ''}</td>
                                            <td style={{ textAlign: 'center' }}>{format(new Date(receipt.deliveryDate), 'dd/MM/yyyy')}</td>
                                            <td style={{ textAlign: 'center' }}>{formatValue(receipt.value)}</td>
                                            <td style={{ textAlign: 'center' }}>{receipt.receiptType?.name ? receipt.receiptType.name : ''}</td>
                                            <td style={{ textAlign: 'center' }}><ButtonGoto route={''} /></td>
                                        </tr>
                                    );
                                });

                            })}
                        </Table>

                        <div>
                            <Pagination
                                page={billsToReceive?.page}
                                pageSize={billsToReceive?.pageSize}
                                count={1}
                                recordCount={billsToReceive?.records?.length}
                            />
                        </div>
                    </Tabs.Content>
                </Tabs.Root>
            </div>
        </div>
    );
}

export default BillsToReceive;

export function getBillsToReceiveRoute() {
    //I18N
    return '/financeiro/contas-a-receber';
}