import nextId from 'react-id-generator';
import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';
import Field from './Field';
export { unmaskCpf } from 'erva-doce-common';

const cpfMask = [ /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/ ];

function CpfInput({
    placeholder,
    ...props }
) {
    return <MaskedInput
        mask={cpfMask}
        placeholder={placeholder}
        {...props}/>;
}

function FieldCpf(
    {
        children,
        required,
        className,
        value,
        onChange,
        onKeyUp,
        onKeyDown,
        icon,
        icon2x,
        readOnly,
        placeholder,
        ...props
    }, ref) {
    const {
        labelText,
        validationError,
    } = props;

    const id = props.id ?? nextId('field-cpf');
    const idError = `${id}-error`;
    const fieldRef = React.useRef();

    let inputStyle = {};
    if (icon) {
        if (icon2x) {
            inputStyle.backgroundImage = `image-set(url(${icon}) 1x, url(${icon2x}) 2x) `;
        } else {
            inputStyle.backgroundImage = `url(${icon})`;
        }
    }

    className = className ?? '';
    if (icon && className.indexOf('has-icon') === -1) {
        className += ' has-icon';
    }

    return (
        <Field ref={fieldRef} {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
            className={className}
        >
            <CpfInput
                ref={ref}
                id={id}
                style={inputStyle}
                required={!!required}
                aria-required={!!required}
                aria-invalid={!!validationError}
                aria-errormessage={idError}
                title={labelText}
                className={className}
                value={value}
                onChange={onChange}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                autoComplete={'cpf'}
                readOnly={readOnly}
                placeholder={placeholder}
            />
        </Field>
    );
}

export default forwardRef(FieldCpf);