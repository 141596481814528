import SimpleConfirmModal from '../../components/modal/SimpleConfirmModal';
import { useState } from 'react';
import Pills from '../../components/Pills';
import FieldJobTitle from '../../components/FieldJobTitle';
import FieldStoreType from '../../components/FieldStoreType';

function StoresFilterModal({
    show,
    onCancel,
    onConfirm,
    filter,
}) {
    const [ storesTypes, setStoresTypes ] = useState(filter?.storesTypes ?? []);

    function confirm() {
        onConfirm({
            storesTypes,
        });
    }

    function cancel() {
        onCancel();
        setStoresTypes(filter.storesTypes);
    }

    return (
        <SimpleConfirmModal
            show={show}
            onCancel={cancel}
            onConfirm={confirm}
            // I18N
            title={'Filtrar lojas'}
        >
            <div className={'gd'}>
                <div className={'gd-col gd-col-12'}>
                    <FieldStoreType
                        multipleSelection={true}
                        onAddItem={(record) => {
                            setStoresTypes((storesTypes) => {
                                return [
                                    ...storesTypes,
                                    record
                                ];
                            });
                        }}
                        ignoreItems={storesTypes.map(record => record.id)}
                    >
                        <Pills
                            pills={storesTypes}
                            onRemoveItem={(record) => {
                                setStoresTypes((storesTypes) => {
                                    return storesTypes.filter(item => item.id !== record.id);
                                });
                            }}
                        />
                    </FieldStoreType>
                </div>
            </div>

        </SimpleConfirmModal>
    );
}

export default StoresFilterModal;