import { zodResolver } from '@hookform/resolvers/zod';
import * as Checkbox from '@radix-ui/react-checkbox';
import { format } from 'date-fns';
import React, { useCallback, useContext, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { z } from 'zod';

import FieldCurrency, { extractCurrencyNumber } from '../../components/FieldCurrency';
import FieldDate from '../../components/FieldDate';
import FieldMachine from '../../components/FieldMachine';
import FieldPaymentType from '../../components/FieldPaymentType';
import FieldSale from '../../components/FieldSale';
import FieldStore from '../../components/FieldStore';
import FieldText, { FieldTextLabelStyle } from '../../components/FieldText';
import FieldTextArea from '../../components/FieldTextarea';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { InfoModalStyle } from '../../components/modal/InfoModal';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { addBillsToReceive, editBillsToReceive, getBillToReceive } from '../../services/BillsToReceiveService';
import { formatValue } from '../../util/formatValue';
import { getBillsToReceiveRoute } from './BillsToReceive';
import styles from './BillsToReceiveForm.module.scss';
import ReceiptsList from './ReceiptsList';
// import { Container } from './styles';

const receiptSchema = z.object({
    deliveryDate: z.string(),
    value: z.string(),
    receiptType: z.object({
        id: z.string(),
        value: z.string(),
    }),
});

const BillsToTeceiveFormSchema = z.object({
    documentNumber: z
        .string()
        .nonempty({
            message: 'O documento não pode ser vazio'
        }),
    amountInstallments: z.string()
        .nonempty({
            message: 'A prestação não pode ser vazia'
        }),
    store: z.object({
        id: z.string(),
        value: z.string(),
    }),
    dueDate: z
        .string()
        .nonempty({
            message: 'A data de vencimento não pode ser vazia'
        }),
    installmentValue: z
        .string()
        .nonempty({
            message: 'O valor não pode ser vazi0'
        }),
    releaseDate: z
        .string()
        .nonempty({
            message: 'A data de lançamento não pode ser vazia'
        }),
    sale: z.object({
        id: z.string(),
        value: z.string(),
    }),
    receiptType: z.object({
        id: z.string(),
        value: z.string(),
    }),
    machine: z.object({
        id: z.string(),
        value: z.string(),
    }),
    observation: z.string(),
    forecast: z.boolean(),
    received: z.boolean(),
    receipts: z.array(receiptSchema),
});

function BillsToReceiveForm() {
    const {
        setInfoModal,
        setWindowTitle,
    } = useContext(EnvironmentContext);
    const { uuid } = useParams();

    const navigate = useNavigate();

    const methods = useForm({
        resolver: zodResolver(BillsToTeceiveFormSchema),
        defaultValues: {
            documentNumber: '',
            amountInstallments: '',
            store: null,
            dueDate: format((new Date), 'yyyy-MM-dd'),
            installmentValue: '',
            releaseDate: format((new Date), 'yyyy-MM-dd'),
            sale: null,
            receiptType: null,
            machine: null,
            observation: '',
            forecast: false,
            received: false,
            receipts: null
        }
    });

    const { control, formState: { errors }, handleSubmit, setValue } = methods;

    const onSubmit = useCallback(async ({ documentNumber, dueDate, amountInstallments, machine, observation, receiptType, receipts, releaseDate, sale, store, installmentValue, received }) => {
        try {
            const formData = {
                documentNumber,
                dueDate,
                amountInstallments,
                machineId: machine.id,
                observation,
                receiptTypeId: receiptType.id,
                receipts: receipts.map(receipt => {
                    return {
                        deliveryDate: receipt.deliveryDate,
                        value: extractCurrencyNumber(receipt.value),
                        receiptTypeId: receipt.receiptType.id
                    };
                }),
                releaseDate,
                saleId: sale.id,
                storeId: store.id,
                value: extractCurrencyNumber(installmentValue),
                status: received === true ? 'received' : 'forecast'
            };

            await addBillsToReceive(formData);

            setInfoModal({
                title: 'Contas a Receber',
                message: 'Conta Cadastrada com Sucesso',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: navigate(getBillsToReceiveRoute()),
            });

        } catch (e) {
            console.log(e);

            setInfoModal({
                title: 'Contas a Receber',
                message: 'Erro ao cadastrar uma conta',
                style: InfoModalStyle.ERROR,
                show: true,
                onClose: () => { },
            });
        }
    }, []);

    const onEditBillToReceive = useCallback(async ({ observation, receipts, received }) => {
        try {
            const formData = {
                observation,
                status: received === true ? 'received' : 'forecast',
                receipts: receipts.filter(receipt => receipt.previousReceipt === false).map(receipt => {
                    return {
                        deliveryDate: receipt.deliveryDate,
                        value: extractCurrencyNumber(receipt.value),
                        receiptTypeId: receipt.receiptType.id
                    };
                }),
            };

            await editBillsToReceive(uuid, formData);

            setInfoModal({
                title: 'Contas a Receber',
                message: 'Conta editada com Sucesso',
                style: InfoModalStyle.SUCCESS,
                show: true,
                onClose: navigate(getBillsToReceiveRoute()),
            });

        } catch (e) {
            console.log(e);

            setInfoModal({
                title: 'Contas a Receber',
                message: 'Erro ao editar uma conta',
                style: InfoModalStyle.ERROR,
                show: true,
                onClose: () => { },
            });
        }
    }, []);

    const fetchBillsToReceive = useCallback(async () => {
        const billToReceive = await getBillToReceive(uuid);

        setValue('documentNumber', billToReceive.documentNumber);
        setValue('releaseDate', format(new Date(billToReceive.releaseDate), 'yyyy-MM-dd'));
        setValue('dueDate', format(new Date(billToReceive.dueDate), 'yyyy-MM-dd'));
        setValue('amountInstallments', billToReceive.amountInstallments);
        billToReceive.status === 'received' ? setValue('received', true) : setValue('forecast', true);
        setValue('observation', billToReceive.observation);
        setValue('installmentValue', billToReceive.value);
        setValue('machine', {
            id: billToReceive.machine.uuid,
            value: billToReceive.machine.name
        });
        setValue('receiptType', {
            id: billToReceive.receiptType.uuid,
            value: billToReceive.receiptType.name
        });
        setValue('sale', {
            id: billToReceive.sale.uuid,
            value: `Número: ${billToReceive.sale.id} | Valor: ${formatValue(billToReceive.sale.total)}`,
        });
        setValue('store', {
            id: billToReceive.store.uuid,
            value: billToReceive.store.fantasyName,
        });
        setValue('receipts', billToReceive.receipts.map(receipt => {
            return {
                deliveryDate: format(new Date(receipt.deliveryDate), 'dd/MM/yyyy'),
                value: formatValue(receipt.value),
                receiptType: {
                    id: receipt.receiptType.uuid,
                    value: receipt.receiptType.name,
                },
                previousReceipt: true
            };
        }));
    }, [ uuid, setValue ]);

    useEffect(() => {
        // I18N
        fetchBillsToReceive();
    }, [ fetchBillsToReceive ]);

    useEffect(() => {
        // I18N
        setWindowTitle('Contas a Receber');
    }, [ setWindowTitle ]);

    return (
        <FormProvider {...methods}>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Detalhamento da conta'}
                    breadcrumbs={[
                        { name: 'Financeiro', route: '' },
                        { name: 'Contas a Receber', route: '' },
                        { name: 'Detalhamento da Conta', route: '' },
                    ]}
                />

                <form onSubmit={handleSubmit(uuid ? onEditBillToReceive : onSubmit)} noValidate>
                    <div className={'gd'} style={{
                        height: '160px'
                    }}>
                        <div className={'gd-col gd-col-3'}>
                            <Controller
                                name={'documentNumber'}
                                control={control}
                                render={({ field: { name, ref, onChange, value } }) => (
                                    <FieldText
                                        required={false}
                                        name={name}
                                        ref={ref}
                                        onChange={onChange}
                                        value={value}
                                        label={'Documento'}
                                        disabled={uuid ? true : false}
                                    >
                                        { errors.documentNumber && <span>{ errors.documentNumber.message }</span> }
                                    </FieldText>
                                )}
                            />
                        </div>

                        <div className={'gd-col gd-col-1'}>
                            <Controller
                                name={'amountInstallments'}
                                control={control}
                                render={({ field: { name, ref, onChange, value } }) => (
                                    <FieldText
                                        required={false}
                                        name={name}
                                        ref={ref}
                                        onChange={onChange}
                                        value={value}
                                        label={'Parcela'}
                                        disabled={uuid ? true : false}
                                    >
                                        { errors.amountInstallments && <span>{ errors.amountInstallments.message }</span> }
                                    </FieldText>
                                )}
                            />
                        </div>

                        <div className={'gd-col gd-col-3'}>
                            <Controller
                                name={'store'}
                                control={control}
                                render={({ field: { name, onChange, value } }) => (
                                    <FieldStore
                                        name={name}
                                        label={'Loja'}
                                        select={value}
                                        onSelected={(e) => {
                                            onChange(e);
                                        }}
                                        disabled={uuid ? true : false}
                                    >
                                        { errors.store && <span>{ errors.store.message }</span> }
                                    </FieldStore>
                                )}
                            />
                        </div>

                        <div className={'gd-col gd-col-2'}>
                            <Controller
                                name={'dueDate'}
                                control={control}
                                render={({ field: { name, onChange, value } }) => (
                                    <FieldDate
                                        required={false}
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        label={'Data do Vencimento'}
                                        disabled={uuid ? true : false}
                                    >
                                        { errors.installmentDate && <span>{ errors.installmentDate.message }</span> }
                                    </FieldDate>
                                )}
                            />
                        </div>

                        <div className={'gd-col gd-col-3'}>
                            <Controller
                                name={'installmentValue'}
                                control={control}
                                render={({ field: { name, onChange, value } }) => (
                                    <FieldCurrency
                                        required={false}
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        label={'Valor'}
                                        disabled={uuid ? true : false}
                                    >
                                        { errors.installmentValue && <span>{ errors.installmentValue.message }</span> }
                                    </FieldCurrency>
                                )}
                            />
                        </div>
                    </div>

                    <div className={'gd'} style={{
                        height: '160px'
                    }}>
                        <div className={'gd-col gd-col-3'}>
                            <Controller
                                name={'releaseDate'}
                                control={control}
                                render={({ field: { name, onChange, value } }) => (
                                    <FieldDate
                                        required={false}
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        label={'Data de Lançamento'}
                                        disabled={uuid ? true : false}
                                    >
                                        { errors.releaseDate && <span>{ errors.releaseDate.message }</span> }
                                    </FieldDate>
                                )}
                            />
                        </div>

                        <div className={'gd-col gd-col-3'}>
                            <Controller
                                name={'sale'}
                                control={control}
                                render={({ field: { name, onChange, value } }) => (
                                    <FieldSale
                                        name={name}
                                        label={'Venda'}
                                        select={value}
                                        onSelected={(e) => {
                                            onChange(e);
                                        }}
                                        disabled={uuid ? true : false}
                                    >
                                        { errors.sale && <span>{ errors.sale.message }</span> }
                                    </FieldSale>
                                )}
                            />
                        </div>

                        <div className={'gd-col gd-col-3'}>
                            <Controller
                                name={'receiptType'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FieldPaymentType
                                        label={'<em>Tipo de pagamento</em>'}
                                        multipleSelection={false}
                                        fieldGroup={false}
                                        labelStyle={FieldTextLabelStyle.LABEL_ABOVE}
                                        onSelected={(e) => {
                                            onChange(e);
                                        }}
                                        select={value}
                                        disabled={uuid ? true : false}
                                    >
                                        { errors.receiptType && <span>{ errors.receiptType.message }</span> }
                                    </FieldPaymentType>
                                )}
                            />
                        </div>

                        <div className={'gd-col gd-col-3'}>
                            <Controller
                                name={'machine'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FieldMachine
                                        label={'<em>Máquina</em>'}
                                        multipleSelection={false}
                                        fieldGroup={false}
                                        labelStyle={FieldTextLabelStyle.LABEL_ABOVE}
                                        onSelected={(e) => {
                                            onChange(e);
                                        }}
                                        select={value}
                                        disabled={uuid ? true : false}
                                    >
                                        { errors.machine && <span>{ errors.machine.message }</span> }
                                    </FieldMachine>
                                )}
                            />
                        </div>
                    </div>

                    <div className={'gd'}>
                        <div className={'gd-col gd-col-12'}>
                            <Controller
                                name={'observation'}
                                control={control}
                                render={({ field: { name, ref, onChange, value } }) => (
                                    <FieldTextArea
                                        name={name}
                                        required={false}
                                        ref={ref}
                                        onChange={onChange}
                                        value={value}
                                        label={'Observações gerais'}
                                    >
                                        { errors.observation && <span>{ errors.observation.message }</span> }
                                    </FieldTextArea>
                                )}
                            />
                        </div>
                    </div>

                    <div className={'gd'}>
                        <div className={styles.bills_types}>
                            <div className={styles.checkbox_container}>
                                <Controller
                                    name={'received'}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Checkbox.Root
                                                className={styles.checkbox_root}
                                                id={'received'}
                                                checked={value}
                                                onCheckedChange={(checked) => {
                                                    onChange(checked === true);
                                                    if (checked === true) {
                                                        setValue('forecast', false);
                                                    }
                                                }}
                                            >
                                                <Checkbox.Indicator>
                                                    { value === true ? '✓' : null }
                                                </Checkbox.Indicator>
                                            </Checkbox.Root>
                                            <label className={styles.checkbox_label} htmlFor={'received'}>
                                                { 'Recebida' }
                                            </label>
                                        </>
                                    )}
                                />
                            </div>

                            <div className={styles.checkbox_container}>
                                <Controller
                                    name={'forecast'}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Checkbox.Root
                                                className={styles.checkbox_root}
                                                id={'forecast'}
                                                checked={value}
                                                onCheckedChange={(checked) => {
                                                    onChange(checked === true);
                                                    if (checked === true) {
                                                        setValue('received', false);
                                                    }
                                                }}
                                            >
                                                <Checkbox.Indicator>
                                                    { value === true ? '✓' : null }
                                                </Checkbox.Indicator>
                                            </Checkbox.Root>
                                            <label className={styles.checkbox_label} htmlFor={'forecast'}>
                                                { 'Previsionada' }
                                            </label>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>


                    <ReceiptsList />

                    <div className={styles.button_container}>
                        <button type={'button'} onClick={() => navigate(getBillsToReceiveRoute())}>{ 'Cancelar' }</button>
                        <button type={'submit'}>{ 'Salvar' }</button>
                    </div>
                </form>
            </div>
        </FormProvider>
    );
}

export default BillsToReceiveForm;

export function getBillsToReceiveFormRoute(uuid) {
    //I18N
    if (uuid) {
        return `/financeiro/contas-a-receber/${uuid}`;
    } else {
        return '/financeiro/contas-a-receber/novo';
    }
}