import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
    OrderByOrdersEnum,
    OrderStatus,
} from 'erva-doce-common';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import FieldTextSearch from '../../components/FieldTextSearch';
import LoadError from '../../components/LoadError';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import ScreenHeader from '../../components/logged/ScreenHeader';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { getDashboardRoute } from '../../dashboard/Dashboard';
import * as OrdersService from '../../services/OrdersService';
import { getOrderDetailRoute } from '../order/order-detail/OrderDetail';
import { formatValue } from '../../util/formatValue';
import { DateTime } from 'luxon';
import Button, { ButtonColor, ButtonStyle } from '../../components/Button';
import { IconFilter, IconFilter2x } from '../../components/images';
import FilterModal from './FilterModal';

const FILTERS = {
    order: OrderByOrdersEnum.ID_ASC,
    search: null,
    page: 0,
    status: null,
    supplier: '',
    representative: '',
};

export default function OrderList() {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [showFilterModal, setShowFilterModal] = useState(null);
    const [filter, setFilter] = useState(FILTERS);
    const [search, setSearch] = useState({
        representative: '',
        supplier: '',
    });

    const {
        setWindowTitle,
    } = useContext(EnvironmentContext);

    const navigate = useNavigate();
    const handleRowClick = (route) => navigate(route);

    useEffect(() => {
        fetchData();
    }, [filter]);

    useEffect(() => {
        setWindowTitle('Listagem de pedidos');

        return () => {
            setWindowTitle();
        };
    }, []);

    const updateFilter = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    async function fetchData() {
        if (isLoading?.cancel) {
            isLoading.cancel();
        }
        try {
            const cancelToken = axios.CancelToken.source().token;
            setIsLoading(cancelToken);
            const response = await OrdersService.getOrders(filter);
            setData(response);
            setIsLoading(null);
        } catch (e) {
            if (axios.isCancel(e)) {
                console.debug('Request cancelled.', e);
            } else {
                console.error(e);
                Sentry.captureException(e);
                setIsLoading(e);
                setData(null);
            }
        }
    }

    function renderTable() {
        if (isLoading && isLoading instanceof Error) {
            return (
                <LoadError
                    // I18N
                    message={'Não foi possível carregar os Motivos de cancelamento'}
                    onTryAgain={fetchData}
                />
            );
        }

        let rows = [];
        const records = data?.records ?? [];
        for (const s of records) {
            rows.push(
                <tr
                    key={s.id}
                    onClick={() => handleRowClick(getOrderDetailRoute(s.id))}
                >
                    <td>{`${s.id}`.padStart(7, '0')}</td>
                    <td>{s.supplierName}</td>
                    <td>{s.representativeName}</td>
                    <td className={'text-center'}>{OrderStatus(s.status)}</td>
                    <td className={'text-center'}>
                        {formatValue(s.minimumOrder)}
                    </td>
                    <td className={'text-center'}>{formatValue(s.total)}</td>
                    <td className={'text-center'}>
                        {DateTime.fromISO(s.createdAt).toFormat('dd/MM/yyyy')}
                    </td>
                    <td className={'text-center'}>
                        {DateTime.fromISO(s.updatedAt).toFormat('dd/MM/yyyy')}
                    </td>
                </tr>
            );
        }

        return (
            <>
                <Table
                    loading={isLoading}
                    currentSort={filter.order}
                    columns={[
                        {
                            name: 'N° Pedido',
                            sortAsc: OrderByOrdersEnum.ID_ASC,
                            sortDesc: OrderByOrdersEnum.ID_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                        {
                            name: 'Fornecedor',
                            sortAsc: OrderByOrdersEnum.SUPPLIER_ASC,
                            sortDesc: OrderByOrdersEnum.SUPPLIER_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                        {
                            name: 'Representante',
                            sortAsc: OrderByOrdersEnum.REPRESENTATIVE_ASC,
                            sortDesc: OrderByOrdersEnum.REPRESENTATIVE_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                        {
                            name: 'Status',
                            align: 'center',
                            sortAsc: OrderByOrdersEnum.STATUS_ASC,
                            sortDesc: OrderByOrdersEnum.STATUS_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                        {
                            name: 'Valor mínimo',
                            align: 'center',
                            sortAsc: OrderByOrdersEnum.MINIMUM_ASC,
                            sortDesc: OrderByOrdersEnum.MINIMUM_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                        {
                            name: 'Valor',
                            align: 'center',
                            sortAsc: OrderByOrdersEnum.TOTAL_ASC,
                            sortDesc: OrderByOrdersEnum.TOTAL_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                        {
                            name: 'Data de criação',
                            align: 'center',
                            sortAsc: OrderByOrdersEnum.CREATED_DATE_ASC,
                            sortDesc: OrderByOrdersEnum.CREATED_DATE_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                        {
                            name: 'Última atualização',
                            align: 'center',
                            sortAsc: OrderByOrdersEnum.LAST_UPDATE_ASC,
                            sortDesc: OrderByOrdersEnum.LAST_UPDATE_DESC,
                            onSortChange: (order) => updateFilter('order', order),
                        },
                    ]}
                >
                    {rows}
                </Table>
                <Pagination
                    page={data?.page}
                    pageSize={data?.pageSize}
                    count={data?.count}
                    recordCount={data?.records?.length}
                    onPageChange={(page) => setFilter({ ...filter, page })}
                />
            </>
        );
    }

    return (
        <>
            <div className={'crud-list'}>
                <ScreenHeader
                    title={'Listagem de pedidos'}
                    breadcrumbs={[
                        { name: 'Compras', route: getDashboardRoute() },
                        {
                            name: 'Pedidos',
                        },
                    ]}
                />
                <div className={'row justify-content-end mb-32'}>
                    <div className={'col-3'}>
                        <FieldTextSearch
                            label={
                                '<em>Buscar por <strong>fornecedores</strong></em>'
                            }
                            onChangeDebounce={() =>
                                updateFilter('supplier', search.supplier)
                            }
                            onChange={(e) =>
                                setSearch({
                                    ...search,
                                    supplier: e.target.value,
                                })
                            }
                            value={search.supplier}
                            className={'text_filter'}
                        />
                    </div>
                    <div className={'col-3'}>
                        <FieldTextSearch
                            label={
                                '<em>Buscar por <strong>representantes</strong></em>'
                            }
                            onChangeDebounce={() =>
                                updateFilter(
                                    'representative',
                                    search.representative
                                )
                            }
                            onChange={(e) =>
                                setSearch({
                                    ...search,
                                    representative: e.target.value,
                                })
                            }
                            value={search.representative}
                            className={'text_filter'}
                        />
                    </div>
                    <div className={'col-4'}></div>
                    <div className={'col-2'}>
                        <Button
                            buttonStyle={ButtonStyle.BUTTON_SHADOW}
                            color={ButtonColor.BUTTON_COLOR_ORANGE}
                            icon={IconFilter}
                            icon2x={IconFilter2x}
                            onClick={() => {
                                setShowFilterModal(true);
                            }}
                            className={'w-100'}
                        >
                            {'Filtrar'}
                        </Button>
                    </div>
                </div>
                <div className={'table-scroll'}>{renderTable()}</div>
            </div>
            <FilterModal
                show={showFilterModal}
                onCancel={() => setShowFilterModal(false)}
                onConfirm={(filter) => {
                    setShowFilterModal(false);
                    setFilter(filter);
                }}
                filter={filter}
            />
        </>
    );
}

export function getOrderListRoute() {
    return '/compras/pedidos';
}
